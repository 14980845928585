import { InjectionToken, Provider } from '@angular/core';
import reduce, { ImageBlobReduce } from 'image-blob-reduce';

/*
    TODO: This is a workaround for the following known library issue:
    https://github.com/nodeca/image-blob-reduce?tab=readme-ov-file#known-issues

    The above known issue documents a workaround
    that adds a custom terser configuration to omit the optimization
    that breaks this library.

    We would need to implement that workaround using custom webpack config,
    but I couldn't get it to work.

    This alternative workaround came from an issue in that same repo:
    https://github.com/nodeca/image-blob-reduce/issues/17#issuecomment-751550135

    We should just replace this library -
    it's been causing us problems ever since we installed it.
    We can also uninstall 'pica' when we replace this library
*/
// @ts-expect-error See above comment
import Pica from 'pica';

export const IMAGE_BLOB_REDUCE = new InjectionToken<ImageBlobReduce>('ImageBlobReduce');

export function imageBlobReduceFactory(): ImageBlobReduce {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const pica = Pica({ features: ['js', 'wasm', 'cib'] });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return reduce({ pica });
}

export function provideImageBlobReduce(): Provider {
    return {
        provide: IMAGE_BLOB_REDUCE,
        useFactory: imageBlobReduceFactory,
    };
}
