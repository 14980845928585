import { GraphQLErrors } from '@apollo/client/errors';

export class GraphqlResponseError extends Error {
    public override name = GraphqlResponseError.name;

    public constructor(
        public readonly errors: GraphQLErrors,
        message?: string,
    ) {
        message ??= `The operation reported ${errors.length} errors.`;
        super(message);
    }
}
