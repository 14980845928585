import { Email, Uuid } from '@lib/shared-interface-utility-types';
import { Account } from './account.interface';
import { SchoolUserData } from './school/school-user-data.interface';
import { UserApplication } from './shared/user-application.interface';
import { StudentUserData } from './student/student-user-data.interface';

/**
 * A user account as represented server-side.
 *
 * An account may exist with only an ID and email address for an invited user.
 */
export type FullAccount = Account & {
    // Todo: In the future, we can discriminate `additionalUserData` in mongoose schema
    //  based on the userApplication value.
    /**
     * Identifies which application this account may access.
     * It can also be used to differentiate the additional data properties (eventually).
     */
    userApplication: UserApplication;

    /**
     * Unique ID in the authentication system.
     */
    authenticationId?: Uuid;

    /**
     * The previous email used by this account.
     */
    priorEmail?: Email;

    /**
     * The timestamp of when the user updated their account email.
     */
    emailUpdatedAt?: number;
};

export type AccountInvitee = Required<Pick<FullAccount, 'firstName' | 'lastName'>>;

export type InternalInvitee = AccountInvitee &
    Required<Pick<FullAccount, 'email'>> &
    Partial<Pick<FullAccount, 'phone'>>;

// School customer id is needed if they are associated with a school.
// kajabiMemberId will be added if the account is attached to a kajabi purchase event.
export type SchoolCustomerInvitee = AccountInvitee &
    Required<Pick<FullAccount, 'email'>> &
    Partial<Pick<FullAccount, 'phone'>> & {
        additionalSchoolUserData: Required<Pick<SchoolUserData, 'schoolCustomerId'>> &
            Partial<Pick<SchoolUserData, 'roles'>>;
    };

export type IndependentTeacherInvitee = AccountInvitee &
    Required<Pick<FullAccount, 'email'>> &
    Partial<Pick<FullAccount, 'phone'>> & {
        additionalSchoolUserData: Pick<SchoolUserData, 'kajabiMemberId'> &
            Partial<Pick<SchoolUserData, 'roles'>>;
    };

export type StudentInvitee = AccountInvitee & {
    additionalStudentUserData: Pick<StudentUserData, 'schoolCustomerId' | 'studentId'>;
};

export type AccountRegistration = Required<Pick<FullAccount, 'authenticationId' | 'email'>>;
