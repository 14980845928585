import { InjectionToken, Provider } from '@angular/core';

export const STUDENT_APP_PATH_TOKEN = new InjectionToken<string>('Student App Path');

export function provideStudentAppPath(studentAppPath: string): Provider {
    return {
        provide: STUDENT_APP_PATH_TOKEN,
        useValue: studentAppPath,
    };
}
