import { createStringEnum } from './create-string-enum';
import { EnumValues } from './enum-types';

// Local differentiations local development environments
// and the deployed development environment
// Uses Node naming conventions,
// rather than abbreviated versions used by AWS
export const EnvironmentType = createStringEnum(['local', 'development', 'test', 'production']);

export type EnvironmentType = EnumValues<typeof EnvironmentType>;
