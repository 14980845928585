import { Route } from '@angular/router';
import {
    READING_PAGE_LINKS,
    READING_PAGE_MEDIA_ID,
    VOCABULARY_PAGE_LINKS,
    VOCABULARY_PAGE_MEDIA_ID,
} from '../dashboards.constants';
import { GRAMMAR_PROGRAM_ROUTES } from './grammar-program.routes';
import { WRITING_PROGRAM_ROUTES } from './writing-program.routes';

export const PROGRAM_CONTENT_PAGE_ROUTES: Route[] = [
    ...WRITING_PROGRAM_ROUTES,
    ...GRAMMAR_PROGRAM_ROUTES,
    {
        path: 'reading-program',
        title: 'The EB Reading Program',
        loadComponent: () => import('../dashboard-template.page'),
        data: {
            mediaId: READING_PAGE_MEDIA_ID,
            links: READING_PAGE_LINKS,
        },
    },
    {
        path: 'vocabulary-program',
        title: 'The EB Vocabulary Program',
        loadComponent: () => import('../dashboard-template.page'),
        data: {
            mediaId: VOCABULARY_PAGE_MEDIA_ID,
            links: VOCABULARY_PAGE_LINKS,
        },
    },
    {
        path: 'vocabulary-program/grade-level-lessons',
        title: 'The EB Vocabulary Program',
        loadComponent: () =>
            import('../vocabulary-program/grade-level-lessons-page/grade-level-lessons.page'),
    },
];
