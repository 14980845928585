import { combineLatest, firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    ApolloGetUnpopulatedSkillGroupsGQL,
    ApolloGetUnpopulatedSkillsGQL,
    handleQueryResult,
} from '@lib/frontend-shared-data-gql-operations';
import { PopulatedSkillGroup, Skill, SkillGroup } from '@lib/shared-interface-skill';

@Injectable({ providedIn: 'root' })
export class SkillsService {
    public readonly skillGroups$: Observable<SkillGroup[]> = this.getUnpopulatedSkillGroupsGql
        .watch()
        .valueChanges.pipe(
            handleQueryResult(),
            map((data) => data.skillGroups),
            shareReplay(1),
        );

    public readonly skills$: Observable<Skill[]> = this.getUnpopulatedSkillsGql
        .watch()
        .valueChanges.pipe(
            handleQueryResult(),
            map((data) => data.getAllSkills),
            shareReplay(1),
        );

    public readonly populatedSkillGroups$: Observable<PopulatedSkillGroup[]> = combineLatest([
        this.skills$,
        this.skillGroups$,
    ]).pipe(
        map(([skills, skillGroups]) => {
            const populateSkillGroup = (skillGroup: SkillGroup) => {
                const isInGroup = (skill: Skill) => skill.groupId === skillGroup.id;
                const filteredSkills = skills.filter(isInGroup);
                return { ...skillGroup, skills: filteredSkills };
            };
            return skillGroups.map(populateSkillGroup);
        }),
    );

    public readonly populatedSkills$: Observable<Skill[]> = combineLatest([
        this.skills$,
        this.skillGroups$,
    ]).pipe(
        map(([skills, skillGroups]) => {
            const populateSkill = (skill: Skill) => {
                const isSkillGroup = (skillGroup: SkillGroup) => skill.groupId === skillGroup.id;
                const group = skillGroups.find(isSkillGroup);
                return { ...skill, group };
            };
            return skills.map(populateSkill);
        }),
    );

    public constructor(
        private readonly getUnpopulatedSkillGroupsGql: ApolloGetUnpopulatedSkillGroupsGQL,
        private readonly getUnpopulatedSkillsGql: ApolloGetUnpopulatedSkillsGQL,
    ) {}

    public async prefetchData() {
        await firstValueFrom(this.populatedSkillGroups$);
    }

    public filterSkillsByIds(ids: string[]): Observable<Skill[]> {
        return this.populatedSkills$.pipe(filterArray((skill) => ids.includes(skill.id)));
    }
}

function filterArray<Value>(filter: (value: Value) => boolean) {
    return map((values: Value[]) => values.filter(filter));
}
