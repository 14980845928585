import { Email, Phone, Uuid } from '@lib/shared-interface-utility-types';
import { UserApplication } from './user-application.interface';

export type SharedAccountProperties = {
    /**
     * Unique ID for this account.
     */
    id: Uuid;
    userApplication: UserApplication;
    /**
     * If false, the user's access has been revoked, but is kept around for historical purposes
     */
    active: boolean;
    /**
     * The email so that others can reach this account.
     */
    email: Email;
    firstName: string;
    lastName: string;
    /**
     * The full name so that we know how to refer to the person behind this account.
     * This will just be a concatenation of the first and last name.
     */
    fullName: string;
    /**
     * Phone number in E.164 format.
     */
    phone?: Phone;
    /**
     * The base64 encoded image uploaded by the user
     */
    avatar?: string;
    /**
     * The id of the account which invited this user. Undefined for the first account created.
     */
    inviterId?: Uuid;
    /**
     * When the account data was first created.
     */
    createdAt: number;
    /**
     * When the account data was last updated.
     */
    updatedAt: number;
    /**
     * If the user has registered, they are no longer pending.
     */
    pending: boolean;
};
