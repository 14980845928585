import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { API_DOMAIN_TOKEN } from '@lib/frontend-shared-util-miscellaneous-providers';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    public constructor(
        @Inject(API_DOMAIN_TOKEN) private readonly interceptDomain: string,
        private readonly authenticationService: AuthenticationService,
    ) {}

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        // Only attach header to requests to our API
        if (!request.url.startsWith(this.interceptDomain)) return next.handle(request);

        // Only attach header if there is no existing Authorization header.
        // Immediately after login, there's no active account,
        // but the request will already have the header.
        if (request.headers.has('Authorization')) return next.handle(request);

        // Check we have everything we need to attach the header
        const accessToken = this.authenticationService.getAccessToken();
        if (accessToken == undefined) return next.handle(request);

        const headers = request.headers.set('Authorization', accessToken);
        const authorizedRequest = request.clone({ headers });

        return next.handle(authorizedRequest);
    }
}
