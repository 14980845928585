import { EMPTY, ObservableInput, of, switchMap } from 'rxjs';
import type { MutationResult } from 'apollo-angular/types';
import { GraphqlResponseError } from './graphql-response-error';
import { GraphqlMissingDataError } from './graphql-missing-data-error';
import { ApolloQueryResult } from '@apollo/client';

export const handleMutationResult = <Data>(handlers?: ResultHandlers<Data, MutationResult<Data>>) =>
    handleApolloResult(handlers);

export const handleQueryResult = <Data>(handlers?: ResultHandlers<Data, ApolloQueryResult<Data>>) =>
    handleApolloResult(handlers);

function handleApolloResult<Data, Result extends ApolloQueryResult<Data> | MutationResult<Data>>(
    handlers?: ResultHandlers<Data, Result>,
) {
    return switchMap<Result, ObservableInput<Data>>((result) => {
        if (result.errors && result.errors.length > 0) {
            if (handlers?.handleError == undefined) throw new GraphqlResponseError(result.errors);
            const data = handlers.handleError(result);
            return data ?? EMPTY;
        }
        if (result.data == undefined) {
            if (handlers?.handleMissingData == undefined) throw new GraphqlMissingDataError(result);
            const data = handlers.handleMissingData(result);
            return data ?? EMPTY;
        }

        return of(result.data);
    });
}

type ResultHandlers<Data, Result extends ApolloQueryResult<Data> | MutationResult<Data>> = {
    handleError?: (result: Result) => ObservableInput<Data> | void;
    handleMissingData?: (result: Result) => ObservableInput<Data> | void;
};
