import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EBW_7TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/faa82977-303d-4770-89e8-66e940d12129',
    headingConfig: {
        letter: 'F',
        level: 'Level F',
        grade: 'Grade 7',
        color: 'var(--program-grade-level-f)',
    },
    slideDeckHeading: 'EBW Approach Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level F/Level_F_Slide_Decks.zip',
    digitFilesConfig: [
        {
            value: 'overview',
            heading: 'The EBW Approach',
            content: [
                {
                    text: 'Essential Questions Generator',
                    url: 'https://docs.google.com/presentation/d/1CQl5soWYZIEmDBeCeqbboTozx0ndeNxPx9eEEvxhdfg/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1n8_lZNlWHMOBiApr528NIesNF_7JBOHVZ49_jBjwiWE/copy',
                },
                {
                    text: 'Pre-Assessment',
                    url: 'https://docs.google.com/presentation/d/1ezdX5idn1QUWh3SKEZB1xH9eeVGi3Y5v8B8yiColkr0/copy',
                },
                {
                    text: 'Post-Assessment',
                    url: 'https://docs.google.com/presentation/d/1-IVaY6MvI02VKkTBavQhyut1ja0YRJoFGmm6pB0Qcys/copy',
                },
                {
                    text: 'Assessment Trackers',
                    url: 'https://docs.google.com/presentation/d/1JpuqnsetVvW0FaFHUzVY3l6cRYcFaKGzTdEK16rmWkk/copy',
                },
            ],
        },
        {
            value: 'introductoryParagraph',
            heading: 'The Introductory Paragraph',
            content: [
                {
                    text: 'Introducing EBW Handout',
                    url: 'https://docs.google.com/presentation/d/14B_UYzeDnsXq05HWahzE8HkDYPrtCTcaXJn8f1YIdGU/copy',
                },
                {
                    text: 'TAG Practice',
                    url: 'https://docs.google.com/presentation/d/1GU3W7dfGDoinOHBgjpCl0lNOJnNnxCN1nGs7TjAGVyA/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1Hxuu0MAP0vlZqTKNO50V_JFUn7cVKeA5arwEzfCeogU/copy',
                },
                {
                    text: 'Claim vs Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1XmG_W4ITTEIdbn_GP2cNAISvSwW8wdvZS4gE4WavLYM/copy',
                },
                {
                    text: 'Claim vs Statement Sort',
                    url: 'https://docs.google.com/presentation/d/1kaRGZpPinmy_Y3FQ4VqOJgyfROlqSZCoXiRtflbYuXc/copy',
                },
                {
                    text: 'Introductory Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1AZVKbawb5oXojtVqPy0NofCi4swLRkhUuH3EW-yio4c/copy',
                },
            ],
        },
        {
            value: 'bodyParagraphs',
            heading: 'The Body Paragraphs',
            content: [
                {
                    text: 'Premise Practice',
                    url: 'https://docs.google.com/presentation/d/1XWDi_ecEgh1_h-0xpeIAAMXtNLTDjBlyNZr5zdmUXjA/copy',
                },
                {
                    text: 'Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/1ZnYEYCNCZlj_i774FzZED5hi4P9OoU7VLlqhGsx5RFM/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1yapLbEBYD_K847DRlM8iBGFzhjjgfDLh3OWUcfvuKMs/copy',
                },
                {
                    text: 'Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/10r1P8MYZMDwNWfo-b9ao-KXDq80ZOmvW7-RF2R0GT0w/copy',
                },
            ],
        },
        {
            value: 'conclusionParagraph',
            heading: 'The Conclusion Paragraph',
            content: [
                {
                    text: 'Restating the Claim Practice',
                    url: 'https://docs.google.com/presentation/d/1iSe26JnlsGMEgxBuZf6YnuC-RGSFBlUn1y6YfCmnlgQ/copy',
                },
                {
                    text: 'Summarizing Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/1d7xeBC03Tq7m5t83vPfchnt4xLkz3kG8bO1YFDsQRYo/copy',
                },
                {
                    text: 'Conclusion Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1NotMJEdq7St5rZyzN5T6x5QPoFsMFUHzGzUcXy62z6k/copy',
                },
                {
                    text: 'Additional RTL Practice',
                    url: 'https://docs.google.com/presentation/d/1JnY8dO8_Vr0LWYYk1Xs3zP0997prm3YPg7aunX9YwTI/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Transitions Practice',
                    url: 'https://docs.google.com/presentation/d/1E2bQk0nKp178uaMJumelz8mZut0jSiLQEOsfnfQfu3U/copy',
                },
                {
                    text: 'Student Thesaurus',
                    url: 'https://docs.google.com/presentation/d/18pP4q9qcnDCajLDrL8rptSjbu7-XiFNrb9U_d8_Aas0/copy',
                },
                {
                    text: 'MLA Formatting Handout',
                    url: 'https://docs.google.com/presentation/d/1-F2gqCuT3nkje0EuhqMOi23Ze4IiCUUVtIIZ_sx8J_U/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1lnUpdG51YuHB_LHm7FOWkyQLihIlhY4pMd28QFXl6pE/copy',
                },
                {
                    text: 'Socratic Seminar Checklist + Rubric',
                    url: 'https://docs.google.com/presentation/d/1sJEb9hP2A4gjvUO9ZURs8DRsNSKPBAa9c6d-VWKLBpk/copy',
                },
            ],
        },
        {
            value: 'bonusContent',
            heading: 'Bonus Content',
            content: [
                {
                    text: 'Student Reflection',
                    url: 'https://docs.google.com/presentation/d/1by5aeUg_M2EUIfwsWBOqzZkI7iGCH_8UJ_RHo232RBA/copy',
                },
                {
                    text: 'Response to Literature Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1vVvZgC_OKUuArnf-YTqLmLZAjVhQS18OqlP8lo3YYG0/copy',
                },
                {
                    text: 'Response to Literature Rubric',
                    url: 'https://docs.google.com/presentation/d/1XzGXabn0TaBCEALgErX6HagLtoK5LSbhqA5AA3EFvcU/copy',
                },
                {
                    text: 'Response to Informational Text Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1NOgqQaq2YS8sMcWE7TNGC5WFAVvEQbNg2VlbX3S2tj4/copy',
                },
                {
                    text: 'Compare & Contrast Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1sMaed0a5ZHbortUAcYplsqwMe7eIxOpM7SdNT795d54/copy',
                },
                {
                    text: 'Multiple Texts Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1FtdeiWEHdnkRMUNgaKgPTTrrelDWBW9oF7yQ0Y2Tq6A/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'overviewEbwApproach',
            heading: 'The EBW Approach',
            content: [
                {
                    text: 'The EBW Approach',
                    url: 'https://drive.google.com/file/d/1CShd4whqE5FiFrjQ_ktCJDyR354-owEb/view?usp=sharing',
                },
            ],
        },
        {
            value: 'overviewIntroductoryParagraph',
            heading: 'The Introductory Paragraph',
            content: [
                {
                    text: 'The Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1oSKdLwBP5Sl8cfndxOTghAGplBtR8nX9/view?usp=sharing',
                },
                {
                    text: 'TAG',
                    url: 'https://drive.google.com/file/d/1xegUz64IlWq6n25YiCpHgSOs0FZ4lXjj/view?usp=sharing',
                },
                {
                    text: 'Combining TAG + Summary',
                    url: 'https://drive.google.com/file/d/1HhpXUUIsnrXhVCuJgS3mJc2sVsvfIYmb/view?usp=sharing',
                },
                {
                    text: 'Claim',
                    url: 'https://drive.google.com/file/d/1uF6-VMwWlCiULJU8O2UJ99iEBsYHAlbZ/view?usp=sharing',
                },
                {
                    text: 'Putting It All Together',
                    url: 'https://drive.google.com/file/d/1aH3U-FtMU4ovvuhplMCe6lnKcF6pVaF8/view?usp=sharing',
                },
            ],
        },
        {
            value: 'overviewBodyParagraph',
            heading: 'The Body Paragraphs',
            content: [
                {
                    text: 'The Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1SnBclJkKZZgOYyp7J-GFT6r0u70XRmYZ/view?usp=sharing',
                },
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/1_WbBf7y2_NqRikY-vvV8zniJSHeHkKMP/view?usp=sharing',
                },
                {
                    text: 'Intro to Evidence & Evidence',
                    url: 'https://drive.google.com/file/d/1Lc-pj_Uor1qFntAbtuSpYzeqi3xQLDKP/view?usp=sharing',
                },
                {
                    text: 'Justification',
                    url: 'https://drive.google.com/file/d/1Kd0Gae94i5rfgTFTj6c8GAvD17i9Vd2_/view?usp=sharing',
                },
                {
                    text: 'Putting it All Together',
                    url: 'https://drive.google.com/file/d/1FtonGlwmrOqxtroPKNztjFr2oyvy2y6D/view?usp=sharing',
                },
                {
                    text: 'MLA Format',
                    url: 'https://drive.google.com/file/d/13hF-B3jgYJornOxSZzL1SyqrMOYwB_w3/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'overviewConclusionParagraph',
            heading: 'The Conclusion Paragraph',
            content: [
                {
                    text: 'The Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1gBupBGCL42xA6Qr7O8eVjuop64MILgYn/view?usp=sharing',
                },
                {
                    text: 'Restating the Claim',
                    url: 'https://drive.google.com/file/d/19cvGn7Ei3fdjx-f0Kww7SSnZvIRjrIMY/view?usp=sharing',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://drive.google.com/file/d/1RHDgBr5oihz0WNyNkT-N1wsGkbhvyqBn/view?usp=sharing',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1N_-jPOar2e474pv4-f9sTzg-q4yudVQk/view?usp=sharing',
                },
                {
                    text: 'Putting it All Together',
                    url: 'https://drive.google.com/file/d/1lBgoubo3dwx-SZNBEt9h_ReCHV9w4tnD/view?usp=sharing',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level F/EBW_Certificate_of_Completion (1).pdf',
};
