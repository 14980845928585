import { ChallengeName } from 'amazon-cognito-identity-js';

export class NewPasswordRequiredError extends Error {
    public constructor(
        public readonly userAttributes: unknown,
        public readonly requiredAttributes: unknown,
    ) {
        super('A new password is required.');
    }
}

class ChallengeError extends Error {
    public constructor(
        public readonly challengeName: ChallengeName,
        public readonly challengeParameters: unknown,
        message: string,
    ) {
        super(message);
    }
}

export class MfaRequiredError extends ChallengeError {
    public constructor(challengeName: ChallengeName, challengeParameters: unknown) {
        super(challengeName, challengeParameters, 'Multi-factor authentication is required.');
    }
}

export class TotpRequiredError extends ChallengeError {
    public constructor(challengeName: ChallengeName, challengeParameters: unknown) {
        super(challengeName, challengeParameters, 'Time-based one-time password is required.');
    }
}

export class CustomChallengeError extends ChallengeError {
    public constructor(challengeParameters: unknown) {
        super('CUSTOM_CHALLENGE', challengeParameters, 'Custom challenge is required.');
    }
}

export class MfaSetupError extends ChallengeError {
    public constructor(challengeName: ChallengeName, challengeParameters: unknown) {
        super(challengeName, challengeParameters, 'Multi-factor authentication must be set up.');
    }
}

export class SelectMfaTypeError extends ChallengeError {
    public constructor(challengeName: ChallengeName, challengeParameters: unknown) {
        super(
            challengeName,
            challengeParameters,
            'Multi-factor authentication type must be selected.',
        );
    }
}
