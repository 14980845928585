import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const NARRATIVE_7TH_GRADE_DOWNLOADS_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/075293a7-61fb-4eea-a236-27d22faa9466',
    headingConfig: {
        letter: 'F',
        grade: 'Grade 7',
        level: 'Level F',
        color: 'var(--program-grade-level-f',
    },
    slideDeckHeading: 'Narrative Writing Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level F/Narrative_Writing_Student_Slide_Decks_ (1).zip',
    digitFilesConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing?',
            content: [
                {
                    text: 'What is Narrative Writing?',
                    url: 'https://docs.google.com/presentation/d/1UtOQ2oVaGy0xf3_kSEru5F1FdyR6qmA6OBR5kIahscE/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Brainstorming',
                    url: 'https://docs.google.com/presentation/d/1u0mcLw6Tj-mPW44aQtodQ3v0_XYJTtvLc_xYnnz64Zo/copy',
                },
                {
                    text: 'Narrative Writing Quilt',
                    url: 'https://docs.google.com/presentation/d/1tbJoIyn0eognUc9e728NwWbXb7dnpmoMJgiKDVo0r4o/copy',
                },
                {
                    text: 'Understanding POV',
                    url: 'https://docs.google.com/presentation/d/1_aF-pH4QAXyue6OCD5ClYHLIyrXJtOv0KXQ8Ju6bnPs/copy',
                },
                {
                    text: 'Writing Leads',
                    url: 'https://docs.google.com/presentation/d/1HdowtzfCo382pSWQEXdhN0ZrzMgPM-9hLzb28U6WRfA/copy',
                },
                {
                    text: 'Creating Endings',
                    url: 'https://docs.google.com/presentation/d/1lI81fNyoouREa8dBaGV_Ch4v8PUbbNybdoS_Nfrh4l8/copy',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://docs.google.com/presentation/d/1_jHIig4KJkbMhEPiNVm7lP5d7Ld_6K1m0gDNYFBqD2U/copy',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://docs.google.com/presentation/d/1K_NMDj8QzpGXJjkzTEw1xlq_oUyDRNLLRWt16JQ2hig/copy',
                },
                {
                    text: 'Dialogue',
                    url: 'https://docs.google.com/presentation/d/1Y8bJ-HvEWRdXGubSkKuf1OSS3s8kpejRfakVx-5LARU/copy',
                },
                {
                    text: 'Transition Words',
                    url: 'https://docs.google.com/presentation/d/1tJVuVL6TeGF5kwk53MIP36-t2DflUPAEdu_2WGLy3Zo/copy',
                },
                {
                    text: 'Story Arc',
                    url: 'https://docs.google.com/presentation/d/11Gkty7AsezQ7x_aQZ1NfalED6Qe7hUUeQqkthvAiXH8/copy',
                },
            ],
        },
        {
            value: 'theWritingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'All About Figurative Language',
                    url: 'https://docs.google.com/presentation/d/1U1H1epVqX3ddJ5UD2UuHojhI9pUURLn7qlgi1mf6-C8/copy',
                },
                {
                    text: 'Narrative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1nUMsFJf5-o8guPaPZRQJIrSJ30fcjO5-E3KgqTqYr44/copy',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://docs.google.com/presentation/d/1795ZVh9AZYGipX7CC9S1qyutjUPdNRJ8LDFq1PGsGAc/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing',
            content: [
                {
                    text: 'What is Narrative Writing',
                    url: 'https://drive.google.com/file/d/1lOrU3vpjelo317n5pw2_OlKdpBiEz1wj/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'The Mini Lessons',
            content: [
                {
                    text: 'Brainstorming for Your Narrative',
                    url: 'https://drive.google.com/file/d/1ilNBv-laOJ4R8kJ8cnPM_KZ-8R4q7IFc/view?usp=drive_link',
                },
                {
                    text: 'The Story Arc',
                    url: 'https://drive.google.com/file/d/1pnWRsguB2gMtVjFljFMfe8G19EI_n99E/view?usp=drive_link',
                },
                {
                    text: 'Point of View',
                    url: 'https://drive.google.com/file/d/11OccwLGgbcpUX7s531Mq2dm50ChUGc7G/view?usp=drive_link',
                },
                {
                    text: 'Leads in Narratives',
                    url: 'https://drive.google.com/file/d/1rqS4KFe7hFv_W3_j_QeBGe-W6JcPtZWo/view?usp=drive_link',
                },
                {
                    text: 'Endings in Narratives',
                    url: 'https://drive.google.com/file/d/1PGeXrji1pTLRZH1WZ1ey3UvxQAMAlLAv/view?usp=drive_link',
                },
                {
                    text: 'Dialogue',
                    url: 'https://drive.google.com/file/d/1w6XVXi4eT3JOESCyvvGTpXgZpESS36kg/view?usp=drive_link',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://drive.google.com/file/d/1vE2hlhikbcuUZJWNOH83ZIdFLKkGrr8d/view?usp=drive_link',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://drive.google.com/file/d/1LIfws3_bLIrXHAF7yK3sYJTZgiA9N68c/view?usp=drive_link',
                },
                {
                    text: 'Transition Words',
                    url: 'https://drive.google.com/file/d/1ptRjtr_kWZETOAmY5IgFIGygudK4y0-X/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'writingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'Your Rough Draft',
                    url: 'https://drive.google.com/file/d/10zZVBvPoxotG7Jj7sfOmO0TbUp5EM3sd/view?usp=drive_link',
                },
                {
                    text: 'Figurative Language',
                    url: 'https://drive.google.com/file/d/10xghJ0qjNVU2UqXZBWfrHdr2d-MQXcM3/view?usp=drive_link',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://drive.google.com/file/d/14Wj-BH62H-gRdkd-8pFKSRBPzn7aiqIw/view?usp=drive_link',
                },
                {
                    text: 'Peer Editing',
                    url: 'https://drive.google.com/file/d/13biOsEzI3p0Ms_L04ktZoE487_Ap15YX/view?usp=drive_link',
                },
                {
                    text: 'Revising Your Narrative',
                    url: 'https://drive.google.com/file/d/18ky6bTnWoC_Y5iTd5fdhssI1Fih2COO9/view?usp=sharing',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level F/Certificate_of_Completion_Narrative_Writing (1).pdf',
};
