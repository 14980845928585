import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const PERSUASIVE_AND_ARGUMENTATIVE_5TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Persuasive Writing 5th Grade',
            url: '/resources/264baeb7-4da6-462c-9f2f-6f9f80284d5a',
        },
        {
            text: 'Argumentative Writing 5th Grade',
            url: '/resources/ee24e8f9-9554-4089-a23e-79e70f4f454e',
        },
    ],
    headingConfig: {
        letter: 'D',
        level: 'Level D',
        grade: 'Grade 5',
        color: 'var(--program-grade-level-d)',
    },
    slideDeckHeading: 'Persuasive and Argumentative Slide Decks',
    slideDeckUrl: [
        {
            text: 'Argumentative_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Argumentative_Slide_Decks (2).zip',
        },
        {
            text: 'Persuasive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Persuasive_Writing_Slide_Decks (2).zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'argumentativeWriting',
            heading: 'Argumentative Writing',
            content: [
                {
                    text: 'Argumentative Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1j8ieAjhEgz3t4CgapgrjHI7Ow-XnZi_Aa4g1svieF_A/copy',
                },
                {
                    text: 'Case Report',
                    url: 'https://docs.google.com/presentation/d/1F6mN8ZcmNOT7Wh0sKiGBIuCbheKZmGT0Ygnw6ywFU7E/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1tBqj6z_pEwFlf49HzEao5OHsMgGOdhWv1_kK8EQetqw/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1Iodr1eFy60f4to0ch131y_g1Zq7aYpmglNg6Gje2j2w/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1B5kxBZ4UNrtGzsRZ-OenExQGOuVH2Jd2shDprwgXwh4/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1Dp27fgpXLqPxtnKtFkffI6i5gH7HouN2ScSyGrb_E0o/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1CEh3R243snAjwe2pplU66F0ELWCD59FIBiVH2bUk7ls/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1dHijLLmHTLaSfL9Q2chKkXzkVYq6r3kczwOF2HxR6xs/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1R_w5yvZNtGEwARR4yo8mWZ2F09Iw8av4i4uXef3feRM/copy',
                },

                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1YB5cT6XbDPqKfIWwEsR6YQ1Mp1kgj9_lK9yUSim1cXo/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1XRT6nek8TwVIk79hE5kx9nInymsW3ydZBVkBU829IQQ/copy',
                },
                {
                    text: 'Claims and Counterclaims Activity',
                    url: 'https://docs.google.com/presentation/d/12va--3vzejM-nIJW8M9bwb5y1ElXpijY2o-t-NC50uc/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1aSfszwYdcovkmz4D6-jBwrH2GRlFNwuMNLsNUtAkDWg/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1NsfYIpDV6hKweKD6G-wBKzxTrw47nB4wr1NXQ1YTeYk/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1NMRi1xg-8lxm06z53nXtKA0qBem94uim4vYejeRixcc/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1FKe174eb426-VQN0AwVIeOlU_4fi3fA6KbKLLtw51xM/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1lzffHarWIKs3rd3OxOpb1PjC-hRQPmgpmJeGMDo1MlU/copy',
                },
                {
                    text: 'Argumentative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1j8ieAjhEgz3t4CgapgrjHI7Ow-XnZi_Aa4g1svieF_A/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/12tg3Ks65dyeZ5f59Eiz8_TE3VnZJ3uVKoAF-wNygZvQ/copy',
                },
            ],
        },
        {
            value: 'persuasiveWriting',
            heading: 'Persuasive Writing',
            content: [
                {
                    text: 'Persuasive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1ZDNzLJ-ra0etv6iheq6VT5eDBFiJpQ9tMKbHt7RY6Bo/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1nbDLQ2zyTuRlopf-R1PfxgfQxf4KGda2gQq9xw0tghA/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1WUHMf7dwRIGUr2qRNlCoRgoKOYmmkRmlWge8tPn9RQg/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1I0FWyi7rCPiVLmJhnPnrYlJ_w3PVbXrduktZXWhklPE/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1v-N6dvtB3EGhnZBX4Nj-qiLpyr6jGv105pFnb3UiBQ0/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1T-wlFvVNg4soThCCGaKh8CT-boiALxwKf0-CrQ5AhMQ/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1q-p_Bis7QrEVlwjP6fIlYIkxmxkg8YceMubPeMMft4k/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1tBs2vIkGYFbx-4id1-3h7kjKiDuw95DfGEm_0Q2M5q8/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1tvx8D9tgdClNNLXBuGoNPTrt3TIkxHJwMKsDmMHaqL4/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1YB8jvNi910FHTPA_EstUpQUzGnlBytjhageBta7vYiw/copy',
                },
                {
                    text: 'Practice with the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1_1of9w9EAiHUw-8tVrvvlXDhplgwtYPQ5-Wtf4jewRA/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1EGsH9P5tIDOTiB7SJ5-i9J5vBPfaHaJkzWFL7WCWZZw/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1Kfav-ASEN_gS_CDu7HqVB9BUG5Jc4WAd0usawCfxFPg/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1OXMG1oIy3E7R0ZoyNOsaiTCMcT09-0cJvjOTYqlBj9Y/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1p-thgNmyqKq3w21aS9k6la9J2cBGxIAZ2GQoCgHmiCQ/copy',
                },
                {
                    text: 'Persuasive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1GIUFH9OTVZDdVctMH8NnKlQVGDsUU7hfOrzqriYm-c4/copy',
                },
                {
                    text: 'Helpful Hints',
                    url: 'https://docs.google.com/presentation/d/1CeUoU8LNcX8UHDFUHp9AYZJnF4uobhxzCRZetlfI2WA/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1i2xRONiDkQSlgAvDE2ztmVplFUBV5mVedG0uTdSNIBI/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'persuasiveWriting',
            heading: 'Persuasive Writing Introduction',
            content: [
                {
                    text: 'What Is Persuasive Writing',
                    url: 'https://drive.google.com/file/d/1X4ChVNYxnjEjj-cBU5ceYXBfXBlvVMv1/view?usp=drive_link',
                },
                {
                    text: 'Preparing for a Persuasive Essay',
                    url: 'https://drive.google.com/file/d/1mGpH4ne0xsF_XzaqEsHJIMY8yaGrOlZI/view?usp=drive_link',
                },
                {
                    text: 'Rhetorical Devices',
                    url: 'https://drive.google.com/file/d/1e1prQWzX2YbjBe9ECplxEMZ5OjKMj5wN/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphPW',
            heading: 'The Introductory Paragraph | Persuasive Writing',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/17MWfCE-5m4YZmqsnAE84KQcOPbcS001P/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1TyyGoeNfcvszzEoxIWVhaPqmWdruUYFI/view?usp=drive_link',
                },
                {
                    text: 'The Claim',
                    url: 'https://drive.google.com/file/d/17i66yb-ncywMIRwgT0mwqj6u_u-mnrfY/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1rCJkqe2Inbng6Z52DegLigufeS9eHjIp/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphPW',
            heading: 'The Body Paragraph | Persuasive Writing',
            content: [
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/1jtbZ0we1msQNdwBhg8MWDI8WoHDq4tib/view?usp=drive_link',
                },
                {
                    text: 'The Introduction to Evidence',
                    url: 'https://drive.google.com/file/d/1ta7ugOt4aaljCX-hmWSmSw8Ef2fqcsr5/view?usp=drive_link',
                },
                {
                    text: 'The Evidence',
                    url: 'https://drive.google.com/file/d/1h1-xFepT9-FIZxlYX1YK-tcaqPEZ6p7g/view?usp=drive_link',
                },
                {
                    text: 'Justification',
                    url: 'https://drive.google.com/file/d/10JQscxnQkupvWV_cR95lwHzqCIxmHg30/view?usp=drive_link',
                },
                {
                    text: 'Practice with Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1fJv92515dgpiJ-qizS9-p9yYNxGzkfwO/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphPW',
            heading: 'The Conclusion Paragraph | Persuasive Writing',
            content: [
                {
                    text: 'Restatement of the Claim',
                    url: 'https://drive.google.com/file/d/1PkmoBCwoSbmuCRyBG0jH-Py6ClVzXkn4/view?usp=drive_link',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://drive.google.com/file/d/1fEfnQSdU4YnpoLIrjSMrTtixTdAtPvBq/view?usp=drive_link',
                },
                {
                    text: 'Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1yXv4qPqSaYrzGltfmCd5P0JOa3Lv1SqL/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1CoyCGt6ix4vZfklqRdWPEWbseXs-KtwH/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'argumentativeWriting',
            heading: 'Argumentative Writing Introduction',
            content: [
                {
                    text: 'What is Argumentative Writing',
                    url: 'https://drive.google.com/file/d/1E-N08-4Bz5C1Gx2OG_UHhZm75LorOrTY/view?usp=drive_link',
                },
                {
                    text: 'Preparing for an Argumentative Essay',
                    url: 'https://drive.google.com/file/d/1eI-V8sDpNFL0GYVmBEE1oX0dmgR8pB-6/view?usp=drive_link',
                },
                {
                    text: 'Introducing the Concept',
                    url: 'https://drive.google.com/file/d/1kxSZlTRgZOQhx8KXogST1WYCXhRZtykM/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphAW',
            heading: 'The Introductory Paragraph | Argumentative Writing',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1w6Q1g91mULo7g4quxLWsrYrGOvDryi8M/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1ugtzUhRaXsGprVa7KQ_5x8s8RBfmVERn/view?usp=drive_link',
                },
                {
                    text: 'The Claim',
                    url: 'https://drive.google.com/file/d/1f-4geS1xhGQQMll0f6lJnTT4rFyaoISI/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1JyYXfXKKX74s3Ib2aAAR7HOvm0b4zQ6r/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphAW',
            heading: 'The Body Paragraph | Argumentative Writing',
            content: [
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/1eX8xyJMYfwvgtBTn7jPYeh7f_ccOp8X_/view?usp=drive_link',
                },
                {
                    text: 'Introduction to Evidence',
                    url: 'https://drive.google.com/file/d/1GkRlkxy0l_EKYaveI7d_xzV1yhGTLbfJ/view?usp=drive_link',
                },
                {
                    text: 'The Evidence',
                    url: 'https://drive.google.com/file/d/1azp-iC6hGvP6sxyuJ9llDrv0KdtE82DH/view?usp=drive_link',
                },
                {
                    text: 'Justification',
                    url: 'https://drive.google.com/file/d/1K839Ibvky-xP24AndIMiF4MyLCP7kOA7/view?usp=drive_link',
                },
                {
                    text: 'The Counterclaim',
                    url: 'https://drive.google.com/file/d/102-0jzpzLcafA-p5BEPgkSJrrwxCJSgr/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1s2BDcExhT2HNS1KnnBbLV2fPToBYuxjN/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphAW',
            heading: 'The Conclusion Paragraph | Argumentative Writing',
            content: [
                {
                    text: 'Restatement of the Claim',
                    url: 'https://drive.google.com/file/d/1L58FXxrXqZtYN7dSXeJopMIvFYjNBFm7/view?usp=drive_link',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://drive.google.com/file/d/1-LPO1q_W5jevBfOt2vUnLeFPB28t9fFY/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1EyWEGsMSiug4kx0Y0PkJxbkaXfoC4fVK/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1opwW1IYXOIIRQu8Wi3PMlkAQulwMyRxl/view?usp=drive_link',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Certificate_of_Completion_Argumentative_and_Persuasive_Writing (2).pdf',
};
