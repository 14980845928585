import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import {
    ClientMetadata,
    CognitoUserAttribute,
    CognitoUserPool,
    IAuthenticationDetailsData,
    ISignUpResult,
    NodeCallback,
} from 'amazon-cognito-identity-js';
import { CognitoObjectFactory } from './cognito-object-factory.service';
import { Email, Uuid } from '@lib/shared-interface-utility-types';
import {
    CustomChallengeError,
    MfaRequiredError,
    MfaSetupError,
    NewPasswordRequiredError,
    SelectMfaTypeError,
    TotpRequiredError,
} from './signin-errors.exception';
import {
    ApolloAuthenticatedAccountGQL,
    ApolloCheckCanRegisterGQL,
    ApolloUserUpdateAccountEmailGQL,
    handleMutationResult,
} from '@lib/frontend-shared-data-gql-operations';
import { ApiHealthService } from '@lib/frontend-shared-data-api-health-service';
import {
    LOCAL_STORAGE_TOKEN,
    SESSION_STORAGE_TOKEN,
    WINDOW_TOKEN,
} from '@lib/frontend-shared-util-miscellaneous-providers';
import { Apollo } from 'apollo-angular';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private tokenRenewalSubscription?: Subscription;
    private readonly activeAuthenticationId = new ReplaySubject<Uuid | undefined>(1);

    /**
     * Emits whenever the logged in account's state is changed
     * and when new Access and ID tokens are acquired.
     *
     * This is a replay subject instead of a behavior subject intentionally.
     * We do not want an initial value as it creates undesired race conditions.
     */
    private readonly authenticationState = new ReplaySubject<AuthenticationState | undefined>(1);
    public readonly authenticationState$: Observable<AuthenticationState | undefined> =
        this.authenticationState.asObservable();

    public constructor(
        private readonly apiHealthService: ApiHealthService,
        private readonly apollo: Apollo,
        private readonly authenticatedAccountGql: ApolloAuthenticatedAccountGQL,
        private readonly cognitoObjectFactory: CognitoObjectFactory,
        private readonly checkCanRegisterGql: ApolloCheckCanRegisterGQL,
        @Inject(LOCAL_STORAGE_TOKEN)
        private readonly localStorage: Storage,
        // Use session storage so that 2 tabs can be open but logged into separate accounts.
        @Inject(SESSION_STORAGE_TOKEN)
        private readonly sessionStorage: Storage,
        private readonly userPool: CognitoUserPool,
        private readonly userUpdateAccountEmailGql: ApolloUserUpdateAccountEmailGQL,
        @Inject(WINDOW_TOKEN) private readonly window: Window,
    ) {}

    private get lastAuthUserKey(): string {
        const userPoolClientId = this.userPool.getClientId();
        return `CognitoIdentityServiceProvider.${userPoolClientId}.LastAuthUser`;
    }

    private get accessTokenStorageKey(): string {
        const userPoolClientId = this.userPool.getClientId();
        // The last authentication user that cognito sets in localstorage
        // is not always the correct authentication id to use.
        // If they have logged into a different account in another tab,
        // Then refresh the page of the first account, we should stay on the first account.
        const authenticationId =
            this.getStoredActiveAuthenticationId() ?? this.getLastAuthenticationId();
        if (!authenticationId) return '';

        return `CognitoIdentityServiceProvider.${userPoolClientId}.${authenticationId}.accessToken`;
    }

    public stopTokenRenewal() {
        this.tokenRenewalSubscription?.unsubscribe();
    }

    public async initAuthenticationState(): Promise<void> {
        this.stopTokenRenewal();

        // Immediately run token renewal for the last logged-in user.
        await this.renewToken();

        // Run token renewal every 4 minutes.
        // We have fixed this to 4 minutes because the minimum access token expiration time is 5 minutes.
        // Therefore, we will be forcing refresh with plenty of time no matter the access token expiration.
        // Todo: Eventually, a more accurate approach could be implemented which depends on actual expiration times.
        const fourMinutes = getMinutesInMilliseconds(4);

        // While we could do `timer(0, fourMinutes)` here
        // and remove the above `await this.renewToken()`,
        // the reason we don't is that we need to ensure the first authentication happens
        // before the application has started.
        const renewalTime$ = timer(fourMinutes, fourMinutes);
        this.tokenRenewalSubscription = renewalTime$.subscribe(() => void this.renewToken());
    }

    /**
     * Signs in to Cognito.
     *
     * Returns a promise of login.
     * On success, it will update the appropriate entry within `accountsAuthenticationState$`
     * with `isLoggedIn: true`.
     * On failure, it will update the appropriate entry within `accountsAuthenticationState$`
     * with `isLoggedIn: false`.
     * It will request a new password if required.
     */
    public async signIn(credentials: SignInParameters): Promise<CognitoUserAttribute[]> {
        const email = credentials.username.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(email);
        const configuration = createAuthenticationDetailsData(credentials);
        const authenticationDetails =
            this.cognitoObjectFactory.getAuthenticationDetails(configuration);

        const authResult = await cognitoUser.authenticateUser(authenticationDetails);

        if (authResult.type !== 'success') this.authenticationState.next(undefined);

        // Todo: We don't currently handle these authentication responses. We probably should.
        //  For now I just throw to signal that we aren't handling these situations.
        if (authResult.type === 'newPasswordRequired')
            throw new NewPasswordRequiredError(
                authResult.userAttributes,
                authResult.requiredAttributes,
            );
        if (authResult.type === 'mfaRequired')
            throw new MfaRequiredError(authResult.challengeName, authResult.challengeParameters);
        if (authResult.type === 'totpRequired')
            throw new TotpRequiredError(authResult.challengeName, authResult.challengeParameters);
        if (authResult.type === 'customChallenge')
            throw new CustomChallengeError(authResult.challengeParameters);
        if (authResult.type === 'mfaSetup')
            throw new MfaSetupError(authResult.challengeName, authResult.challengeParameters);
        if (authResult.type === 'selectMFAType')
            throw new SelectMfaTypeError(authResult.challengeName, authResult.challengeParameters);
        if (authResult.type === 'failure') throw authResult.error;

        const attributesResult = await cognitoUser.getUserAttributes();
        if (attributesResult.type === 'failure') throw attributesResult.error;

        const authenticationId = getUserAttribute('sub', attributesResult.data);
        if (!authenticationId)
            throw new Error('Authentication is broken and may not work until it is fixed.');

        await this.setAuthenticationState(authenticationId);

        return attributesResult.data;
    }

    /**
     * Sets authenticationState for given id
     *
     * Checks for a Cognito session via valid refresh token.
     * if token is valid, a session is received,
     * if not, related account tokens are removed from storage
     *
     * The authenticationStates$ Observable will then be updated
     * with that accounts status loggedIn status accordingly.
     */
    public async setAuthenticationState(authenticationId: string): Promise<void> {
        const resetLastAuthUser = this.setLastAuthUser(authenticationId);
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(authenticationId);
        const setState = (isLoggedIn: boolean) => {
            this.authenticationState.next({ authenticationId, isLoggedIn });
            resetLastAuthUser();
            if (isLoggedIn) this.updateActiveAuthenticationId(authenticationId);
        };
        const sessionResult = await cognitoUser.getSession();

        if (sessionResult.type === 'failure') {
            setState(false);
            return;
        }

        const refreshToken = sessionResult.data.getRefreshToken();
        const refreshResult = await cognitoUser.refreshSession(refreshToken);

        if (refreshResult.type === 'failure') {
            setState(false);

            // Reload the window on failed session refresh to
            // remove all user-specific data from memory.
            // This also returns the user to the login page
            this.window.location.reload();
            return;
        }

        setState(true);
    }

    /**
     * Signs Out of Cognito
     *
     * Provided an authenticationId, this will log out the given user,
     * invalidating their session and removing their tokens from storage.
     * The authenticationStates BehaviorSubject and Observable will be updated accordingly.
     * */
    public async signOut(reload = true): Promise<void> {
        const authenticationId =
            this.getStoredActiveAuthenticationId() ?? this.getLastAuthenticationId();
        if (!authenticationId) return;

        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(authenticationId);
        await cognitoUser.signOut();

        // Clearing the cache is a security precaution.
        void this.apollo.client.clearStore();

        this.updateActiveAuthenticationId(undefined);
        await this.setAuthenticationState(authenticationId);

        // Reload the window on logout to
        // remove all user-specific data from memory
        if (reload) this.window.location.reload();
    }

    public getAccessToken(): string | undefined {
        const accessTokenStorageKey = this.accessTokenStorageKey;
        return this.localStorage.getItem(accessTokenStorageKey) ?? undefined;
    }

    /**
     * Send a password reset code.
     *
     * Uses Cognito to send a confirmation code for resetting a password.
     * This is successful even if no Cognito user exists for the email.
     */
    public async requestPasswordReset(username: string): Promise<void> {
        const email = username.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(email);
        const result = await cognitoUser.forgotPassword();
        if (result.type === 'failure') throw result.error;
    }

    /**
     * Sends a confirmation to Cognito to reset the password of the given username.
     *
     * @param authIdOrEmail This is the email alias, so this method is expecting the email as the username input
     * @param verificationCode This is of length 6;
     * @param newPassword
     */
    public async confirmPassword(
        authIdOrEmail: string,
        verificationCode: string,
        newPassword: string,
    ): Promise<string> {
        authIdOrEmail = authIdOrEmail.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(authIdOrEmail);
        const result = await cognitoUser.confirmPassword(verificationCode, newPassword);
        if (result.type === 'failure') throw result.error;

        return result.data;
    }

    /**
     * @param username This is the email alias, so this method is expecting the email as the username input
     *
     * More info to come when registration is added. I think this one is related to that method.
     */
    public async resendConfirmationCode(username: string): Promise<unknown> {
        const email = username.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(email);
        const result = await cognitoUser.resendConfirmationCode();
        if (result.type === 'failure') throw result.error;

        return result.data;
    }

    /**
     * Sends a request to Cognito to register a new account and updates account in backend.
     */
    public async register(email: string, password: string): Promise<void> {
        email = email.toLowerCase();
        const emailAttribute = this.cognitoObjectFactory.createUserAttribute('email', email);
        await this.signUp(email, password, [emailAttribute], []);
    }

    public emailCanRegister(email: string): Promise<boolean> {
        const result$ = this.checkCanRegisterGql.fetch({ email });
        const canRegister$ = result$.pipe(map((result) => result.data.canRegister));

        return firstValueFrom(canRegister$);
    }

    public async confirmRegistration(
        email: Email,
        code: string,
        forceAliasCreation = true,
    ): Promise<void> {
        email = email.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(email);
        const result = await cognitoUser.confirmRegistration(code, forceAliasCreation);
        if (result.type === 'failure') throw result.error;
    }

    public async requestEmailChange(newEmail: Email): Promise<void> {
        newEmail = newEmail.toLowerCase();

        const emailAttribute = this.cognitoObjectFactory.createUserAttribute('email', newEmail);
        const cognitoUser = this.cognitoObjectFactory.getCurrentCognitoUser();
        // Calling get session populates authentication details for subsequent calls
        // See https://github.com/aws-amplify/amplify-js/issues/8064#issuecomment-938017620.
        const sessionResult = await cognitoUser.getSession();
        if (sessionResult.type === 'failure') throw sessionResult.error;

        const attributesResult = await cognitoUser.getUserAttributes();
        if (attributesResult.type === 'failure') throw attributesResult.error;

        const currentEmail = getUserAttribute('email', attributesResult.data);
        if (currentEmail == undefined) throw new Error('Could not confirm current email.');
        if (currentEmail === newEmail) throw new Error(`Email already set to ${newEmail}.`);

        const updateResult = await cognitoUser.updateAttributes([emailAttribute]);
        if (updateResult.type === 'failure') throw updateResult.error;
    }

    public async verifyEmailChange(confirmationCode: string) {
        const cognitoUser = this.cognitoObjectFactory.getCurrentCognitoUser();

        // Calling get session populates authentication details for subsequent calls
        // See https://github.com/aws-amplify/amplify-js/issues/8064#issuecomment-938017620.
        const sessionResult = await cognitoUser.getSession();
        if (sessionResult.type === 'failure') throw sessionResult.error;

        await this.apiHealthService.assertReachable();
        await cognitoUser.verifyAttribute('email', confirmationCode);
        await this.updateAccountEmail();
    }

    public updateActiveAuthenticationId(authenticationId: Uuid | undefined): void {
        this.activeAuthenticationId.next(authenticationId);
        authenticationId
            ? this.storeActiveAuthenticationId(authenticationId)
            : this.clearStoredActiveAuthenticationId();
    }

    public getStoredActiveAuthenticationId(): Uuid | undefined {
        const authenticationId = this.sessionStorage.getItem(ACTIVE_AUTHENTICATION_ID_KEY);
        if (authenticationId) return authenticationId;

        // It's important to fall back to whatever cognito stores in local storage
        // to prevent being directed to the login page when a new session is started.
        return this.getLastAuthenticationId();
    }

    private signUp(
        email: string,
        password: string,
        userAttributes: CognitoUserAttribute[],
        validationData: CognitoUserAttribute[],
        clientMetadata?: ClientMetadata,
    ): Promise<ISignUpResult> {
        // This seems to be the only callback-style method that we use
        // from the user pool at the moment.
        // If we find we need to use more of them, it may be worth wrapping the user pool service.
        return new Promise<ISignUpResult>((resolve, reject) => {
            const callback: NodeCallback<Error, ISignUpResult> = (error, result) => {
                if (error) return reject(error);
                if (result) return resolve(result);
                throw new Error('No error or result during signup.');
            };
            this.userPool.signUp(
                email,
                password,
                userAttributes,
                validationData,
                callback,
                clientMetadata,
            );
        });
    }

    private async renewToken() {
        // Checks session-stored auth id first to avoid overwriting on reload.
        const authenticationId =
            this.getStoredActiveAuthenticationId() ?? this.getLastAuthenticationId();
        if (!authenticationId) {
            this.authenticationState.next(undefined);
            return;
        }

        await this.setAuthenticationState(authenticationId);
    }

    private getLastAuthenticationId(): Uuid | undefined {
        const key = this.lastAuthUserKey;
        return this.localStorage.getItem(key) ?? undefined;
    }

    private setLastAuthUser(authenticationId: Uuid): () => void {
        // This workaround is required because amazon-cognito-identity-js always uses LastAuthUser
        // even if you hand it a different authentication id.
        const key = `CognitoIdentityServiceProvider.${this.userPool.getClientId()}.LastAuthUser`;
        const originalId = this.localStorage.getItem(key) ?? undefined;
        this.localStorage.setItem(key, authenticationId);

        if (originalId === undefined) return () => this.localStorage.removeItem(key);
        return () => this.localStorage.setItem(key, authenticationId);
    }

    private updateAccountEmail(): Promise<Uuid> {
        const result$ = this.userUpdateAccountEmailGql.mutate(undefined, {
            refetchQueries: [{ query: this.authenticatedAccountGql.document }],
        });
        const id$ = result$.pipe(
            handleMutationResult(),
            map((data) => data.userUpdateAccountEmail.id),
        );

        return firstValueFrom(id$);
    }

    private storeActiveAuthenticationId(authenticationId: Uuid): void {
        this.sessionStorage.setItem(ACTIVE_AUTHENTICATION_ID_KEY, authenticationId);
    }

    private clearStoredActiveAuthenticationId(): void {
        this.sessionStorage.removeItem(ACTIVE_AUTHENTICATION_ID_KEY);
    }
}

const ACTIVE_AUTHENTICATION_ID_KEY = 'activeAuthenticationId';

function getMinutesInMilliseconds(minutes: number): number {
    return minutes * 60 * 1000;
}

function getUserAttribute(
    name: string,
    cognitoUserAttributes: CognitoUserAttribute[],
): string | undefined {
    return cognitoUserAttributes.find((attribute: CognitoUserAttribute) => attribute.Name === name)
        ?.Value;
}

function createAuthenticationDetailsData(
    credentials: SignInParameters,
): IAuthenticationDetailsData {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
        Username: credentials.username.toLowerCase(),
        Password: credentials.password,
        ValidationData: {
            code: credentials.code,
        },
    };
    /* eslint-enable @typescript-eslint/naming-convention */
}

export interface AuthenticationState {
    authenticationId: string;
    isLoggedIn: boolean;
}

interface SignInParameters {
    username: string;
    password: string;
    code?: string | undefined;
}
