import { SchoolJobTitle } from './school-job-title.interface';
import { Uuid } from '@lib/shared-interface-utility-types';
import { GradeLevel } from '@lib/shared-util-global-constants';
import { SchoolUserType } from './school-user-type.interface';
import {
    AnySurveyResult,
    RawSurveyResultUpdate,
    SurveyResultUpdate,
} from '@lib/shared-interface-survey-question';
import { SchoolAccountRole } from './school-account-role.interface';
import { SchoolAccountPlan } from './school-account-plan.interface';

/**
 * This extra data only applies to school accounts.
 */
export type SchoolUserData = {
    /**
     * The type of user so we can differentiate what properties should exist.
     * Will be unset when user is missing certain properties.
     */
    userType?: SchoolUserType;

    plan: SchoolAccountPlan;
    roles: SchoolAccountRole[];
    /**
     * The grades that this educator is teaching or intends to teach.
     */
    gradesTaught?: GradeLevel[];
    /**
     * The job title of this particular educator.
     */
    jobTitle?: SchoolJobTitle;
    /**
     * When our kajabi events webhook is called,
     * we need to record the member id for handling future events.
     */
    kajabiMemberId?: number;
    /**
     * The id of the school customer with which an individual school account is affiliated.
     */
    schoolCustomerId?: Uuid;
    /**
     * The id of the NCES-imported school with which an individual school account is affiliated.
     */
    schoolId?: string;
    /**
     * If they can't find their affiliated school in our imports,
     * they may offer up their own school name.
     */
    schoolName?: string;
    /**
     * If they can't find their affiliated school in our imports or if they homeschool,
     * they may offer up their own school country.
     */
    schoolCountry?: string;
    /**
     * If they can't find their affiliated school in our imports or if they homeschool,
     * they may offer up their own school state.
     */
    schoolState?: string;
    /**
     * If they can't find their affiliated school in our imports or if they homeschool,
     * they may offer up their own school city.
     */
    schoolCity?: string;
    /**
     * This user's responses to the registration survey.
     */
    registrationSurveyResults?: AnySurveyResult[];
    /**
     * Whether the registration survey is completed.
     * This will need to be calculated by a field resolver.
     */
    registrationSurveyCompleted?: boolean;
    /**
     * Whether they watched the registration welcome video.
     */
    watchedWelcomeVideo?: boolean;
};

// The reason I'm doing Omit instead of pick
// is that there will be additional user data questions added soon.
export type RawSchoolUserDataUpdate = Partial<EditableSchoolUserData> & {
    // This is a workaround for graphql not allowing union types in their inputs.
    registrationSurveyResults?: RawSurveyResultUpdate[];
};
(({}) as RawSchoolUserDataUpdate).plan;

export type SchoolUserDataUpdate = Partial<EditableSchoolUserData> & {
    // This is a workaround for graphql not allowing union types in their inputs.
    registrationSurveyResults?: SurveyResultUpdate[];
};

// Reasons for exclusion:
//  - `userType` is always calculated
//  - `registrationSurveyResults` requires a subtle type change for incoming data
//  - `registrationSurveyCompleted` is always calculated
type EditableSchoolUserData = Omit<
    SchoolUserData,
    'userType' | 'registrationSurveyResults' | 'registrationSurveyCompleted'
>;
