import { SchoolUserType } from './school-user-type.interface';
import { SchoolUserData } from './school-user-data.interface';

export function hasTeachingInfo(
    properties: SchoolUserData | AnySchoolUserProperties,
): properties is AnySchoolUserProperties {
    return (
        // Just checking the existence of this one property is the shortcut to checking all of them.
        Boolean(properties.userType) ||
        // The rest of these are in case user type is unset for any reason
        hasDependentTeachingInfo(properties) ||
        hasIndependentAffiliatedTeachingInfo(properties) ||
        hasIndependentCustomTeachingInfo(properties) ||
        hasIndependentNonAffiliatedTeachingInfo(properties)
    );
}

export function determineSchoolUserType(properties: SchoolUserData): SchoolUserType | undefined {
    const typeCheckerEntries = Object.entries(SCHOOL_USER_TYPE_CHECKERS) as UserTypeCheckTuple[];
    for (const [userType, isType] of typeCheckerEntries) {
        if (isType(properties)) return userType;
    }
    return;
}
type UserTypeCheckTuple = [SchoolUserType, (typeof SCHOOL_USER_TYPE_CHECKERS)[SchoolUserType]];

const SCHOOL_USER_TYPE_CHECKERS: Record<
    SchoolUserType,
    (schoolUserData: SchoolUserData) => boolean
> = {
    [SchoolUserType.DEPENDENT_AFFILIATED]: hasDependentTeachingInfo,
    [SchoolUserType.INDEPENDENT_AFFILIATED]: hasIndependentAffiliatedTeachingInfo,
    [SchoolUserType.INDEPENDENT_AFFILIATED_CUSTOM]: hasIndependentCustomTeachingInfo,
    [SchoolUserType.INDEPENDENT_NON_AFFILIATED]: hasIndependentNonAffiliatedTeachingInfo,
};

export function hasDependentTeachingInfo(
    properties: SchoolUserData | AnySchoolUserProperties,
): properties is DependentRequiredProperties {
    const { schoolCustomerId, jobTitle } = properties as DependentRequiredProperties;

    // We can't use the shortcut of checking user type
    // because that leads to infinite recursion in the userType getter.
    return Boolean(schoolCustomerId) && Boolean(jobTitle);
}

export function hasIndependentAffiliatedTeachingInfo(
    properties: SchoolUserData | AnySchoolUserProperties,
): properties is IndependentAffiliatedRequiredProperties {
    const { schoolId, jobTitle } = properties as IndependentAffiliatedRequiredProperties;

    // We can't use the shortcut of checking user type
    // because that leads to infinite recursion in the userType getter.
    return Boolean(schoolId) && Boolean(jobTitle);
}

export function hasIndependentCustomTeachingInfo(
    properties: SchoolUserData | AnySchoolUserProperties,
): properties is IndependentCustomRequiredProperties {
    const { jobTitle, schoolName, schoolCountry, schoolState, schoolCity } =
        properties as IndependentCustomRequiredProperties;

    // We can't use the shortcut of checking user type
    // because that leads to infinite recursion in the userType getter.
    return (
        Boolean(jobTitle) &&
        Boolean(schoolName) &&
        Boolean(schoolCountry) &&
        Boolean(schoolState) &&
        Boolean(schoolCity)
    );
}

export function hasIndependentNonAffiliatedTeachingInfo(
    properties: SchoolUserData | AnySchoolUserProperties,
): properties is NonAffiliatedRequiredProperties {
    const { jobTitle, schoolCountry, schoolState, schoolCity } =
        properties as NonAffiliatedRequiredProperties;

    // We can't use the shortcut of checking user type
    // because that leads to infinite recursion in the userType getter.
    return (
        Boolean(jobTitle) && Boolean(schoolCountry) && Boolean(schoolState) && Boolean(schoolCity)
    );
}

export type DependentRequiredProperties = {
    userType: typeof SchoolUserType.DEPENDENT_AFFILIATED;
} & Required<Pick<SchoolUserData, 'schoolCustomerId' | 'jobTitle' | 'gradesTaught'>>;

export type IndependentAffiliatedRequiredProperties = {
    userType: typeof SchoolUserType.INDEPENDENT_AFFILIATED;
} & Required<Pick<SchoolUserData, 'schoolId' | 'jobTitle' | 'gradesTaught'>>;

export type IndependentCustomRequiredProperties = {
    userType: typeof SchoolUserType.INDEPENDENT_AFFILIATED_CUSTOM;
} & Required<
    Pick<
        SchoolUserData,
        'jobTitle' | 'gradesTaught' | 'schoolName' | 'schoolCountry' | 'schoolState' | 'schoolCity'
    >
>;

export type NonAffiliatedRequiredProperties = {
    userType: typeof SchoolUserType.INDEPENDENT_NON_AFFILIATED;
} & Required<
    Pick<
        SchoolUserData,
        'jobTitle' | 'gradesTaught' | 'schoolCountry' | 'schoolState' | 'schoolCity'
    >
>;

type AnySchoolUserProperties =
    | DependentRequiredProperties
    | IndependentAffiliatedRequiredProperties
    | IndependentCustomRequiredProperties
    | NonAffiliatedRequiredProperties;
