import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const NARRATIVE_8TH_GRADE_DOWNLOADS_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/c68938fc-0cd6-4f17-a848-f1938e970097',
    headingConfig: {
        letter: 'G',
        grade: 'Grade 8',
        level: 'Level G',
        color: 'var(--program-grade-level-g',
    },
    slideDeckHeading: 'Narrative Writing Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level G/Narrative_Writing_Student_Slide_Decks_.zip',
    digitFilesConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing?',
            content: [
                {
                    text: 'What is Narrative Writing?',
                    url: 'https://docs.google.com/presentation/d/1m2BFFAQFeVCCbXrLUoTIAXeXS8yUF3Xah_V_AxmuGQM/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Brainstorming',
                    url: 'https://docs.google.com/presentation/d/1IHPx3DX7PwN3V-rd6BkMaZQXM2ucX_B3pWrtPOn6gTI/copy',
                },
                {
                    text: 'Narrative Writing Quilt',
                    url: 'https://docs.google.com/presentation/d/1v13WoPNOh8_LXjSR0YCuVQwTacr5goG6Wxr3rnS04h8/copy',
                },
                {
                    text: 'Understanding POV',
                    url: 'https://docs.google.com/presentation/d/13HNFjQ6v3u6dD4YU95KUnnCcpSC92xc2mQ6sfGaaBXY/copy',
                },
                {
                    text: 'Writing Leads',
                    url: 'https://docs.google.com/presentation/d/1LFDoZ24kSGNvjgwZuhweaZUUEqRdCC-7Ltt1BZvMq0w/copy',
                },
                {
                    text: 'Creating Endings',
                    url: 'https://docs.google.com/presentation/d/1tykV0YS2PIHG2UeOWdD1XBb9FmyQOZqy9kGOXblrdiI/copy',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://docs.google.com/presentation/d/1Z_dO2FnlLSDkIBHNcnxzw9YKeRZRVTNpOgnT2hYTyuw/copy',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://docs.google.com/presentation/d/1LGRw4OhlpSG7Hsrirhpujb-nkJspkO7GyPX0dIqGPZI/copy',
                },
                {
                    text: 'Dialogue',
                    url: 'https://docs.google.com/presentation/d/1SSi68Y9IUcaKLZPa1XETwZYu7gFcsAm7HTO7AB4UZA8/copy',
                },
                {
                    text: 'Transition Words',
                    url: 'https://docs.google.com/presentation/d/16hqktTYHNKBmKjG9Kl0M8FpUcUe5RlNNU0Dj3b2iqzE/copy',
                },
                {
                    text: 'Story Arc',
                    url: 'https://docs.google.com/presentation/d/1YQFdLmJxR8lZ1ksweMDKYrJ3obx5nzFrCCd4c83kcZs/copy',
                },
            ],
        },
        {
            value: 'theWritingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'All About Figurative Language',
                    url: 'https://docs.google.com/presentation/d/1-fCU8czqVXMT_uYvIif0Wh00WP3HtdWyGKXA1fNdTc4/copy',
                },
                {
                    text: 'Narrative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/11T5kYb2FrrXKmABT0FDm4_hq5RYhYWZ4FAFgyx9sab4/copy',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://docs.google.com/presentation/d/1Mtjz_bHSEAA-KdnmGbWw8FeoafqZubglv00mtWZ-5_Y/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing',
            content: [
                {
                    text: 'What is Narrative Writing',
                    url: 'https://drive.google.com/file/d/1lOrU3vpjelo317n5pw2_OlKdpBiEz1wj/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'The Mini Lessons',
            content: [
                {
                    text: 'Brainstorming for Your Narrative',
                    url: 'https://drive.google.com/file/d/1ilNBv-laOJ4R8kJ8cnPM_KZ-8R4q7IFc/view?usp=drive_link',
                },
                {
                    text: 'The Story Arc',
                    url: 'https://drive.google.com/file/d/1pnWRsguB2gMtVjFljFMfe8G19EI_n99E/view?usp=drive_link',
                },
                {
                    text: 'Point of View',
                    url: 'https://drive.google.com/file/d/11OccwLGgbcpUX7s531Mq2dm50ChUGc7G/view?usp=drive_link',
                },
                {
                    text: 'Leads in Narratives',
                    url: 'https://drive.google.com/file/d/1rqS4KFe7hFv_W3_j_QeBGe-W6JcPtZWo/view?usp=drive_link',
                },
                {
                    text: 'Endings in Narratives',
                    url: 'https://drive.google.com/file/d/1PGeXrji1pTLRZH1WZ1ey3UvxQAMAlLAv/view?usp=drive_link',
                },
                {
                    text: 'Dialogue',
                    url: 'https://drive.google.com/file/d/1w6XVXi4eT3JOESCyvvGTpXgZpESS36kg/view?usp=drive_link',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://drive.google.com/file/d/1vE2hlhikbcuUZJWNOH83ZIdFLKkGrr8d/view?usp=drive_link',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://drive.google.com/file/d/1LIfws3_bLIrXHAF7yK3sYJTZgiA9N68c/view?usp=drive_link',
                },
                {
                    text: 'Transition Words',
                    url: 'https://drive.google.com/file/d/1ptRjtr_kWZETOAmY5IgFIGygudK4y0-X/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'writingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'Your Rough Draft',
                    url: 'https://drive.google.com/file/d/10zZVBvPoxotG7Jj7sfOmO0TbUp5EM3sd/view?usp=drive_link',
                },
                {
                    text: 'Figurative Language',
                    url: 'https://drive.google.com/file/d/10xghJ0qjNVU2UqXZBWfrHdr2d-MQXcM3/view?usp=drive_link',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://drive.google.com/file/d/14Wj-BH62H-gRdkd-8pFKSRBPzn7aiqIw/view?usp=drive_link',
                },
                {
                    text: 'Peer Editing',
                    url: 'https://drive.google.com/file/d/13biOsEzI3p0Ms_L04ktZoE487_Ap15YX/view?usp=drive_link',
                },
                {
                    text: 'Revising Your Narrative',
                    url: 'https://drive.google.com/file/d/18ky6bTnWoC_Y5iTd5fdhssI1Fih2COO9/view?usp=sharing',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level G/Certificate_of_Completion_Narrative_Writing.pdf',
};
