import { InjectionToken, Provider } from '@angular/core';

export const API_DOMAIN_TOKEN = new InjectionToken<string>('API Domain');

export function provideApiDomain(apiDomain: string): Provider {
    return {
        provide: API_DOMAIN_TOKEN,
        useValue: apiDomain,
    };
}
