export type PaginationOptions = {
    pageIndex: number;
    pageSize: number;
};

export type PaginatedSearchCriteria = PaginationOptions & {
    searchTerm: string;
};

export type PaginatedSearchResult<Item> = {
    items: Item[];
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    totalCount: number;
};
