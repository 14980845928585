import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const SCAFFOLDED_VERSION_CONTENT: DownloadsConfig = {
    resourceLink: '/resources/c9fa7f4c-11f1-454a-b889-fb8853a54b79',
    slideDeckHeading: '',
    slideDeckUrl: '',
    certificateUrl: '',
    headingConfig: {
        letter: 'S',
        level: 'Scaffold',
        grade: 'Version',
        color: 'var(--program-scaffold-blue)',
    },
    digitFilesConfig: [
        {
            value: 'overview',
            heading: 'The EBW Approach',
            content: [
                {
                    text: 'Essential Questions Generator',
                    url: 'https://docs.google.com/presentation/d/11rnLjfEHNFR5gBmXmFE6Lsf7H60HpPUwHkybLQvg-YQ/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1VyXl96ap6IY6sp9zM85UNL6qTaSb7jTCdfgxiKbqU8k/copy',
                },
                {
                    text: 'Pre-Assessment',
                    url: 'https://docs.google.com/presentation/d/1hvxVK_5NQKw0wbhbGyfdVXSNvWNTvG0WpxpPa9xTCcs/copy',
                },
                {
                    text: 'Post-Assessment',
                    url: 'https://docs.google.com/presentation/d/1IyDT5nmUfZ87qFBvcAgOXhQueAotAgNnfhsXWr7eSFM/copy',
                },
                {
                    text: 'Assessment Trackers',
                    url: 'https://docs.google.com/presentation/d/1CqIDhfgsY6DMnoZAst4J75BTOwoVjrB3BKd3UItwFnk/copy',
                },
            ],
        },
        {
            value: 'introductoryParagraph',
            heading: 'The Introductory Paragraph',
            content: [
                {
                    text: 'Introducing EBW Handout',
                    url: 'https://docs.google.com/presentation/d/1-p-zp7SUTqJcsPgjvO2cEyTkk74dx3rPTHYuYILJ2RM/copy',
                },
                {
                    text: 'TAG Practice',
                    url: 'https://docs.google.com/presentation/d/19pRPKrJOOxLJIstK6aCdlKymbLhE5x92RY7AHgCYdmw/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/12N40rxOI1TJYIEGDkO5RLgsxbRxhRtVDcJNzrqn-0Ks/copy',
                },
                {
                    text: 'Claim vs Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1kH-UvGccvN8CAXwY-sWQ1828uj9EBdkLjdrO200rOdc/copy',
                },
                {
                    text: 'Claim vs Statement Sort',
                    url: 'https://docs.google.com/presentation/d/1fzqK8iEiy6QEo48FLui9ms5PpziII5UINZZP-rMiE8E/copy',
                },
                {
                    text: 'Introductory Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1W2j9YAmVPzqNjs1d_0VhHdNk36-YBP7eIQvo1cVLitU/copy',
                },
            ],
        },
        {
            value: 'bodyParagraphs',
            heading: 'The Body Paragraphs',
            content: [
                {
                    text: 'Premise Practice',
                    url: 'https://docs.google.com/presentation/d/10nDhag_Mm-uZ8gG3iuASVAAe3sTcvNTXwVnLxdFI-mE/copy',
                },
                {
                    text: 'Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/17YIvSYeQGUkfGSjH2oaElMk2SlRdtSeocdYp0z-KANo/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1F3mSUjYJmTNCPMKkD0PJc4-4UVgwcI18dGdYN4kUtzM/copy',
                },
                {
                    text: 'Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1eBK9C6SMMab0EsnT9-AfWsbWaIEk_odOY_YWQaLgPVc/copy',
                },
            ],
        },
        {
            value: 'conclusionParagraph',
            heading: 'The Conclusion Paragraph',
            content: [
                {
                    text: 'Restating the Claim Practice',
                    url: 'https://docs.google.com/presentation/d/1h7fzaeFsWaLN2H13ekigK1VtM-h7359m-VhqD9idGvE/copy',
                },
                {
                    text: 'Summarizing Evidence Practice',
                    url: 'https://docs.google.com/presentation/d/1DGHBGW6O4iYRlLzIqaT55k30MH3qb22ulKcxEpGelhw/copy',
                },
                {
                    text: 'Conclusion Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1RW53DJrJ7JnP-LUZ54gViWgIKoW4sA4AbpLL5QZDnJw/copy',
                },
                {
                    text: 'Additional RTL Practice',
                    url: 'https://docs.google.com/presentation/d/18UqW28yVIQHe_muUgBSjSIizlBC5rqzvYUxQf_gEBzk/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Transitions Practice',
                    url: 'https://docs.google.com/presentation/d/1JpzdH75K_CR_LxqgJnOelwYzGv7wVwv-AYRHGWlUheI/copy',
                },
                {
                    text: 'Student Thesaurus',
                    url: 'https://docs.google.com/presentation/d/1FZ9uybc242FaYULynOO-GHkKc9cB-C7P7s6uaOqyYPk/copy',
                },
                {
                    text: 'MLA Formatting Handout',
                    url: 'https://docs.google.com/presentation/d/1eugefIQuVdZqIWY6oi7n-0Vaxsgd8Uy13FIuB2m9nLU/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1fvDOc1lqN89XzE2G-7pE3Wcp_j17CDngFC8MOENXqOc/copy',
                },
                {
                    text: 'Socratic Seminar Checklist + Rubric',
                    url: 'https://docs.google.com/presentation/d/1IOvNFx9VEAAr6rHLpUgihGz8CsnOTIyMCr_9oucQZkM/copy',
                },
            ],
        },
        {
            value: 'bonusContent',
            heading: 'Bonus Content',
            content: [
                {
                    text: 'Student Reflection',
                    url: 'https://docs.google.com/presentation/d/1Ru_sbPZScHrSpnHnUtRbf0vG3oK5BlChoIO2qW5b8nA/copy',
                },
                {
                    text: 'Response to Literature Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1-rF5yMci34ZuOq7QWrtodP-6ypwHoLHP4fhGshJnzV4/copy',
                },
                {
                    text: 'Response to Literature Rubric',
                    url: 'https://docs.google.com/presentation/d/1L5SgBhKXl6-IrWbAhpoOqdSZ6dC-7DhUHEkdkpxkHSc/copy',
                },
                {
                    text: 'Response to Informational Text Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1bkmoZnieWCsT4roalWm-xhRlCg9hwexgKLaHG4T4bc4/copy',
                },
                {
                    text: 'Compare & Contrast Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1A6zZYoG0WuYpv91Go1R2IP9VuLfA968izEeS9-o6ImI/copy',
                },
                {
                    text: 'Multiple Texts Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1VM47ttSasIOjzuK26NXk_1l-PzjuiQtAH32s0MmV62E/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'overviewEbwApproach',
            heading: 'The EBW Approach',
            content: [
                {
                    text: 'The EBW Approach',
                    url: 'https://drive.google.com/file/d/1CShd4whqE5FiFrjQ_ktCJDyR354-owEb/view?usp=sharing',
                },
            ],
        },
        {
            value: 'overviewIntroductoryParagraph',
            heading: 'The Introductory Paragraph',
            content: [
                {
                    text: 'The Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1BdNOezfpKu1XdUl49EOMsLR2fBHcVzJq/view?usp=drive_link',
                },
                {
                    text: 'TAG',
                    url: 'https://drive.google.com/file/d/1m1LFod0Xk82vZm31RdZ_4Q5OTiLiLC24/view?usp=drive_link',
                },
                {
                    text: 'Combining TAG + Summary',
                    url: 'https://drive.google.com/file/d/1k6dMEr72IscDV7bc_VoxcdpeSu7aGHSx/view?usp=drive_link',
                },
                {
                    text: 'Claim',
                    url: 'https://drive.google.com/file/d/1ZqH4Fz_hnASHjSkNUedYWf5rLs5WIfFh/view?usp=drive_link',
                },
                {
                    text: 'Putting It All Together',
                    url: 'https://drive.google.com/file/d/118NtaxpqsKkNWzb2jqx8RHmyyqEgkVoa/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'overviewBodyParagraph',
            heading: 'The Body Paragraphs',
            content: [
                {
                    text: 'The Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1JrKs7xZ6K3q9kXaGuRiWpnC4dI8PhMl2/view?usp=drive_link',
                },
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/1vgwUQQCCd9hX5cUPP_1wUJqmqqEjzePx/view?usp=drive_link',
                },
                {
                    text: 'Intro to Evidence & Evidence',
                    url: 'https://drive.google.com/file/d/1v5g4e2Xqd-KV7gJyK3iSCesLDX4Z1ygM/view?usp=drive_link',
                },
                {
                    text: 'Justification',
                    url: 'https://drive.google.com/file/d/18ryScTxh5UQJ-m4YeELk6XX52eSa796e/view?usp=drive_link',
                },
                {
                    text: 'Putting it All Together',
                    url: 'https://drive.google.com/file/d/1UWwnv6arAnsCFQWRjwuZqJPAN7pVKls2/view?usp=drive_link',
                },
                {
                    text: 'MLA Format',
                    url: 'https://drive.google.com/file/d/13hF-B3jgYJornOxSZzL1SyqrMOYwB_w3/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'overviewConclusionParagraph',
            heading: 'The Conclusion Paragraph',
            content: [
                {
                    text: 'The Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1bDfWUnIBNNd1KPIxloi5E4519vMrnKKN/view?usp=drive_link',
                },
                {
                    text: 'Restating the Claim',
                    url: 'https://drive.google.com/file/d/1eEgkB9h4j7LQFLZAghQMwinINUIzbjPg/view?usp=drive_link',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://drive.google.com/file/d/1io1CvM4kBDyrma_B4428vsmUrCSaqera/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1N_-jPOar2e474pv4-f9sTzg-q4yudVQk/view?usp=sharing',
                },
                {
                    text: 'Putting it All Together',
                    url: 'https://drive.google.com/file/d/10XyV4F7ZJRBSf2O9-jsEjTI8Je0oDXHh/view?usp=drive_link',
                },
            ],
        },
    ],
};
