import { Route } from '@angular/router';
import { WRITING_PAGE_LINKS, WRITING_PAGE_MEDIA_ID } from '../dashboards.constants';
import {
    EbwContentAccessors,
    ExpositoryContentAccessors,
    NarrativeContentAccessors,
    PersuasiveContentAccessors,
} from '../interfaces/programs.interfaces';
import { EBW_5TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/the-ebw-approach/ebw-5th-grade.constants';
import { NARRATIVE_5TH_GRADE_DOWNLOADS_CONFIG } from '../writing-program/narrative-writing/narrative-5th-grade-downloads.constants';
import { NARRATIVE_6TH_GRADE_DOWNLOADS_CONFIG } from '../writing-program/narrative-writing/narrative-6th-grade-downloads.constants';
import { NARRATIVE_7TH_GRADE_DOWNLOADS_CONFIG } from '../writing-program/narrative-writing/narrative-7th-grade-downloads.constants';
import { NARRATIVE_8TH_GRADE_DOWNLOADS_CONFIG } from '../writing-program/narrative-writing/narrative-8th-grade-downloads.constants';
import { EBW_6TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/the-ebw-approach/ebw-6th-grade.constants';
import { EBW_7TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/the-ebw-approach/ebw-7th-grade.constants';
import { EBW_8TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/the-ebw-approach/ebw-8th-grade.constants';
import { EXPOSITORY_5TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/expository-writing/expository-5th-grade-downloads.constants';
import { EXPOSITORY_6TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/expository-writing/expository-6th-grade-downloads.constants';
import { EXPOSITORY_7TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/expository-writing/expository-7th-grade-downloads.constants';
import { EXPOSITORY_8TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/expository-writing/expository-8th-grade-downloads.constants';
import { PERSUASIVE_AND_ARGUMENTATIVE_5TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/persuasive-and-argumentative-writing/persuasive-and-argumentative-5th-grade-downloads.constants';
import { PERSUASIVE_AND_ARGUMENTATIVE_6TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/persuasive-and-argumentative-writing/persuasive-and-argumentative-6th-grade-downloads.constants';
import { PERSUASIVE_AND_ARGUMENTATIVE_7TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/persuasive-and-argumentative-writing/persuasive-and-argumentative-7th-grade-downloads.constants';
import { PERSUASIVE_AND_ARGUMENTATIVE_8TH_GRADE_DOWNLOAD_CONFIG } from '../writing-program/persuasive-and-argumentative-writing/persuasive-and-argumentative-8th-grade-downloads.constants';
import { SCAFFOLDED_VERSION_CONTENT } from '../writing-program/the-ebw-approach/scaffolded-version.constants';

export const WRITING_PROGRAM_ROUTES: Route[] = [
    {
        path: 'writing-program',
        title: 'The EB Writing Program',
        loadComponent: () => import('../dashboard-template.page'),
        data: {
            mediaId: WRITING_PAGE_MEDIA_ID,
            links: WRITING_PAGE_LINKS,
        },
    },
    {
        path: 'writing-program/the-ebw-approach',
        title: 'The EBW Approach Modules',
        loadComponent: () =>
            import(
                '../writing-program/the-ebw-approach/the-ebw-approach-page/the-ebw-approach.page'
            ),
        data: {
            backUrl: '../',
            forwardUrl: 'module-0',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/module-0',
        title: 'The EBW Approach | Module 0',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: EbwContentAccessors.module0Videos,
            backUrl: '../',
            forwardUrl: '../module-1',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/module-1',
        title: 'The EBW Approach | Module 1',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: EbwContentAccessors.module1Videos,
            header: 'Module 1 | The EBW Approach',
            backUrl: '../module-0',
            forwardUrl: '../module-2',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/module-2',
        title: 'The EBW Approach | Module 2',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: EbwContentAccessors.module2Videos,
            header: 'Module 2 | The Introductory Paragraph',
            backUrl: '../module-1',
            forwardUrl: '../module-3',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/module-3',
        title: 'The EBW Approach | Module 3',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: EbwContentAccessors.module3Videos,
            header: 'Module 3 | The Body Paragraphs',
            backUrl: '../module-2',
            forwardUrl: '../module-4',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/module-4',
        title: 'The EBW Approach | Module 4',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: EbwContentAccessors.module4Videos,
            header: 'Module 4 | The Conclusion',
            backUrl: '../module-3',
            forwardUrl: '../module-5',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/module-5',
        title: 'The EBW Approach | Module 5',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: EbwContentAccessors.module5Videos,
            header: 'Module 5 | Mini-Lessons',
            backUrl: '../module-4',
            forwardUrl: '../bonus-content',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/bonus-content',
        title: 'The EBW Approach | Bonus Content',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: EbwContentAccessors.bonusContentLessons,
            header: 'Bonus Content + Lessons',
            backUrl: '../module-5',
            forwardUrl: '../subsequent-years',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/subsequent-years',
        title: 'The EBW Approach | Subsequent Years',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: EbwContentAccessors.subsequentYearsVideos,
            header: 'Subsequent Years of the EBW Approach',
            backUrl: '../bonus-content',
            forwardUrl: '../scaffolded-version-downloads',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/scaffolded-version-downloads',
        title: 'The EBW Approach | Scaffolded Version Downloads',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: SCAFFOLDED_VERSION_CONTENT,
            backUrl: '../subsequent-years',
            forwardUrl: '../downloads-level-d',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/downloads-level-d',
        title: 'The EBW Approach | Downloads Level D',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: EBW_5TH_GRADE_DOWNLOAD_CONFIG,
            header: 'EBW Approach | Downloads Level D',
            backUrl: '../scaffolded-version-downloads',
            forwardUrl: '../downloads-level-e',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/downloads-level-e',
        title: 'The EBW Approach | Downloads Level E',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: EBW_6TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-d',
            forwardUrl: '../downloads-level-f',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/downloads-level-f',
        title: 'The EBW Approach | Downloads Level F',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: EBW_7TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-e',
            forwardUrl: '../downloads-level-g',
        },
    },
    {
        path: 'writing-program/the-ebw-approach/downloads-level-g',
        title: 'The EBW Approach | Downloads Level G',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: EBW_8TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-f',
            forwardUrl: '/writing-program/narrative-writing',
        },
    },
    {
        path: 'writing-program/narrative-writing',
        title: 'Narrative Writing Modules',
        loadComponent: () =>
            import(
                '../writing-program/narrative-writing/narrative-writing-page/narrative-writing.page'
            ),
        data: {
            backUrl: '../the-ebw-approach/downloads-level-g',
            forwardUrl: 'teacher-guide',
        },
    },
    {
        path: 'writing-program/narrative-writing/teacher-guide',
        title: 'Teacher Guide | Narrative Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: NarrativeContentAccessors.narrativeTeacherGuideVideos,
            backUrl: '../',
            forwardUrl: '../mini-lessons-the-writing-process-and-your-publishing-party',
        },
    },
    {
        path: 'writing-program/narrative-writing/mini-lessons-the-writing-process-and-your-publishing-party',
        title: 'Mini Lessons, The Writing Process, and Your Publishing Party | Narrative Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: NarrativeContentAccessors.narrativeMiniLessonVideos,
            header: 'Mini Lessons, The Writing Process, and Your Publishing Party ',
            backUrl: '../teacher-guide',
            forwardUrl: '../downloads-level-d',
        },
    },
    {
        path: 'writing-program/narrative-writing/downloads-level-d',
        title: 'Downloads Level D | Narrative Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: NARRATIVE_5TH_GRADE_DOWNLOADS_CONFIG,
            header: 'Downloads',
            backUrl: '../mini-lessons-the-writing-process-and-your-publishing-party',
            forwardUrl: '../downloads-level-e',
        },
    },
    {
        path: 'writing-program/narrative-writing/downloads-level-e',
        title: 'Downloads Level E | Narrative Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: NARRATIVE_6TH_GRADE_DOWNLOADS_CONFIG,
            header: 'Downloads',
            backUrl: '../downloads-level-d',
            forwardUrl: '../downloads-level-f',
        },
    },
    {
        path: 'writing-program/narrative-writing/downloads-level-f',
        title: 'Downloads Level F | Narrative Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: NARRATIVE_7TH_GRADE_DOWNLOADS_CONFIG,
            header: 'Downloads',
            backUrl: '../downloads-level-e',
            forwardUrl: '../downloads-level-g',
        },
    },
    {
        path: 'writing-program/narrative-writing/downloads-level-g',
        title: 'Downloads Level G | Narrative Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: NARRATIVE_8TH_GRADE_DOWNLOADS_CONFIG,
            header: 'Downloads',
            backUrl: '../downloads-level-f',
            forwardUrl: '../../expository-writing',
        },
    },
    {
        path: 'writing-program/expository-writing',
        title: 'Expository Writing Modules',
        loadComponent: () =>
            import(
                '../writing-program/expository-writing/expository-writing-page/expository-writing.page'
            ),
        data: {
            backUrl: '../narrative-writing/downloads-level-g',
            forwardUrl: 'teacher-guide',
        },
    },
    {
        path: 'writing-program/expository-writing/teacher-guide',
        title: 'Teacher Guide | Expository Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: ExpositoryContentAccessors.expositoryTeacherGuideVideos,
            backUrl: '../',
            forwardUrl: '../problem-solution-writing',
        },
    },
    {
        path: 'writing-program/expository-writing/problem-solution-writing',
        title: 'Problem Solution | Expository Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: ExpositoryContentAccessors.expositoryProblemSolutionVideos,
            backUrl: '../teacher-guide',
            forwardUrl: '../compare-and-contrast-writing',
        },
    },
    {
        path: 'writing-program/expository-writing/compare-and-contrast-writing',
        title: 'Compare and Contrast | Expository Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: ExpositoryContentAccessors.expositoryCompareAndContrastVideos,
            backUrl: '../problem-solution-writing',
            forwardUrl: '../cause-and-effect-writing',
        },
    },
    {
        path: 'writing-program/expository-writing/cause-and-effect-writing',
        title: 'Cause and Effect | Expository Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: ExpositoryContentAccessors.expositoryCauseAndEffectVideos,
            backUrl: '../compare-and-contrast-writing',
            forwardUrl: '../descriptive-writing',
        },
    },
    {
        path: 'writing-program/expository-writing/descriptive-writing',
        title: 'Descriptive Writing | Expository Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: ExpositoryContentAccessors.expositoryDescriptiveVideos,
            backUrl: '../cause-and-effect-writing',
            forwardUrl: '../downloads-level-d',
        },
    },
    {
        path: 'writing-program/expository-writing/downloads-level-d',
        title: 'Downloads Level D | Expository Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: EXPOSITORY_5TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../descriptive-writing',
            forwardUrl: '../downloads-level-e',
        },
    },
    {
        path: 'writing-program/expository-writing/downloads-level-e',
        title: 'Downloads Level E | Expository Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: EXPOSITORY_6TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-d',
            forwardUrl: '../downloads-level-f',
        },
    },
    {
        path: 'writing-program/expository-writing/downloads-level-f',
        title: 'Downloads Level F | Expository Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: EXPOSITORY_7TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-e',
            forwardUrl: '../downloads-level-g',
        },
    },
    {
        path: 'writing-program/expository-writing/downloads-level-g',
        title: 'Downloads Level G | Expository Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: EXPOSITORY_8TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-f',
            forwardUrl: '../../persuasive-and-argumentative-writing',
        },
    },
    {
        path: 'writing-program/persuasive-and-argumentative-writing',
        title: 'Persuasive and Argumentative Writing Modules',
        loadComponent: () =>
            import(
                '../writing-program/persuasive-and-argumentative-writing/persuasive-and-argumentative-writing-page/persuasive-and-argumentative-writing.page'
            ),
        data: {
            backUrl: '../expository-writing/downloads-level-g',
            forwardUrl: 'teacher-guide',
        },
    },
    {
        path: 'writing-program/persuasive-and-argumentative-writing/teacher-guide',
        title: 'Teacher Guide | Persuasive and Argumentative Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: PersuasiveContentAccessors.teacherGuideVideos,
            backUrl: '../',
            forwardUrl: '../persuasive-writing',
        },
    },
    {
        path: 'writing-program/persuasive-and-argumentative-writing/persuasive-writing',
        title: 'Persuasive Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: PersuasiveContentAccessors.persuasiveVideos,
            backUrl: '../teacher-guide',
            forwardUrl: '../argumentative-writing',
        },
    },
    {
        path: 'writing-program/persuasive-and-argumentative-writing/argumentative-writing',
        title: 'Argumentative Writing',
        loadComponent: () => import('../video-list.page'),
        data: {
            contentAccessor: PersuasiveContentAccessors.argumentativeVideos,
            backUrl: '../persuasive-writing',
            forwardUrl: '../downloads-level-d',
        },
    },
    {
        path: 'writing-program/persuasive-and-argumentative-writing/downloads-level-d',
        title: 'Downloads Level D | Persuasive and Argumentative Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: PERSUASIVE_AND_ARGUMENTATIVE_5TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../argumentative-writing',
            forwardUrl: '../downloads-level-e',
        },
    },
    {
        path: 'writing-program/persuasive-and-argumentative-writing/downloads-level-e',
        title: 'Downloads Level E | Persuasive and Argumentative Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: PERSUASIVE_AND_ARGUMENTATIVE_6TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-d',
            forwardUrl: '../downloads-level-f',
        },
    },
    {
        path: 'writing-program/persuasive-and-argumentative-writing/downloads-level-f',
        title: 'Downloads Level F | Persuasive and Argumentative Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: PERSUASIVE_AND_ARGUMENTATIVE_7TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-e',
            forwardUrl: '../downloads-level-g',
        },
    },
    {
        path: 'writing-program/persuasive-and-argumentative-writing/downloads-level-g',
        title: 'Downloads Level G | Persuasive and Argumentative Writing',
        loadComponent: () => import('../downloads-list.page'),
        data: {
            config: PERSUASIVE_AND_ARGUMENTATIVE_8TH_GRADE_DOWNLOAD_CONFIG,
            backUrl: '../downloads-level-f',
        },
    },
];
