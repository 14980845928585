import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AccountsService } from './accounts.service';
import { firstValueFrom } from 'rxjs';
import { Account, SchoolAccountRole, hasTeachingInfo } from '@lib/shared-interface-account';

/**
 * Creates a guard that redirects to the specified URL if the user is not logged in.
 */
export function createIsLoggedInAndActiveGuard(redirectUrl: string): CanActivateFn {
    return async (route, state) => {
        const router = inject(Router);
        const accountsService = inject(AccountsService);

        const account = await firstValueFrom(accountsService.userAccount$);
        const { active } = account ?? {};
        const result = active || redirectUrl;

        if (typeof result !== 'string') return result;

        // Capture the current URL for redirection after access is resolved.
        const urlTree = router.parseUrl(result);
        urlTree.queryParams = { ...urlTree.queryParams, redirect: state.url };
        return urlTree;
    };
}

/**
 * Creates a guard that redirects to the specified URL if the user is already logged in.
 */
export function createNotLoggedInOrActiveGuard(redirectUrl: string): CanActivateFn {
    return async (route) => {
        const router = inject(Router);
        const accountsService = inject(AccountsService);

        const desiredRedirectUrl = route.queryParamMap.get('redirect');
        const account = await firstValueFrom(accountsService.userAccount$);
        const { active } = account ?? {};

        // Use the previously captured redirect URL if it exists.
        const result = !active || (desiredRedirectUrl ?? redirectUrl);
        if (typeof result !== 'string') return result;

        return router.parseUrl(result);
    };
}

export function createHasCompletedOnboardingGuard(redirectUrl: string): CanActivateFn {
    return async () => {
        const router = inject(Router);
        const accountsService = inject(AccountsService);

        const account = await firstValueFrom(accountsService.userAccount$);
        const result = hasCompletedOnboarding(account) || redirectUrl;
        if (typeof result !== 'string') return result;

        // Todo: Capture a redirect URL to potentially use after they complete onboarding.
        return router.parseUrl(result);
    };
}

function hasCompletedOnboarding(account?: Account): boolean {
    const { agreedToTermsAndConditionsAt, acknowledgedPrivacyPolicyAt, additionalSchoolUserData } =
        account ?? {};
    const { roles, watchedWelcomeVideo, registrationSurveyCompleted } =
        additionalSchoolUserData ?? {};

    const hasAcceptedLegalDocuments =
        typeof agreedToTermsAndConditionsAt === 'number' &&
        typeof acknowledgedPrivacyPolicyAt === 'number';
    if (!hasAcceptedLegalDocuments) return false;

    const hasWatchedWelcomeVideo = Boolean(watchedWelcomeVideo);
    if (!hasWatchedWelcomeVideo) return false;

    const hasAdditionalTeachingInfo = additionalSchoolUserData
        ? hasTeachingInfo(additionalSchoolUserData)
        : false;
    if (!hasAdditionalTeachingInfo) return false;

    const isTeacher = roles?.includes(SchoolAccountRole.TEACHER);
    if (!isTeacher) return true;

    return Boolean(registrationSurveyCompleted);
}
