import { createStringEnum, EnumValues } from '@lib/shared-interface-utility-types';

export const SchoolUserType = createStringEnum([
    'DEPENDENT_AFFILIATED',
    'INDEPENDENT_AFFILIATED',
    'INDEPENDENT_AFFILIATED_CUSTOM',
    'INDEPENDENT_NON_AFFILIATED',
]);
export type SchoolUserType = EnumValues<typeof SchoolUserType>;

export const SCHOOL_USER_TYPES = Object.values(SchoolUserType);
