import { map, OperatorFunction } from 'rxjs';

// Todo: I believe this could be useful in the near future.
//  However, if it remains unused long-term, we can remove it.
export function mapArray<Value, Result>(
    transform: (value: Value) => Result,
): OperatorFunction<Value[], Result[]> {
    const transformArray = (values: Value[]) => values.map(transform);
    return map(transformArray);
}
