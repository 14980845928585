import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CommonMissingTranslationHandler implements MissingTranslationHandler {
    private dotSyntaxStringMatcher = /\w+\.\w+/;
    public handle(parameters: MissingTranslationHandlerParams): string {
        if (
            this.dotSyntaxStringMatcher.test(parameters.key) &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            parameters.translateService.translations?.en?.[parameters.key] == undefined
        ) {
            console.error(`Lack of translation for "${parameters.key}"`);
        }
        return parameters.key;
    }
}
