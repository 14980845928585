import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const NARRATIVE_5TH_GRADE_DOWNLOADS_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/2c32208c-879b-40e6-bf11-810ea12fb1e4',
    headingConfig: {
        letter: 'D',
        grade: 'Grade 5',
        level: 'Level D',
        color: 'var(--program-grade-level-d)',
    },
    slideDeckHeading: 'Narrative Writing Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Narrative_Writing_Student_Slide_Decks_ (2).zip',
    digitFilesConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing?',
            content: [
                {
                    text: 'What is Narrative Writing?',
                    url: 'https://docs.google.com/presentation/d/1mgofxCwNFBI7RUe2wiaOlGTxORvC9L9OhbWh9Rqw0YM/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Brainstorming',
                    url: 'https://docs.google.com/presentation/d/16JQqWPMKwcNo5Kb-kIZrUFSBRIHxaOnYeunSNsbjTtU/copy',
                },
                {
                    text: 'Narrative Writing Quilt',
                    url: 'https://docs.google.com/presentation/d/1lCNgDiC5Fus-8A3bOoKC5Ixsjd64mB6Tsud6QMTEamk/copy',
                },
                {
                    text: 'Understanding POV',
                    url: 'https://docs.google.com/presentation/d/1jZ2ICGeh6Zbp70VC_VqlYD5BjWlXlEt8MrxbyLkTo9U/copy',
                },
                {
                    text: 'Writing Leads',
                    url: 'https://docs.google.com/presentation/d/1M-0Pz_lVz22n7OyeMD-_gb1w_Q-10dcbwoZMAbAUjFA/copy',
                },
                {
                    text: 'Creating Endings',
                    url: 'https://docs.google.com/presentation/d/18o0wanwxi7KCUftNBpQHQyAQF4HltRjxHqMdPQuhrCA/copy',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://docs.google.com/presentation/d/1pleDPZ9Q43EBOFduEohRSxSZnKihA4_8nD3i5tAHSw4/copy',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://docs.google.com/presentation/d/1BI11JWKbWRSz_HW1K1pNhYMWusFlzhhGQrlEEGa71XI/copy',
                },
                {
                    text: 'Dialogue',
                    url: 'https://docs.google.com/presentation/d/1DVErevRbv9NkWMbK45r_dqu8hUYISEBUo6qF5OO4TBw/copy',
                },
                {
                    text: 'Transition Words',
                    url: 'https://docs.google.com/presentation/d/1hMSGKOZAFsnueQaGIJ3Pc9t4eru3xsejCQyp8KrWuaE/copy',
                },
                {
                    text: 'Story Arc',
                    url: 'https://docs.google.com/presentation/d/1CYXYcOMdEjs1SVLv9Yg7GRIjz0T-7ZgqrirvOkLBG2Q/copy',
                },
            ],
        },
        {
            value: 'theWritingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'All About Figurative Language',
                    url: 'https://docs.google.com/presentation/d/1F9jNc8dSnV1jYTV2BG0_520uR2KpXO9yu4px-5kzxI8/copy',
                },
                {
                    text: 'Narrative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1jViL668x5T6obJ7q9gYU9MAsxIquP_rdulCN3EWdtGY/copy',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://docs.google.com/presentation/d/1IAYVPO8ExmruXB1yjOnZJRQL-D91Cz59hJWyW0IEq30/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing',
            content: [
                {
                    text: 'What is Narrative Writing',
                    url: 'https://drive.google.com/file/d/1lOrU3vpjelo317n5pw2_OlKdpBiEz1wj/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'The Mini Lessons',
            content: [
                {
                    text: 'Brainstorming for Your Narrative',
                    url: 'https://drive.google.com/file/d/1ilNBv-laOJ4R8kJ8cnPM_KZ-8R4q7IFc/view?usp=drive_link',
                },
                {
                    text: 'The Story Arc',
                    url: 'https://drive.google.com/file/d/1pnWRsguB2gMtVjFljFMfe8G19EI_n99E/view?usp=drive_link',
                },
                {
                    text: 'Point of View',
                    url: 'https://drive.google.com/file/d/11OccwLGgbcpUX7s531Mq2dm50ChUGc7G/view?usp=drive_link',
                },
                {
                    text: 'Leads in Narratives',
                    url: 'https://drive.google.com/file/d/1rqS4KFe7hFv_W3_j_QeBGe-W6JcPtZWo/view?usp=drive_link',
                },
                {
                    text: 'Endings in Narratives',
                    url: 'https://drive.google.com/file/d/1PGeXrji1pTLRZH1WZ1ey3UvxQAMAlLAv/view?usp=drive_link',
                },
                {
                    text: 'Dialogue',
                    url: 'https://drive.google.com/file/d/1w6XVXi4eT3JOESCyvvGTpXgZpESS36kg/view?usp=drive_link',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://drive.google.com/file/d/1vE2hlhikbcuUZJWNOH83ZIdFLKkGrr8d/view?usp=drive_link',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://drive.google.com/file/d/1LIfws3_bLIrXHAF7yK3sYJTZgiA9N68c/view?usp=drive_link',
                },
                {
                    text: 'Transition Words',
                    url: 'https://drive.google.com/file/d/1ptRjtr_kWZETOAmY5IgFIGygudK4y0-X/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'writingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'Your Rough Draft',
                    url: 'https://drive.google.com/file/d/10zZVBvPoxotG7Jj7sfOmO0TbUp5EM3sd/view?usp=drive_link',
                },
                {
                    text: 'Figurative Language',
                    url: 'https://drive.google.com/file/d/10xghJ0qjNVU2UqXZBWfrHdr2d-MQXcM3/view?usp=drive_link',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://drive.google.com/file/d/14Wj-BH62H-gRdkd-8pFKSRBPzn7aiqIw/view?usp=drive_link',
                },
                {
                    text: 'Peer Editing',
                    url: 'https://drive.google.com/file/d/13biOsEzI3p0Ms_L04ktZoE487_Ap15YX/view?usp=drive_link',
                },
                {
                    text: 'Revising Your Narrative',
                    url: 'https://drive.google.com/file/d/18ky6bTnWoC_Y5iTd5fdhssI1Fih2COO9/view?usp=sharing',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Certificate_of_Completion_Narrative_Writing (2).pdf',
};
