import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { ToastService } from '@lib/frontend-shared-util-toast-service';
import { WINDOW_TOKEN } from '@lib/frontend-shared-util-miscellaneous-providers';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class AppUpdateService implements OnDestroy {
    private subscription?: Subscription;
    private updateCheckTimeout?: ReturnType<typeof setTimeout>;

    public constructor(
        private readonly swUpdate: SwUpdate,
        private readonly platform: Platform,
        private readonly toastService: ToastService,
        @Inject(WINDOW_TOKEN) private readonly window: Window,
    ) {}

    public initialize(durations: AppUpdateDurations) {
        this.subscription = this.swUpdate.versionUpdates.subscribe((event) => {
            if (event.type === 'NO_NEW_VERSION_DETECTED') {
                this.setVersionCheckTimeout(durations.checkForUpdate);
                return;
            }
            if (event.type === 'VERSION_READY') {
                void this.presentRestartPrompt(durations);
                return;
            }
            if (event.type === 'VERSION_INSTALLATION_FAILED') {
                console.error(
                    'An error occurred while installing the new application version.',
                    event.error,
                );
                return;
            }
        });
    }

    public ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    private setVersionCheckTimeout(minutes: number) {
        if (this.updateCheckTimeout) {
            clearTimeout(this.updateCheckTimeout);
        }
        const timeoutMilliseconds = 1000 * 60 * minutes;
        this.updateCheckTimeout = setTimeout(
            () => void this.swUpdate.checkForUpdate(),
            timeoutMilliseconds,
        );
    }

    private presentRestartPrompt(durations: AppUpdateDurations) {
        // Below this screen size, stacked without an icon looks better.
        // If we had better access to shadow DOM,
        // we could change the flex-direction of the button container instead.
        const isSmallScreen = this.platform.width() < 615;

        void this.toastService.presentWarning({
            message: `A new version of the app is ready to install. Refresh the page now or be reminded in ${durations.restartReminder} minutes?`,
            // It's unlikely they will leave this toast open for an entire hour.
            // However, this is generally frowned upon in the Ionic documentation.
            duration: 1000 * 60 * durations.newVersionWarning,
            icon: isSmallScreen ? undefined : 'warning',
            layout: isSmallScreen ? 'stacked' : 'baseline',
            buttons: [
                {
                    role: 'refresh',
                    text: 'Refresh Now',
                    handler: () => this.window.location.reload(),
                },
                {
                    role: 'cancel',
                    text: `Remind Later`,
                    handler: () => this.setVersionCheckTimeout(durations.restartReminder),
                },
            ],
        });
    }
}

// These are all in minutes.
export type AppUpdateDurations = {
    checkForUpdate: number;
    restartReminder: number;
    newVersionWarning: number;
};
