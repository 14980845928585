import { Route } from '@angular/router';
import { NavigationComponent } from '@lib/frontend-internal-navigation-feature-navigation';
import {
    createIsLoggedInAndActiveGuard,
    createNotLoggedInOrActiveGuard,
} from '@lib/frontend-shared-accounts-data-accounts-service';
import { PROGRAM_CONTENT_PAGE_ROUTES } from '@lib/frontend-shared-feature-programs-content';

const isLoggedIn = createIsLoggedInAndActiveGuard('/account/login');
const notLoggedIn = createNotLoggedInOrActiveGuard('/dashboard');

export const APP_ROUTES: Route[] = [
    {
        path: '',
        component: NavigationComponent,
        canActivate: [isLoggedIn],
        children: [
            {
                path: 'account/profile',
                title: 'Edit Profile',
                loadComponent: () =>
                    import('@lib/frontend-shared-accounts-feature-edit-profile-page'),
            },
            {
                path: 'assessments',
                title: 'Assessments',
                loadComponent: () =>
                    import('@lib/frontend-internal-assessments-feature-assessment-list'),
            },
            {
                path: 'assessments/:assessmentId',
                title: 'Assessment Details',
                loadComponent: () =>
                    import('@lib/frontend-internal-assessments-feature-assessment-details'),
            },
            {
                path: 'dashboard',
                title: 'Dashboard',
                loadComponent: () =>
                    import('@lib/frontend-internal-dashboard-feature-dashboard-page'),
            },
            {
                path: 'media-library',
                title: 'Media Library',
                loadComponent: () => import('@lib/frontend-shared-feature-media-library-list-page'),
                data: {
                    edit: true,
                    delete: true,
                },
            },
            {
                path: 'media-library/create',
                title: 'Add Media',
                loadComponent: () =>
                    import('@lib/frontend-shared-feature-media-library-create-edit-page'),
            },
            {
                path: 'media-library/:id/edit',
                title: 'Edit Media',
                loadComponent: () =>
                    import('@lib/frontend-shared-feature-media-library-create-edit-page'),
            },
            {
                path: 'media-library/:id',
                title: 'Media Details',
                loadComponent: () =>
                    import('@lib/frontend-shared-feature-media-library-detail-page'),
                data: {
                    edit: true,
                    delete: true,
                },
            },
            {
                path: 'resources',
                title: 'Resources',
                loadComponent: () =>
                    import('@lib/frontend-shared-resource-feature-browse-resources'),
                data: {
                    edit: true,
                },
            },
            {
                path: 'resources/create',
                title: 'Create Resource',
                loadComponent: () =>
                    import('@lib/frontend-internal-resource-feature-create-resource-page'),
            },
            {
                path: 'resources/:resourceId',
                title: 'Resource Details',
                loadComponent: () =>
                    import('@lib/frontend-shared-resource-feature-resource-details'),
                data: {
                    edit: true,
                },
            },
            {
                path: 'resources/:resourceId/edit',
                title: 'Edit Resource',
                loadComponent: () =>
                    import('@lib/frontend-internal-resource-feature-edit-resource'),
            },
            {
                path: 'school-customers',
                title: 'School Customers',
                loadComponent: () =>
                    import('@lib/frontend-internal-customers-feature-school-customer-list-page'),
            },
            {
                path: 'school-customers/create',
                title: 'Create Customer',
                loadComponent: () =>
                    import('@lib/frontend-internal-customers-feature-create-school-customer-page'),
            },
            {
                path: 'school-customers/:id',
                title: 'School Customer',
                loadComponent: () =>
                    import('@lib/frontend-internal-customers-feature-school-customer-page'),
            },
            {
                path: 'school-customers/:id/edit',
                title: 'Edit Customer',
                loadComponent: () =>
                    import('@lib/frontend-internal-customers-feature-edit-school-customer-page'),
            },
            {
                path: 'school-users',
                title: 'EBA Portal Users',
                loadComponent: () =>
                    import('@lib/frontend-internal-accounts-feature-school-user-list-page'),
            },
            {
                path: 'school-users/:id',
                title: 'EBA Portal User',
                loadComponent: () =>
                    import('@lib/frontend-internal-accounts-feature-school-user-page'),
            },
            {
                path: 'schools/import',
                title: 'Import Schools',
                loadComponent: () =>
                    import('@lib/frontend-internal-schools-feature-imported-schools-page'),
            },
            {
                path: 'settings/developer-tools',
                title: 'Developer Tools',
                loadComponent: () => import('@lib/frontend-shared-feature-developer-tools-page'),
            },
            {
                path: 'skills',
                title: 'Skills / Standards',
                loadComponent: () => import('@lib/frontend-shared-skill-feature-skills-page'),
            },
            {
                path: 'users/staff',
                title: 'EB Academics Staff',
                loadComponent: () =>
                    import('@lib/frontend-internal-accounts-feature-staff-list-page'),
            },
            ...PROGRAM_CONTENT_PAGE_ROUTES,
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'account/register',
        title: 'Account Registration',
        loadComponent: () => import('@lib/frontend-shared-authentication-feature-register-page'),
    },
    {
        path: 'account/confirm-registration',
        redirectTo: 'account/register',
        pathMatch: 'full',
    },
    {
        path: 'account/confirm-registration/:email',
        title: 'Confirm Registration',
        canActivate: [notLoggedIn],
        loadComponent: () =>
            import('@lib/frontend-shared-authentication-feature-confirm-registration-page'),
    },
    {
        path: 'account/login',
        title: 'Login',
        canActivate: [notLoggedIn],
        loadComponent: () => import('@lib/frontend-internal-authentication-feature-login-page'),
    },
    {
        path: 'account/reset-password',
        title: 'Reset Password',
        loadComponent: () =>
            import('@lib/frontend-shared-authentication-feature-reset-password-page'),
    },
    {
        path: 'account/change-password',
        redirectTo: 'account/reset-password',
        pathMatch: 'full',
    },
    {
        path: 'account/change-password/:email',
        title: 'Change Password',
        loadComponent: () =>
            import('@lib/frontend-shared-authentication-feature-change-password-page'),
    },
    {
        // Redirect unrecognized routes to the dashboard if the user is logged in,
        // otherwise redirect to the login page.
        // This can't be used in a group with the isLoggedIn guard,
        // as it will cause a redirect loop.
        path: '**',
        redirectTo: '',
    },
];
