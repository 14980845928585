/**
 * Checks if a type is unknown and returns a `true` or `false`.
 *
 * Any type can always extend unknown but unknown can only extend itself.
 * See https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-0.html#new-unknown-top-type.
 *
 * Note that using this will _not_ decrease character count.
 * _Readability/maintainability_ is more important.
 *
 * @example
 * Instead of doing `type MyType = Type1 extends unknown ? Type2 : Type3` by accident,
 * which will always result in `Type2` being selected,
 * you can instead do `type MyType = IsUnknown<Type1> extends true ? Type2 : Type3`.
 */
export type IsUnknown<Type> = unknown extends Type ? true : false;
