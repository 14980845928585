import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const NARRATIVE_6TH_GRADE_DOWNLOADS_CONFIG: DownloadsConfig = {
    resourceLink: '/resources/41a94fbb-b872-4fd7-91e6-a67d901bf7b6',
    headingConfig: {
        letter: 'E',
        grade: 'Grade 6',
        level: 'Level E',
        color: 'var(--program-grade-level-e)',
    },
    slideDeckHeading: 'Narrative Writing Slide Decks',
    slideDeckUrl:
        '/resource-assets/program-content/EB Writing Program/Level E/Narrative_Writing_Student_Slide_Decks_.zip',
    digitFilesConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing?',
            content: [
                {
                    text: 'What is Narrative Writing?',
                    url: 'https://docs.google.com/presentation/d/1NfqG-NNDcMyFu_5JWLHD6k8KR03QEp1By3UZwEEMDyo/copy',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'Mini Lessons',
            content: [
                {
                    text: 'Brainstorming',
                    url: 'https://docs.google.com/presentation/d/10325VGlv7LT404Axlyrpg4-mdXVok6Pn9yToNX56Pho/copy',
                },
                {
                    text: 'Narrative Writing Quilt',
                    url: 'https://docs.google.com/presentation/d/1FtxpTA4OwSdXVIyZZb0ck7XWxaw5TFz6C3f-GL8pBhs/copy',
                },
                {
                    text: 'Understanding POV',
                    url: 'https://docs.google.com/presentation/d/19TyXE5ix4eTUywVgf2LUltboMZD07jGiFkm2iQaEey4/copy',
                },
                {
                    text: 'Writing Leads',
                    url: 'https://docs.google.com/presentation/d/1HuUqkXiUThfwoGCZejE-RXc3vVhiTYSbYursuJars_M/copy',
                },
                {
                    text: 'Creating Endings',
                    url: 'https://docs.google.com/presentation/d/1jxjx7W6EL1nheTZqhROvLsLaQeYXFEzCbHsE1rZfo2Q/copy',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://docs.google.com/presentation/d/1nYUR7MH76-Baoct6BarYQyJbqcEZXltE9Wdm8oiVQos/copy',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://docs.google.com/presentation/d/1BEu8B_N795rPkDvuQddHLxB1vbPUCxKDxkivas_fjBY/copy',
                },
                {
                    text: 'Dialogue',
                    url: 'https://docs.google.com/presentation/d/1I78ExL20LjYoBCnX8eiZrPN5PgD556ryAD0y4cHt4Bc/copy',
                },
                {
                    text: 'Transition Words',
                    url: 'https://docs.google.com/presentation/d/1XlE6JcIZ4wKFu9cPBaF3AiVLdxj4NfaQ4BZEI7VBPjE/copy',
                },
                {
                    text: 'Story Arc',
                    url: 'https://docs.google.com/presentation/d/1RWi6ZNtkZCC7dGTwbLStNliHKoIl6uhny-F4eykTPxw/copy',
                },
            ],
        },
        {
            value: 'theWritingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'All About Figurative Language',
                    url: 'https://docs.google.com/presentation/d/1PEFnKJBkNgsw55KABWG9o6i-VStPG7IPjFjTSzwCqKY/copy',
                },
                {
                    text: 'Narrative Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1Ti8k6dhMmcES7_P5ngBMMgDvlczSeQn5wUokiHWBp-s/copy',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://docs.google.com/presentation/d/1l1HtOv6R49aJg1R91r1m-QctrWjDmrbrX5aPiKkawhU/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'whatIsNarrativeWriting',
            heading: 'What is Narrative Writing',
            content: [
                {
                    text: 'What is Narrative Writing',
                    url: 'https://drive.google.com/file/d/1lOrU3vpjelo317n5pw2_OlKdpBiEz1wj/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'miniLessons',
            heading: 'The Mini Lessons',
            content: [
                {
                    text: 'Brainstorming for Your Narrative',
                    url: 'https://drive.google.com/file/d/1ilNBv-laOJ4R8kJ8cnPM_KZ-8R4q7IFc/view?usp=drive_link',
                },
                {
                    text: 'The Story Arc',
                    url: 'https://drive.google.com/file/d/1pnWRsguB2gMtVjFljFMfe8G19EI_n99E/view?usp=drive_link',
                },
                {
                    text: 'Point of View',
                    url: 'https://drive.google.com/file/d/11OccwLGgbcpUX7s531Mq2dm50ChUGc7G/view?usp=drive_link',
                },
                {
                    text: 'Leads in Narratives',
                    url: 'https://drive.google.com/file/d/1rqS4KFe7hFv_W3_j_QeBGe-W6JcPtZWo/view?usp=drive_link',
                },
                {
                    text: 'Endings in Narratives',
                    url: 'https://drive.google.com/file/d/1PGeXrji1pTLRZH1WZ1ey3UvxQAMAlLAv/view?usp=drive_link',
                },
                {
                    text: 'Dialogue',
                    url: 'https://drive.google.com/file/d/1w6XVXi4eT3JOESCyvvGTpXgZpESS36kg/view?usp=drive_link',
                },
                {
                    text: 'Crafting Stronger Sentences',
                    url: 'https://drive.google.com/file/d/1vE2hlhikbcuUZJWNOH83ZIdFLKkGrr8d/view?usp=drive_link',
                },
                {
                    text: 'Expanding Sentences',
                    url: 'https://drive.google.com/file/d/1LIfws3_bLIrXHAF7yK3sYJTZgiA9N68c/view?usp=drive_link',
                },
                {
                    text: 'Transition Words',
                    url: 'https://drive.google.com/file/d/1ptRjtr_kWZETOAmY5IgFIGygudK4y0-X/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'writingProcess',
            heading: 'The Writing Process',
            content: [
                {
                    text: 'Your Rough Draft',
                    url: 'https://drive.google.com/file/d/10zZVBvPoxotG7Jj7sfOmO0TbUp5EM3sd/view?usp=drive_link',
                },
                {
                    text: 'Figurative Language',
                    url: 'https://drive.google.com/file/d/10xghJ0qjNVU2UqXZBWfrHdr2d-MQXcM3/view?usp=drive_link',
                },
                {
                    text: 'Student Checklist',
                    url: 'https://drive.google.com/file/d/14Wj-BH62H-gRdkd-8pFKSRBPzn7aiqIw/view?usp=drive_link',
                },
                {
                    text: 'Peer Editing',
                    url: 'https://drive.google.com/file/d/13biOsEzI3p0Ms_L04ktZoE487_Ap15YX/view?usp=drive_link',
                },
                {
                    text: 'Revising Your Narrative',
                    url: 'https://drive.google.com/file/d/18ky6bTnWoC_Y5iTd5fdhssI1Fih2COO9/view?usp=sharing',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level E/Certificate_of_Completion_Narrative_Writing.pdf',
};
