import { ApolloError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';

export function extractApolloErrorCodes(error: Error): string[] {
    const isApolloError = error instanceof ApolloError;
    if (!isApolloError) return [];

    const getGqlErrorCode = (gqlError: GraphQLError) => gqlError.extensions.code;
    return error.graphQLErrors.map(getGqlErrorCode);
}

// Helps avoid typescript assertions above.
declare module 'graphql' {
    export interface GraphQLErrorExtensions {
        code: string;
    }
}
