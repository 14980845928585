import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AccountsService } from '@lib/frontend-shared-accounts-data-accounts-service';
import { AuthenticationService } from '@lib/frontend-shared-accounts-data-authentication-service';
import { mapIfDefined } from '@lib/shared-util-rxjs';
import { BrandedNavigationComponent } from '@lib/frontend-shared-feature-branded-navigation';

@Component({
    standalone: true,
    selector: 'lib-internal-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AsyncPipe, BrandedNavigationComponent, IonicModule, RouterModule, TranslateModule],
})
export class NavigationComponent {
    protected readonly account$ = this.accountsService.userAccount$;
    protected readonly isLoggedIn$: Observable<boolean> = this.account$.pipe(map(Boolean));
    protected readonly accountTitle$: Observable<string | undefined> = this.account$.pipe(
        map((account) => account?.fullName ?? account?.email),
    );
    protected readonly accountAvatar$: Observable<Image | undefined> = this.account$.pipe(
        mapIfDefined(({ fullName, email, avatar }) => {
            if (!avatar) return;

            const alternateText = fullName ?? email;
            return { source: avatar, alternateText };
        }),
    );

    public constructor(
        private readonly accountsService: AccountsService,
        private readonly authenticationService: AuthenticationService,
        private readonly navigationController: NavController,
    ) {}

    public async logOut() {
        await this.authenticationService.signOut();
        await this.navigationController.navigateRoot(['account', 'login']);
    }
}

type Image = {
    source: string;
    alternateText: string;
};
