import { Uuid } from '@lib/shared-interface-utility-types';

export type SurveyAnswer = SurveyAnswerChoice | SurveyAnswerOtherChoice;

export type SurveyAnswerChoice = SurveyAnswerOtherChoice & {
    id: Uuid;
};

export type SurveyAnswerOtherChoice = {
    // If id doesn't exist, it is an "other" response
    id?: Uuid;
    text: string;
};

export function isSurveyAnswerChoice(answer: SurveyAnswer): answer is SurveyAnswerChoice {
    return answer.id !== undefined;
}

export function isSurveyAnswerOtherChoice(answer: SurveyAnswer): answer is SurveyAnswerOtherChoice {
    return answer.id === undefined;
}
