import { SurveyAnswerChoice } from './survey-answer.interface';
import { SurveyQuestionFormat } from './survey-question-format.interface';

export type AnySurveyQuestionOptions =
    | SurveySingleSelectOptions
    | SurveyMultipleSelectOptions
    // This should probably always go last for the sake of type guards.
    | SurveyQuestionOptions;

export type SurveyQuestionOptions = {
    format: string;
    hint?: string;
};

export type SurveySingleSelectOptions = SurveyQuestionOptions & {
    format: typeof SurveyQuestionFormat.SINGLE_SELECT;
    allowOther?: boolean;
    answers: SurveyAnswerChoice[];
};

export type SurveyMultipleSelectOptions = SurveyQuestionOptions & {
    format: typeof SurveyQuestionFormat.MULTIPLE_SELECT;
    minSelect?: number;
    maxSelect?: number;
    allowOther?: boolean;
    answers: SurveyAnswerChoice[];
};

export function isSurveySingleSelectOptions(
    options: AnySurveyQuestionOptions,
): options is SurveySingleSelectOptions {
    return options.format === SurveyQuestionFormat.SINGLE_SELECT;
}

export function isSurveyMultipleSelectOptions(
    options: AnySurveyQuestionOptions,
): options is SurveyMultipleSelectOptions {
    return options.format === SurveyQuestionFormat.MULTIPLE_SELECT;
}
