import { inject, Provider } from '@angular/core';
import { I18nService } from './i18n.service';
import { HttpClient } from '@angular/common/http';
import { TranslationLoaderService } from './translation-loader.service';
import { I18N_ASSETS_PREFIX, I18N_ASSETS_SUFFIX } from './i18n.constants';
import { provideAppInitializer } from '@lib/frontend-shared-util-miscellaneous-providers';

export const I18N_APP_INITIALIZER: Provider = provideAppInitializer(createI18nInitializer);

export function httpLoaderFactory(http: HttpClient) {
    return new TranslationLoaderService(I18N_ASSETS_PREFIX, I18N_ASSETS_SUFFIX, http);
}

function createI18nInitializer() {
    const i18nService = inject(I18nService);
    return async () => await i18nService.initLanguage();
}
