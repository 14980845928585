export type KajabiUserImport = RegularSchoolUserImport &
    Required<Pick<SchoolUserImport, 'kajabiMemberId'>>;

export type SchoolCustomerUserImport = RegularSchoolUserImport &
    Required<Pick<SchoolUserImport, 'hubspotCompanyId'>>;

export type RegularSchoolUserImport = Pick<
    SchoolUserImport,
    'firstName' | 'lastName' | 'email' | 'primaryRole'
>;

export type SchoolUserImport = {
    firstName: string;
    lastName: string;
    email: string;
    primaryRole?: string;
    // A school user import can have either one of these, or neither.
    kajabiMemberId?: number;
    hubspotCompanyId?: string;
};

export function isKajabiUserImport(
    schoolUserImport: SchoolUserImport,
): schoolUserImport is KajabiUserImport {
    const hasKajabiMemberId = 'kajabiMemberId' in schoolUserImport;
    return hasKajabiMemberId && typeof schoolUserImport.kajabiMemberId === 'number';
}

export function isSchoolCustomerUserImport(
    schoolUserImport: SchoolUserImport,
): schoolUserImport is SchoolCustomerUserImport {
    const hasHubspotCompanyId = 'hubspotCompanyId' in schoolUserImport;
    return hasHubspotCompanyId && typeof schoolUserImport.hubspotCompanyId === 'string';
}
