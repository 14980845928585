import { map, OperatorFunction } from 'rxjs';

/**
 * If a stream value is undefined, leave it that way.
 * Otherwise, use transform function to map to a new value.
 */
export function mapIfDefined<Value, Result>(
    transform: (value: Value) => Result,
): OperatorFunction<Value | undefined, Result | undefined> {
    const actualTransform = (value: Value | undefined) =>
        value == undefined ? undefined : transform(value);

    return map(actualTransform);
}

/**
 * If a stream value is undefined, return the default result.
 * Otherwise, use transform function to map to a new value.
 */
export function mapOrDefault<Value, Result, DefaultResult = Result>(
    transform: (value: Value) => Result,
    defaultResult: DefaultResult,
): OperatorFunction<Value | undefined, Result | DefaultResult> {
    const actualTransform = (value: Value | undefined) =>
        value == undefined ? defaultResult : transform(value);

    return map(actualTransform);
}
