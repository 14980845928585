import { Component, OnInit } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SkillsService } from '@lib/frontend-shared-skill-data-skill-service';

@Component({
    standalone: true,
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    imports: [IonicModule],
    providers: [
        // This might be possible at the app providers level
        // but the following article shows it in component providers:
        // https://ionic.zendesk.com/hc/en-us/articles/10386373742231-Angular-Standalone-Components-with-Ionic
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
    ],
})
export class AppComponent implements OnInit {
    public constructor(private skillsService: SkillsService) {}

    public ngOnInit() {
        void this.skillsService.prefetchData();
    }
}
