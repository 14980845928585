import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { IfPlatformIsDirective } from '@lib/frontend-shared-ui-platform-directive';
import { SideMenuService } from '@lib/frontent-shared-util-side-menu-service';
import {
    distinctUntilChanged,
    filter,
    firstValueFrom,
    map,
    Observable,
    shareReplay,
    startWith,
    Subscription,
} from 'rxjs';
import { AsyncPipe, DatePipe } from '@angular/common';
import { RequireFeatureDirective } from '@lib/frontend-shared-data-feature-access-service';
import { TranslateModule } from '@ngx-translate/core';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterLink,
    RouterLinkActive,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
    standalone: true,
    selector: 'lib-branded-navigation',
    templateUrl: './branded-navigation.component.html',
    styleUrl: './branded-navigation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        RequireFeatureDirective,
        IfPlatformIsDirective,
        IonicModule,
        RouterLink,
        RouterLinkActive,
        TranslateModule,
        DatePipe,
    ],
})
export class BrandedNavigationComponent implements AfterViewInit, OnDestroy {
    @Input() public includeSideMenu = true;
    @Input() public socialLinks: SocialLink[] = [];

    protected readonly pageTitle$ = this.router.events.pipe(
        // All of this gets us the data/title of the activated child route
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.root.snapshot),
        map((routeSnapshot) => {
            while (routeSnapshot.firstChild) routeSnapshot = routeSnapshot.firstChild;
            return routeSnapshot.title;
        }),
    );
    protected readonly isLargeScreen$: Observable<boolean> = this.platform.resize.pipe(
        startWith(undefined),
        map(() => this.platform.width() > 1560),
        distinctUntilChanged(),
    );
    protected readonly version$ = this.httpClient
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .get<NgswJsonData>('/ngsw.json', { params: { 'version-cache-bust': Date.now() } })
        .pipe(
            map((data) => data.timestamp),
            shareReplay(1),
        );
    private subscription?: Subscription;

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly platform: Platform,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        protected readonly sideMenuService: SideMenuService,
    ) {}

    public ngAfterViewInit() {
        if (this.includeSideMenu) {
            this.subscription = this.isLargeScreen$.subscribe((isLarge) => {
                this.sideMenuService.setOpen(isLarge);
            });
        }
    }

    public ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    public async closeWhenSmall() {
        const isLarge = await firstValueFrom(this.isLargeScreen$);
        if (isLarge) return;

        this.sideMenuService.setOpen(false);
    }
}

type NgswJsonData = {
    timestamp: number;
};

type SocialLink = {
    href: string;
    iconName?: string;
    iconSource?: string;
};
