import { Uuid } from '@lib/shared-interface-utility-types';

/**
 * This extra data only applies to student accounts.
 */
export type StudentUserData = {
    /**
     * The id of the school customer with which an individual student account is affiliated.
     */
    schoolCustomerId: Uuid;
    /**
     * The school-assigned id of this student.
     * We use this and the school customer id to determine the user's generate email for Cognito.
     */
    studentId: string;
};
