import { createStringEnum, EnumValues } from '@lib/shared-interface-utility-types';

export const GradeLevel = createStringEnum([
    'PRE_KINDERGARTEN',
    'KINDERGARTEN',
    'GRADE_1',
    'GRADE_2',
    'GRADE_3',
    'GRADE_4',
    'GRADE_5',
    'GRADE_6',
    'GRADE_7',
    'GRADE_8',
    'GRADE_9',
    'GRADE_10',
    'GRADE_11',
    'GRADE_12',
    'GRADE_13',
    'UNDER_GRADUATE',
    'ADULT_EDUCATION',
]);
export type GradeLevel = EnumValues<typeof GradeLevel>;

export const ALL_GRADE_LEVELS = Object.values<GradeLevel>(GradeLevel);

export const ENABLED_GRADE_LEVELS: GradeLevel[] = [
    GradeLevel.GRADE_5,
    GradeLevel.GRADE_6,
    GradeLevel.GRADE_7,
    GradeLevel.GRADE_8,
];

export const IMPORTED_GRADE_LEVELS: GradeLevel[] = [
    ...ENABLED_GRADE_LEVELS,
    GradeLevel.GRADE_9,
    GradeLevel.GRADE_10,
    GradeLevel.GRADE_11,
    GradeLevel.GRADE_12,
];

export const CLASS_FORM_GRADE_LEVELS: GradeLevel[] = [GradeLevel.GRADE_4, ...IMPORTED_GRADE_LEVELS];

export const INTAKE_REPORTED_GRADE_LEVELS: GradeLevel[] = [
    GradeLevel.KINDERGARTEN,
    GradeLevel.GRADE_1,
    GradeLevel.GRADE_2,
    GradeLevel.GRADE_3,
    GradeLevel.GRADE_4,
    ...IMPORTED_GRADE_LEVELS,
];

const gradeLevelDisplayMap = {
    [GradeLevel.PRE_KINDERGARTEN]: 'Pre-Kindergarten',
    [GradeLevel.KINDERGARTEN]: 'Kindergarten',
    [GradeLevel.GRADE_1]: '1st Grade',
    [GradeLevel.GRADE_2]: '2nd Grade',
    [GradeLevel.GRADE_3]: '3rd Grade',
    [GradeLevel.GRADE_4]: '4th Grade',
    [GradeLevel.GRADE_5]: '5th Grade',
    [GradeLevel.GRADE_6]: '6th Grade',
    [GradeLevel.GRADE_7]: '7th Grade',
    [GradeLevel.GRADE_8]: '8th Grade',
    [GradeLevel.GRADE_9]: '9th Grade',
    [GradeLevel.GRADE_10]: '10th Grade',
    [GradeLevel.GRADE_11]: '11th Grade',
    [GradeLevel.GRADE_12]: '12th Grade',
    [GradeLevel.GRADE_13]: '13th Grade',
    [GradeLevel.UNDER_GRADUATE]: 'Undergraduate',
    [GradeLevel.ADULT_EDUCATION]: 'Adult Education',
} as const;

export function getDisplayedGradeLevel(gradeLevel: GradeLevel): string {
    return gradeLevelDisplayMap[gradeLevel];
}

export function assertGradeLevel(gradeLevel: unknown): asserts gradeLevel is GradeLevel {
    if (!Object.values(GradeLevel).includes(gradeLevel as GradeLevel)) {
        throw new Error(`Invalid grade level`);
    }
}

export function assertGradeLevels(gradeLevels: string[]): asserts gradeLevels is GradeLevel[] {
    for (const gradeLevel of gradeLevels) {
        assertGradeLevel(gradeLevel);
    }
}
