import { SurveyAnswer } from './survey-answer.interface';
import { SurveyQuestionFormat } from './survey-question-format.interface';

export type AnySurveyResponse =
    | SurveySingleSelectResponse
    | SurveyMultipleSelectResponse
    // This should probably always go last for the sake of type guards.
    | SurveyResponse;

export type SurveyResponse = {
    format: string;
};

export type SurveyMultipleSelectResponse = {
    format: typeof SurveyQuestionFormat.MULTIPLE_SELECT;
    answers: SurveyAnswer[];
};

export type SurveySingleSelectResponse = {
    format: typeof SurveyQuestionFormat.SINGLE_SELECT;
    answer: SurveyAnswer;
};

export function isSurveySingleSelectResponse(
    response: AnySurveyResponse,
): response is SurveySingleSelectResponse {
    return response.format === SurveyQuestionFormat.SINGLE_SELECT;
}

export function isSurveyMultipleSelectResponse(
    response: AnySurveyResponse,
): response is SurveyMultipleSelectResponse {
    return response.format === SurveyQuestionFormat.MULTIPLE_SELECT;
}
