import { UserApplication } from './shared/user-application.interface';
import { FullAccount } from './full-account.interface';

export function isInternalAccount(account: AnyAccount): account is InternalAccount {
    return account.userApplication === UserApplication.INTERNAL;
}

export function isSchoolAccount(account: AnyAccount): account is SchoolAccount {
    return account.userApplication === UserApplication.SCHOOL;
}

export function isStudentAccount(account: AnyAccount): account is StudentAccount {
    return account.userApplication === UserApplication.STUDENT;
}

type AnyAccount = FullAccount | InternalAccount | SchoolAccount | StudentAccount;

type InternalAccount = Omit<
    FullAccount,
    'userApplication' | 'additionalSchoolUserData' | 'additionalStudentUserData'
> & {
    userApplication: typeof UserApplication.INTERNAL;
};

type SchoolAccount = Omit<
    FullAccount,
    'userApplication' | 'additionalInternalUserData' | 'additionalStudentUserData'
> & {
    userApplication: typeof UserApplication.SCHOOL;
};

type StudentAccount = Omit<
    FullAccount,
    'userApplication' | 'additionalInternalUserData' | 'additionalSchoolUserData'
> & {
    userApplication: typeof UserApplication.STUDENT;
};
