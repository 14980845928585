import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    public constructor(private readonly toastController: ToastController) {}

    public async presentError(options: ToastOptions): Promise<HTMLIonToastElement> {
        const toast = await this.toastController.create({
            animated: true,
            buttons: [{ text: 'Dismiss' }],
            color: 'danger',
            duration: 10_000,
            icon: 'alert-circle-outline',
            position: 'bottom',
            ...options,
        });
        await toast.present();

        return toast;
    }

    public async presentWarning(options: ToastOptions): Promise<HTMLIonToastElement> {
        const toast = await this.toastController.create({
            animated: true,
            color: 'warning',
            duration: 5000,
            icon: 'warning-outline',
            position: 'bottom',
            ...options,
        });
        await toast.present();

        return toast;
    }

    public async presentSuccess(options: ToastOptions): Promise<HTMLIonToastElement> {
        const toast = await this.toastController.create({
            animated: true,
            color: 'success',
            duration: 3000,
            icon: 'checkmark-circle-outline',
            position: 'bottom',
            ...options,
        });
        await toast.present();

        return toast;
    }
}
