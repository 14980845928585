import { inject, InjectionToken, Provider } from '@angular/core';
import { CognitoUserPool, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import { AuthenticationService } from './authentication.service';
import { AuthenticationInterceptor } from './authentication.interceptor';
import {
    provideAppInitializer,
    provideInterceptor,
} from '@lib/frontend-shared-util-miscellaneous-providers';

export type AuthenticationConfiguration = ICognitoUserPoolData;

const AUTHENTICATION_CONFIGURATION_TOKEN = new InjectionToken<AuthenticationConfiguration>(
    'AuthenticationConfiguration',
);

const COGNITO_USER_POOL_PROVIDER: Provider = {
    provide: CognitoUserPool,
    // if there is more than one parameter for `CognitoUserPool`
    // then a factory function might be more appropriate.
    useClass: CognitoUserPool,
    deps: [AUTHENTICATION_CONFIGURATION_TOKEN],
};

const AUTHENTICATION_STATE_INITIALIZER = provideAppInitializer(getAuthenticationStateInitializer);
const AUTHENTICATION_INTERCEPTOR_PROVIDER = provideInterceptor(AuthenticationInterceptor);

export function provideAuthenticationService(configuration: AuthenticationConfiguration): Provider {
    const configurationProvider = {
        provide: AUTHENTICATION_CONFIGURATION_TOKEN,
        useValue: configuration,
    };

    return [
        AUTHENTICATION_INTERCEPTOR_PROVIDER,
        AUTHENTICATION_STATE_INITIALIZER,
        COGNITO_USER_POOL_PROVIDER,
        configurationProvider,
    ];
}

function getAuthenticationStateInitializer() {
    const authenticationService = inject(AuthenticationService);
    return () => authenticationService.initAuthenticationState();
}
