import { Uuid } from '@lib/shared-interface-utility-types';
import {
    AnySurveyQuestionOptions,
    isSurveyMultipleSelectOptions,
    isSurveySingleSelectOptions,
    SurveyMultipleSelectOptions,
    SurveySingleSelectOptions,
} from './survey-question-options.interface';

export type AnySurveyQuestion =
    | SurveyMultipleSelectQuestion
    | SurveySingleSelectQuestion
    // This should probably always go last for the sake of type guards.
    | SurveyQuestion;

export type SurveyQuestion = {
    id: Uuid;
    /**
     * In the future, this could be removed in favor of a "surveys" collection.
     *
     * In discussion with Dylan,
     * we decided it could be easier to store question order in a "surveys" collection.
     * This would also allow questions to be re-used by multiple surveys.
     * That said, it didn't seem that we needed that flexibility just yet,
     * and it wouldn't be hard to migrate later if we decide we do need that flexibility.
     * So for now, this approach locks us down to a relationship of 1-survey to many-questions
     * and can be represented with a single collection.
     */
    survey?: SurveyDetails;
    text: string;
    options: AnySurveyQuestionOptions;
    createdAt: number;
    updatedAt: number;
};

export type SurveyDetails = {
    title: string;
    /** The order in which this question will appear when requesting a survey by its title. */
    order: number;
};

export type SurveySingleSelectQuestion = Omit<SurveyQuestion, 'options'> & {
    options: SurveySingleSelectOptions;
};

export type SurveyMultipleSelectQuestion = Omit<SurveyQuestion, 'options'> & {
    options: SurveyMultipleSelectOptions;
};

export const SCHOOL_REGISTRATION_SURVEY_TITLE = 'SCHOOL_REGISTRATION_SURVEY';

export function isSurveySingleSelectQuestion(
    question: AnySurveyQuestion,
): question is SurveySingleSelectQuestion {
    return isSurveySingleSelectOptions(question.options);
}

export function isSurveyMultipleSelectQuestion(
    question: AnySurveyQuestion,
): question is SurveyMultipleSelectQuestion {
    return isSurveyMultipleSelectOptions(question.options);
}
