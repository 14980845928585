import { StringEnum } from './enum-types';

/**
 * Creates a string enum where the values are identical to the keys.
 */
export function createStringEnum<Keys extends string>(keys: readonly Keys[]): StringEnum<Keys> {
    const enumDefinition: Record<string, string> = {};
    for (const value of keys) enumDefinition[value] = value;

    return enumDefinition as StringEnum<Keys>;
}
