import {
    AnySurveyQuestion,
    SurveyMultipleSelectQuestion,
    SurveySingleSelectQuestion,
} from './survey-question.interface';
import {
    AnySurveyResponse,
    SurveyMultipleSelectResponse,
    SurveySingleSelectResponse,
} from './survey-response.interface';
import { SurveyQuestionFormat } from './survey-question-format.interface';

export type AnySurveyResult =
    | SurveySingleSelectResult
    | SurveyMultipleSelectResult
    // This should probably always go last for the sake of type guards.
    | SurveyResult;

export type SurveyResult = {
    question: AnySurveyQuestion;
    response: AnySurveyResponse;
    // updatedAt is likely not necessary
    createdAt: number;
};

export type SurveyMultipleSelectResult = Omit<SurveyResult, 'question' | 'response'> & {
    question: SurveyMultipleSelectQuestion;
    response: SurveyMultipleSelectResponse;
};

export type SurveySingleSelectResult = Omit<SurveyResult, 'question' | 'response'> & {
    question: SurveySingleSelectQuestion;
    response: SurveySingleSelectResponse;
};

// This is a workaround for graphql not allowing union types in their inputs.
export type RawSurveyResultUpdate = {
    // Only one of these is expected to be set, otherwise additional properties are ignored.
    multipleSelect?: SurveyMultipleSelectResultUpdate;
    singleSelect?: SurveySingleSelectResultUpdate;
};

export type SurveyResultUpdate = SurveyMultipleSelectResultUpdate | SurveySingleSelectResultUpdate;

export type SurveyMultipleSelectResultUpdate = Omit<SurveyMultipleSelectResult, 'createdAt'> & {
    response: SurveyMultipleSelectResponse;
};

export type SurveySingleSelectResultUpdate = Omit<SurveySingleSelectResult, 'createdAt'> & {
    response: SurveySingleSelectResponse;
};

export function isSurveyMultipleSelectResultUpdate(
    result: SurveyResultUpdate,
): result is SurveyMultipleSelectResultUpdate {
    return (
        result.question.options.format === SurveyQuestionFormat.MULTIPLE_SELECT &&
        result.response.format === SurveyQuestionFormat.MULTIPLE_SELECT
    );
}

export function isSurveySingleSelectResultUpdate(
    result: SurveyResultUpdate,
): result is SurveySingleSelectResultUpdate {
    return (
        result.question.options.format === SurveyQuestionFormat.SINGLE_SELECT &&
        result.response.format === SurveyQuestionFormat.SINGLE_SELECT
    );
}
