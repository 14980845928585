import { FeatureFlag } from '@lib/shared-interface-feature-flag';
import { InjectionToken } from '@angular/core';

export type FeatureAccessConfiguration = {
    disabledFeatures: FeatureFlag[];
};

export const FEATURE_ACCESS_CONFIGURATION_TOKEN = new InjectionToken<FeatureAccessConfiguration>(
    'FeatureAccessConfiguration',
);

export function provideFeatureAccessConfiguration(configuration: FeatureAccessConfiguration) {
    return {
        provide: FEATURE_ACCESS_CONFIGURATION_TOKEN,
        useValue: configuration,
    };
}
