import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EXPOSITORY_6TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Problem-Solution 6th Grade',
            url: '/resources/37a9fde7-ce90-4972-925f-ec69d5e3b7a4',
        },
        {
            text: 'Compare + Contrast 6th Grade',
            url: '/resources/e142c31f-998c-4e0c-97e6-e5175fb3a584',
        },
        {
            text: 'Cause + Effect 6th Grade',
            url: '/resources/33b8a706-8448-433d-aa09-8ff14e0f5010',
        },
        {
            text: 'Descriptive Writing 6th Grade',
            url: '/resources/caee5cd7-cefb-477a-be42-774dfce7f7d0',
        },
    ],
    headingConfig: {
        letter: 'E',
        level: 'Level E',
        grade: 'Grade 6',
        color: 'var(--program-grade-level-e)',
    },
    slideDeckHeading: 'Expository Slide Decks',
    slideDeckUrl: [
        {
            text: 'Cause_and_Effect_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Cause_and_Effect_Slide_Decks.zip',
        },
        {
            text: 'Compare_and_Contrast_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Compare_and_Contrast_Slide_Decks.zip',
        },
        {
            text: 'Descriptive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Descriptive_Writing_Slide_Decks.zip',
        },
        {
            text: 'Problem-Solution_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level E/Problem-Solution_Slide_Decks.zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'causeAndEffect',
            heading: 'Cause + Effect',
            content: [
                {
                    text: 'Cause + Effect Graphic Organizer 1',
                    url: 'https://docs.google.com/presentation/d/1H3AKbuaUpn1G9Z7t7xyxC39pWc7FavyXtiS0reSx4JY/copy',
                },
                {
                    text: 'Cause + Effect Graphic Organizer 2',
                    url: 'https://docs.google.com/presentation/d/1ARRoBdKyQF9cJD3C2riCCdac2vK-LCCY6fH9aq_Uf1Y/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1d-CpX0QH_aewSGcuRn5BW7Ug6ejgA-O2Wc8YzsVkQDQ/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1Drzj25fJ1LwGa4l6iBMsxMP8R-1Np6Dih3I8Aac-ycI/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1e1cHCOzJ8BTKFvsE9VIcQFGNqMvGZ3hA91IBSwmimnU/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1dEx3Iu2ojcNGsPfJCrMV_ntV8Fov9Rhyh7-2cHQgBJ0/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1Ui3V5gPTvfdSbtUfkYns65fspmC2VYRxuHfwZP5_fbk/copy',
                },
                {
                    text: 'Identifying Parts of an Intro Paragraph',
                    url: 'https://docs.google.com/presentation/d/1N2QIflJ-F9ItjOHU2VFMwtQHjAa0J6FtayFJ9C7iIgo/copy',
                },
                {
                    text: 'Premises in Writing',
                    url: 'https://docs.google.com/presentation/d/1zKHRFffRWoMq3B84N8f3mMlzJZG3zBb5d8Zei3oMbdI/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1B-Jt0w_q5nuQBb7J3odSBqbeYB46F38wkRGrv_IebeY/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/13iy4dQhlTnk8hujLmbb2kAQHNhLL2wOn-M45r-6IGbg/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1sjQvaRGPamyqs4y81gb-9kcLB2jCAMp_efRxwOU7RWU/copy',
                },
                {
                    text: 'Practice with the Intro and Body Paragraphs',
                    url: 'https://docs.google.com/presentation/d/1qYOvBrfHTmocntt3CncUGG0hMtdkwfxPpBBxPO5lzrg/copy',
                },
                {
                    text: 'Restating the Claim',
                    url: 'https://docs.google.com/presentation/d/1G6CP0CfO9E87kmwcMxyISd6RNdBvOFgDqeVTMZgn5j0/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1UuVQXJ2Md7cvso4_iWspFZ2QWjmhWRn5I62q5QPZTv0/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1wCBeL3kwtpYwU1dmpwA17p-p6VkvBR1zhLHhuzmqac4/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1aT3cRN-mTQ6DnjzXvDlLRorod4W_VIerU1VBy2b6lj8/copy',
                },
                {
                    text: 'Cause and Effect Rubric',
                    url: 'https://docs.google.com/presentation/d/1n-BBt1GhT6Z4k14TiGgaxzNUw4m5i0Ppy7w1zQSlzJk/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1nVMCogdr1XOzZ_F7wR6wQLz0pfVkEA7PNp-3PieVE5U/copy',
                },
            ],
        },
        {
            value: 'compareAndContrast',
            heading: 'Compare + Contrast',
            content: [
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TOPICS',
                    url: 'https://docs.google.com/presentation/d/1qzeYQNjwPdqsPuWoKoysdb5EwheA8uakIHJ0UNCXGa8/copy',
                },
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TEXTS',
                    url: 'https://docs.google.com/presentation/d/1afnQHSGJ4PZMXwqSSqlP7VdV-k2PRzGEPYN7BkdFonY/copy',
                },
                {
                    text: 'Game Cards',
                    url: 'https://docs.google.com/presentation/d/1GrsnpiM1y7Ht2S0UN-_9Im55v-75ALP0ItkdRD2yZno/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1m_wM_EB8SWYx5GPaUE44kWEKM-_ga3LmfxKwGAsCNs8/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1iUtvmjYnypUlzt09Rvuxv3u9LNu8URvuAUFfRDHPOoc/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1FRZBg2JPj5gZZbOOOJ1_FRJ_JCbY8h8qz1FgTEW1IrM/copy',
                },
                {
                    text: 'Matching Prompts + Claims',
                    url: 'https://docs.google.com/presentation/d/1PW_HKmwefgUM1UhcU9qR_Bh-3vTFJb97PeoR3Vf5te0/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1YyMoULLamRWK3iHCoWmCqU8kFhRCjUH-cEMa-wcYSno/copy',
                },
                {
                    text: 'Intro and Body Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1iVUNdcN5SIOMgk5B3REQK4LEKf7s504W_98MraT2TnQ/copy',
                },
                {
                    text: 'Premises in Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1GttGgqtCyrBMWpDF0s7NzjS9DPLc2-vRsmAxq3IY3yI/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1mFjQwIAtbZz7FR2GY4QffZgjJ_lWy5w2BNs5RYmi9x4/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1puGp9M2VXHiqkNxN6ActYFOIA9n2LW64FFeHpX39nfw/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1HAk0SuBOcuQPEf_QRWSuO6_rHsu_OQPntCAcATs-kDw/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1iChbMAPy8VGCtR9d9sloRfPTCHFDbaYzJv-6OnIlHKI/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/18WhcJeJPOkKbwldSBO095nMn9m4febWkWHjq_5iHCOs/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1S7ig_D0jM-o7WbjHNHzEsEbHkU98L2b6djerhkUvwTc/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1VvxphuihCQyddAsCXx--U6JIrGzqYI2qTsG33Y-vhME/copy',
                },
                {
                    text: 'Compare and Contrast Rubric',
                    url: 'https://docs.google.com/presentation/d/1nSWPWUZMRrCaBVBEvuHNb_Uu5kvAMWls56WyUYAcJSI/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1Wd0MXpPLekEWSX3qHeBd7Hg644kl5PozucG47RIm0z0/copy',
                },
            ],
        },
        {
            value: 'descriptiveWriting',
            heading: 'Descriptive Writing',
            content: [
                {
                    text: 'Descriptive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/17iz45TbdYAZFuvL644Zt0LGFbGfT6xBsMeEg9p7CfNc/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1nDK-g9_4pPrnmeoVXXzG5Pd_9XTs1IA96-0PabNcgps/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/16Ltd6KEUbn3mSuj6VPXWmb0l6X_SdQ_g-McSwKGs-K4/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1yKTjKogtKHFXxVF_tEmS94dj_gVtGnR2X1jne_LuTps/copy',
                },
                {
                    text: 'Practicing Thesis Statements',
                    url: 'https://docs.google.com/presentation/d/1RP8rrwlnwVr78ZhwPZg8s9PBoEzy0182CGWgxp7yXcM/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1zOui2dhpZrFnEYa089aotw9ij9YmgvvCs46_U1jWv_4/copy',
                },
                {
                    text: 'Understanding the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1BuiGMWiTYWA7XGRJroxuJ_JZKLW1Wz8xlD8SpWziMy8/copy',
                },
                {
                    text: 'Practicing the Topic Sentences',
                    url: 'https://docs.google.com/presentation/d/1DNtKOx9mysMMe1X0G8zqWjHP3zkFr4Z-WSUv9ozhE5E/copy',
                },
                {
                    text: 'Concluding Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1BeKHyYhbsgomyacSVuYUSRyUIqaxnupbjC01MDUEZQU/copy',
                },
                {
                    text: 'Writing a Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1LMdJfP824Dz0N8JIwQ5cWZrVZVRe2zML5zTcvtU9grs/copy',
                },
                {
                    text: 'Restating the Thesis Statement',
                    url: 'https://docs.google.com/presentation/d/1PI6PcFSDoP1Si0pQrV0zFZQ7X7_YTj9MILAT7micVqw/copy',
                },
                {
                    text: 'Summarizing the Topic Sentence',
                    url: 'https://docs.google.com/presentation/d/1tVAyr9xWj1_VmB8jOkgxukvBiy-fotBTndzIp-EhqEk/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1W6cBUkWH_ax0lVIbOWO9MSDh0MaIB6QbGzb5btX7oaw/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1DVP3C10N9zXRpsTBQpLcO-8OhV4I8UIbNvhyRz3r6xU/copy',
                },
                {
                    text: 'Descriptive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1Jpe35MyS-QMMxd3n6T3aeJ9SV51oKPLdhJNp5fBP0YQ/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1ru3xCWGtFv9i11Fv6FvctaGhng6EC5O0qDAtxy-X_Xk/copy',
                },
            ],
        },
        {
            value: 'problemSolution',
            heading: 'Problem-Solution',
            content: [
                {
                    text: 'Problem-Solution Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1rDwKJJABJ6aZIGENUUA5G2k5bVENKr6Dkgkq6uAFqoU/copy',
                },
                {
                    text: 'Sorting Cards',
                    url: 'https://docs.google.com/presentation/d/1dkfYNt-mSiZNiRVGM4VgUgrxkgqogHnlMkQXOHhD7no/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1jDFTg3EpnLrWTqdrN-oziVbsNa--D1RIsbLGnVaClRA/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1kx6Rwn6u4NJsaV--yW8ud6AyFLx6qt9U-2WU5QK1de0/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1HPMG8OPyScUHUQOU-GFwMDOqFqXHc7mpE8vIWrGk-n8/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1XOysGaffAK3G_gay4zcsRvFN4bAeOcXUGF6kOSK8ODw/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1brT7Et7TtDRYQEOSpFmpDfsdWMPRIgotVD_xavu4i88/copy',
                },
                {
                    text: 'Premises Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1oieQAD9VyRN4OYfb7OlG6WoJ7Rgf4rY764BwupL1l6k/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/13l3n14eCtqeWjV3Vhn_pWxmhWzw7yMZL72CJ-wd4Wnw/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1_YA21DXrz_KkQ6t113ksIiz3cSvMZls2RGbtUmA2jWo/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1B_GCiMBCNRxNYdgvtbcdPXRDdHlqIoi4mD3pECzXDDw/copy',
                },
                {
                    text: 'Intro and Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1Fr-2xrx-CA2SEmSPhtXp5pdlzFOtW37qZtyJFXqKxXA/copy',
                },
                {
                    text: 'Restating the Thesis ',
                    url: 'https://docs.google.com/presentation/d/11WxXdB8j8jauwxWZUvajhwCp3aMi_H94xB5HibbdMig/copy',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1tsU0_s6-nw3GOIagsl73Z7MbdU_K-nd4hpu9Lyy6GIw/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1OQi29KJKt7-yhemOiGu6hkV7z0TIWK2rXmYXQus0GAQ/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1gGim8czfM4ouWIKvClpB96iYrk7T6TEwdXTpSarN8UM/copy',
                },
                {
                    text: 'Problem-Solution Rubric',
                    url: 'https://docs.google.com/presentation/d/1CY8uJM1JaD-RKsSLYr-6afD91DQCW89ZVi6BR8hQ13M/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1FNu9bKO8AvPN_dFD2xlJwfKV8m1KhBTLofrM_ttOYAI/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'causeAndEffect',
            heading: 'Cause and Effect Writing',
            content: [
                {
                    text: 'What Is Cause and Effect Writing?',
                    url: 'https://drive.google.com/file/d/1MGV24IkiCfpG5z8MKcxNr35NztFl5QpB/view?usp=drive_link',
                },
                {
                    text: 'Is This a Good Topic?',
                    url: 'https://drive.google.com/file/d/1V-zkMxPu4ZLh2P--r6OLwMKTD_GO6_jD/view?usp=drive_link',
                },
                {
                    text: 'Gathering Evidence',
                    url: 'https://drive.google.com/file/d/1IIjRAzhmqtMmazdRKwOzdZrerXGgrZcD/view?usp=drive_link',
                },
                {
                    text: 'Investigation Trail',
                    url: 'https://drive.google.com/file/d/1v9LFj9n6HMgSJpNpcS_Ld-WoxMpecBQj/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphCnE',
            heading: 'The Introductory Paragraph | Cause and Effect',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1pku7fdB66BUYu1ccfemKZnKMEwIbrCi9/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1qQZHAzVLEYpdGzEtNli1SmSeS1UYvPH_/view?usp=drive_link',
                },
                {
                    text: 'The Claim',
                    url: 'https://drive.google.com/file/d/1D7zmDXK_wmJmgaTjdYnZl1DL-1EkF4Gn/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1JlP8P4kjl9X_TJfOM62sE7tiQDI0eIWf/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphCnE',
            heading: 'The Body Paragraph | Cause and Effect',
            content: [
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/1mOhxvA5231T2fwQSLqIaj9U_e_EpMYAh/view?usp=drive_link',
                },
                {
                    text: 'Introduction to Evidence',
                    url: 'https://drive.google.com/file/d/17PeedYhkj6gJ5Ewn2kAR3XGioWl_ssz7/view?usp=drive_link',
                },
                {
                    text: 'The Evidence',
                    url: 'https://drive.google.com/file/d/14zxg9TEtUkYwCqxMS3L6tJVP-0MUhtbT/view?usp=drive_link',
                },
                {
                    text: 'Justification',
                    url: 'https://drive.google.com/file/d/1IvC5N1i9FvLduq5sxRgeXu1xBkuG6ox1/view?usp=drive_link',
                },
                {
                    text: 'Body Paragraph Practice',
                    url: 'https://drive.google.com/file/d/1ECI2o7HCgnnBF3MyrLow9_hmq0b_veUL/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphCnE',
            heading: 'The Conclusion Paragraph | Cause and Effect',
            content: [
                {
                    text: 'Restating the Claim',
                    url: 'https://drive.google.com/file/d/1bWW9xZ1XaGDcP4YMQRQF0LuV6-W9gb4V/view?usp=drive_link',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://drive.google.com/file/d/11Y0_miM7XRW5qbpnUJcb1l3x_Q4gFYwm/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1KsCMqpKNxEFnCn5yXDjcBK7Xm1SijLOd/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1FLLxikTjZBnU6MoweuZXPdixmmkQpcJ7/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'problemSolution',
            heading: 'Problem-Solution Writing',
            content: [
                {
                    text: 'What is Problem-Solution Writing',
                    url: 'https://drive.google.com/file/d/1JEmKvCkhQJpLjqY5r1FQJ2APo0BVo9GK/view?usp=drive_link',
                },
                {
                    text: 'Preparing for a Problem-Solution Essay',
                    url: 'https://drive.google.com/file/d/1e-SGezgSsB4KwFwPFqcP2YvIrxhOG05K/view?usp=drive_link',
                },
                {
                    text: 'Problem-Solution Activity',
                    url: 'https://drive.google.com/file/d/1U_ERfqMZD5KZqcg4QY2j7s__XWmmzvFL/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphPS',
            heading: 'The Introductory Paragraph | Problem-Solution',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1GF943MSr6jtUZEi17AQ8_seoZhAgco5r/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1xmgdJzmqaCTrJRJxMKtuaTBb-EV51A3v/view?usp=drive_link',
                },
                {
                    text: 'The Thesis Statement',
                    url: 'https://drive.google.com/file/d/1a7Jrq98IDsRdLXFyhdqeuCGgk-Gzi8yS/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1NBf6P5OVc00N902nW8YwDy6MlWKi-rWQ/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphPS',
            heading: 'The Body Paragraph | Problem-Solution',
            content: [
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/15QlJ_Ab7V0Hs_Kcw1WpgpIkk9UGhqIIo/view?usp=drive_link',
                },
                {
                    text: 'The Introduction to Evidence',
                    url: 'https://drive.google.com/file/d/1yz0LVIkVX3ATYf0BgPuYh_pIWrDvcB3O/view?usp=drive_link',
                },
                {
                    text: 'The Evidence',
                    url: 'https://drive.google.com/file/d/10v0dfNjsepuOrv0Sn_bYz5tzC7QQx3tK/view?usp=drive_link',
                },
                {
                    text: 'Justification',
                    url: 'https://drive.google.com/file/d/1wkILv5AMLZ5yHQyzQHwX6gV6CSGQ1bfL/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1lATOXSaFo-o2I1xWYgy5EjXQmYfDxovn/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphPS',
            heading: 'The Conclusion Paragraph | Problem-Solution',
            content: [
                {
                    text: 'The Restatement of Thesis',
                    url: 'https://drive.google.com/file/d/1iFc8C2_lDlHO6KCu65GmkR8KeCVxF-1k/view?usp=drive_link',
                },
                {
                    text: 'The Summary or Evidence',
                    url: 'https://drive.google.com/file/d/1E-qQcxWfDzQhxKKdEGeeVyXD-6xudD2T/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1PQ97NRbu_KHra1IARk4gpkNzBpx-iwfG/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1lnQWDV_1nwbSEME2IUsyu2VrilxXOd0K/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'compareAndContrast',
            heading: 'Compare and Contrast Writing',
            content: [
                {
                    text: 'What is Compare and Contrast Writing',
                    url: 'https://drive.google.com/file/d/1PQ4BB7yqME6WFbEF-uvUNp2UvbEFBMgZ/view?usp=drive_link',
                },
                {
                    text: 'Preparing for a Compare and Contrast Essay',
                    url: 'https://drive.google.com/file/d/1zPt9R6v-kC6LrCie5UxUt0GF2ltn5I3x/view?usp=drive_link',
                },
                {
                    text: 'Compare and Contrast Game',
                    url: 'https://drive.google.com/file/d/1SfU4mv3C_x-R5FnCOibdeEk10uOJs0Qs/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphCnC',
            heading: 'The Introductory Paragraph | Compare and Contrast',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1D3SNl2vFI_peBG0IK-CuJ6V1-GQOKDbe/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1Yax4dQ6w2WdoixEdVtR9M_NU3E5mQRWW/view?usp=drive_link',
                },
                {
                    text: 'The Claim',
                    url: 'https://drive.google.com/file/d/1IbBzSlqdQjTb2jC0hglTAVaDh3hRP2jl/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1XI0CRRmxEfVJhM6ycjQe92OtgGVcUKDY/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphCnC',
            heading: 'The Body Paragraph | Compare and Contrast',
            content: [
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/1tqGN_dsb-Qm3nfZzn3CIr_OnHOK1HSaQ/view?usp=drive_link',
                },
                {
                    text: 'The Introduction to Evidence',
                    url: 'https://drive.google.com/file/d/1hGkAH8NoXquVhshsz8EyebFBzj4sa3BS/view?usp=drive_link',
                },
                {
                    text: 'The Evidence',
                    url: 'https://drive.google.com/file/d/1UAFCadh2LINm-h1mgsCLL_wByKOlif1U/view?usp=drive_link',
                },
                {
                    text: 'The Justification',
                    url: 'https://drive.google.com/file/d/1f4ZAHaKqQ_AZmlY3RpFz7gey3-2f0klJ/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1b6URmXT5UYbWC6TpTyD09iWhBvsLBjZ0/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphCnC',
            heading: 'The Conclusion Paragraph | Compare and Contrast',
            content: [
                {
                    text: 'The Restatement of the Claim',
                    url: 'https://drive.google.com/file/d/1uoxzPzFhLEN5fmawG-kQ-msDdTGz62f9/view?usp=drive_link',
                },
                {
                    text: 'The Summary of Evidence',
                    url: 'https://drive.google.com/file/d/1zoNM6xt8x6oMuyN493mG8jMk8lv9fK-T/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1O6sb3GlIhwZrWOZdySU0Kwtt9eydrWcQ/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1FZUSEApsk9CR3gY0YJuw7B2qgx5ytR42/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'descriptiveWriting',
            heading: 'Descriptive Writing',
            content: [
                {
                    text: 'What is Descriptive Writing',
                    url: 'https://drive.google.com/file/d/1K2ndJdB8jPW5AXtBFRkbEmUpWmfYh5LL/view?usp=drive_link',
                },
                {
                    text: 'Organizing a Descriptive Writing Essay',
                    url: 'https://drive.google.com/file/d/1cKFlSGKOF1ykaQ6JpG8RPjDhUi5SdBWr/view?usp=drive_link',
                },
                {
                    text: 'Introducing the Concept',
                    url: 'https://drive.google.com/file/d/1Z2fBQ4ER83knr14Tt-EXeIFqUoKXKMDz/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphDW',
            heading: 'The Introductory Paragraph | Descriptive Writing',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1FqHHBV-EzCPAzLGSf4rxy551EWCCqQY1/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1f0Nc6EG3KHvTjrabhf9i4Ox2ThrKzP_l/view?usp=drive_link',
                },
                {
                    text: 'The Thesis Statement',
                    url: 'https://drive.google.com/file/d/1G70w1CNYQ_qRdwM7orxrTrjpWS7C52ZC/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1qMiAN6wsgr99WLBVqIddAG3Wv_KKhFXh/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphDW',
            heading: 'The Body Paragraph | Descriptive Writing',
            content: [
                {
                    text: 'The Topic Sentences',
                    url: 'https://drive.google.com/file/d/1B7g6ZNRVEr91deCdKO7YUvOarl96bVDf/view?usp=drive_link',
                },
                {
                    text: 'The Supporting Details',
                    url: 'https://drive.google.com/file/d/1u5uiv0VrxWBou6etteBddxVesqgNt5D-/view?usp=drive_link',
                },
                {
                    text: 'The Concluding Statement',
                    url: 'https://drive.google.com/file/d/1ZDXrvG6Q2BUxEQefJFSGG-xrvZjq-k7u/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1LlQKXiXT7MYxyMbN6Z9feLiKTGtW4NgE/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphDW',
            heading: 'The Conclusion Paragraph | Descriptive Writing',
            content: [
                {
                    text: 'Restating the Thesis Statement',
                    url: 'https://drive.google.com/file/d/1RdF-kRlqPTSzl50-rYQLYRjuUBz5Zd3W/view?usp=drive_link',
                },
                {
                    text: 'Summarizing the Topic Sentences',
                    url: 'https://drive.google.com/file/d/140PMJffM9TjJERroTBsRKHt2z4yOXQtH/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1x-Akt7vuJJwjEjpMd7z5Z7wD_wCDzgRR/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1URmHatvWCYsxCal4VHi64pfuL6sr3d-0/view?usp=drive_link',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level E/Certificate_of_Completion_Expository_Writing.pdf',
};
