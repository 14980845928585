import { createStringEnum, EnumValues } from '@lib/shared-interface-utility-types';

export const SCHOOL_ACCOUNT_ROLES = [
    'ADMINISTRATOR',
    'TEACHER',
    // 'DEMO', // Future role for use with potential customers
] as const;

export const SchoolAccountRole = createStringEnum(SCHOOL_ACCOUNT_ROLES);
export type SchoolAccountRole = EnumValues<typeof SchoolAccountRole>;
