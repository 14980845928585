<ion-header class="inherit-background ion-no-border">
    <ion-toolbar id="navigation-toolbar">
        <ion-buttons slot="start">
            @if (includeSideMenu) {
                <ion-button (click)="sideMenuService.toggleOpen()">
                    <ion-icon name="menu" slot="icon-only" />
                </ion-button>
            }
        </ion-buttons>
        <picture class="ion-hide-xl-down" slot="start">
            <source
                media="(prefers-color-scheme:dark)"
                srcset="/assets/logos/logo-icon-black-name-white.svg"
            />
            <img
                alt="Logo icon and name"
                class="icon-full cursor-pointer"
                routerLink="/"
                src="/assets/logos/logo-icon-black-name-white.svg"
            />
        </picture>
        <picture class="ion-hide-xl-up ion-hide-sm-down" slot="start">
            <source
                media="(prefers-color-scheme:dark)"
                srcset="/assets/logos/logo-icon-only-black.svg"
            />
            <img
                alt="Logo icon"
                class="icon-only cursor-pointer"
                routerLink="/"
                slot="start"
                src="/assets/logos/logo-icon-only-black.svg"
            />
        </picture>
        <ion-title *libIfPlatformIs="'mobile'"> {{ pageTitle$ | async }} </ion-title>

        <ion-buttons
            *libIfPlatformIs="'desktop'"
            (click)="closeWhenSmall()"
            class="large-screen-navigation ion-justify-content-around"
            id="desktop-buttons"
        >
            <ng-content select="ion-button[desktopNavigationLink]" />
        </ion-buttons>

        <ion-buttons slot="end" class="account-avatar-container">
            <ion-menu-toggle menu="account-menu">
                <ng-content select="ion-button[accountMenuToggle]" />
            </ion-menu-toggle>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<div id="menu-content-container" [class.menu-pane-visible]="sideMenuService.sideMenuOpen$ | async">
    <div id="menu-content">
        <!-- div required for correct transition animation -->
        <div>
            <ng-content select="[sideMenuContent]" />
        </div>
        <!-- div required for correct transition animation -->
        <div class="menu-footer">
            <ion-buttons class="ion-margin">
                @for (link of socialLinks; track $index) {
                    <ion-button [href]="link.href" color="light" target="_blank">
                        <ion-icon slot="icon-only" [name]="link.iconName" [src]="link.iconSource" />
                    </ion-button>
                }
            </ion-buttons>
        </div>
    </div>
    <div
        (click)="closeWhenSmall()"
        [class.corners-rounded]="sideMenuService.sideMenuOpen$ | async"
        id="main-content"
    >
        <ion-content>
            <ion-router-outlet />
            <ion-tabs>
                <ion-tab-bar *libIfPlatformIs="'mobile'" slot="bottom">
                    <ng-content select="ion-tab-button[mobileTabButton]" />
                </ion-tab-bar>
            </ion-tabs>
        </ion-content>
    </div>
</div>

<ion-menu menuId="account-menu" contentId="main-content" side="end">
    <ion-header class="ion-padding-horizontal">
        <ion-toolbar class="menu-light-contrast">
            <ng-content select="[accountMenuToolbarContent]" />
        </ion-toolbar>
    </ion-header>
    <ion-content class="account-navigation-container menu-light-contrast">
        <ng-content select="[accountMenuContent]" />
        <div accountMenuContent class="account-menu-footer">
            <ion-item lines="none">
                <!-- ngsw.json timestamp is displayed in UTC for consistency across browsers -->
                <small>Build {{ version$ | async | date: 'yyyyMMdd.HHmmss' : 'UTC' }}</small>
            </ion-item>
        </div>
    </ion-content>
</ion-menu>
