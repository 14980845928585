import { createStringEnum, EnumValues } from '@lib/shared-interface-utility-types';

export const SchoolJobTitle = createStringEnum([
    // Ordered by usage likelihood
    'CLASSROOM_TEACHER',
    'INSTRUCTIONAL_COACH',
    'ADMIN',
    'HOMESCHOOL_EDUCATOR',
    'OTHER',
]);
export type SchoolJobTitle = EnumValues<typeof SchoolJobTitle>;

export const SCHOOL_JOB_TITLES = Object.values(SchoolJobTitle);
