import { GRAMMAR_PAGE_LINKS, GRAMMAR_PAGE_MEDIA_ID } from '../dashboards.constants';
import { GrammarContentAccessors } from '../interfaces/programs.interfaces';
import { Route } from '@angular/router';
import { GradeLevel } from '@lib/shared-util-global-constants';

export const GRAMMAR_PROGRAM_ROUTES: Route[] = [
    {
        path: 'grammar-program',
        title: 'The EB Grammar Program',
        loadComponent: () => import('../dashboard-template.page'),
        data: {
            mediaId: GRAMMAR_PAGE_MEDIA_ID,
            links: GRAMMAR_PAGE_LINKS,
        },
    },
    {
        path: 'grammar-program/grammar-pd-videos',
        title: 'Grammar PD Videos',
        loadComponent: () => import('../grammar-program/pd-videos-page/pd-videos.page'),
        data: {
            contentAccessor: GrammarContentAccessors.grammarPdVideos,
            backUrl: '../',
            forwardUrl: '../grade-level-lessons',
        },
    },
    {
        path: 'grammar-program/grade-level-lessons',
        title: 'The EB Grammar Program',
        loadComponent: () =>
            import('../grammar-program/grade-level-lessons-page/grade-level-lessons.page'),
        data: {
            backUrl: '../grammar-pd-videos',
            forwardUrl: '../5th-grade-level-d',
        },
    },
    {
        path: 'grammar-program/5th-grade-level-d',
        title: 'The EB Grammar Program',
        loadComponent: () => import('../grammar-program/grade-level-page/grade-level.page'),
        data: {
            constantFileAccessor: GradeLevel.GRADE_5,
            gradeLevel: 'Grade 5',
            backUrl: '../grade-level-lessons',
            forwardUrl: '../6th-grade-level-e',
        },
    },
    {
        path: 'grammar-program/6th-grade-level-e',
        title: 'The EB Grammar Program',
        loadComponent: () => import('../grammar-program/grade-level-page/grade-level.page'),
        data: {
            constantFileAccessor: GradeLevel.GRADE_6,
            gradeLevel: 'Grade 6',
            backUrl: '../5th-grade-level-d',
            forwardUrl: '../7th-grade-level-f',
        },
    },
    {
        path: 'grammar-program/7th-grade-level-f',
        title: 'The EB Grammar Program',
        loadComponent: () => import('../grammar-program/grade-level-page/grade-level.page'),
        data: {
            constantFileAccessor: GradeLevel.GRADE_7,
            gradeLevel: 'Grade 7',
            backUrl: '../6th-grade-level-e',
            forwardUrl: '../8th-grade-level-g',
        },
    },
    {
        path: 'grammar-program/8th-grade-level-g',
        title: 'The EB Grammar Program',
        loadComponent: () => import('../grammar-program/grade-level-page/grade-level.page'),
        data: {
            constantFileAccessor: GradeLevel.GRADE_8,
            gradeLevel: 'Grade 8',
            backUrl: '../7th-grade-level-f',
            forwardUrl: '../supporting-lessons',
        },
    },
    {
        path: 'grammar-program/supporting-lessons',
        title: 'The EB Grammar Program',
        loadComponent: () =>
            import('../grammar-program/supporting-lessons-page/supporting-lessons.page'),
        data: { backUrl: '../8th-grade-level-g' },
    },
];
