import { map } from 'rxjs';

export function requireRouteParameter(parameterKey: string) {
    return map((parameterMap: ParameterMap) => {
        const value = parameterMap.get(parameterKey);
        // Won't happen if routed correctly.
        if (!value) throw new Error(`"${parameterKey}" is a required route parameter`);
        return value;
    });
}

/**
 * A basic type to allow compatibility with Angular ParamMaps
 * without relying on the Angular package directly.
 */
type ParameterMap = {
    get: (key: string) => string | null;
};
