import { DownloadsConfig } from '../../interfaces/programs.interfaces';

export const EXPOSITORY_5TH_GRADE_DOWNLOAD_CONFIG: DownloadsConfig = {
    resourceLink: [
        {
            text: 'Problem-Solution 5th Grade',
            url: '/resources/374f372c-5e2e-4150-9f77-8db312526980',
        },
        {
            text: 'Compare + Contrast 5th Grade',
            url: '/resources/f0bb9e29-1f9c-4171-9d76-3d357b604b2b',
        },
        {
            text: 'Cause + Effect 5th Grade',
            url: '/resources/8760eeab-d939-4e93-8cc8-b71137c79b53',
        },
        {
            text: 'Descriptive Writing 5th Grade',
            url: '/resources/e40adf2e-f534-4886-b85c-2b7e1ca123e6',
        },
    ],
    headingConfig: {
        letter: 'D',
        level: 'Level D',
        grade: 'Grade 5',
        color: 'var(--program-grade-level-d)',
    },
    slideDeckHeading: 'Expository Slide Decks',
    slideDeckUrl: [
        {
            text: 'Cause_and_Effect_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Cause_and_Effect_Slide_Decks (2).zip',
        },
        {
            text: 'Compare_and_Contrast_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Compare_and_Contrast_Slide_Decks (2).zip',
        },
        {
            text: 'Descriptive_Writing_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Descriptive_Writing_Slide_Decks (2).zip',
        },
        {
            text: 'Problem-Solution_Slide_Decks.zip',
            url: '/resource-assets/program-content/EB Writing Program/Level D/Problem-Solution_Slide_Decks (2).zip',
        },
    ],
    digitFilesConfig: [
        {
            value: 'causeAndEffect',
            heading: 'Cause + Effect',
            content: [
                {
                    text: 'Cause + Effect Graphic Organizer 1',
                    url: 'https://docs.google.com/presentation/d/1m9lAJXzwwYIy99vNvi7MZAveAOGhCArboHixdx0JoLE/copy',
                },
                {
                    text: 'Cause + Effect Graphic Organizer 2',
                    url: 'https://docs.google.com/presentation/d/1YCs5YXhjjg4StN0--G5m-_gkbZ4de2T70cWvIbNRnLM/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/10phz1NwCHYEvJHuJ1orHeEfSPjFSInU8H9SK3yUUFUQ/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/128uUV98TbmeoUn93_Z_kpCUj-QxvXJn9az0uF1XdCyE/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1urjChQ5XBvW1ufci1s9ZmMNyl8Vp7mnql1BjlnambZE/copy',
                },
                {
                    text: 'Practice Writing a Summary',
                    url: 'https://docs.google.com/presentation/d/1_E_m5Buwpt-ugL1kDTq5hyihNWRcwT64-I24iPFsz20/copy',
                },
                {
                    text: 'Claim Sorting Activity',
                    url: 'https://docs.google.com/presentation/d/1zqI9P0Yzv92ykelAfUacN-BgYzr4YRa49QlGtfGHABU/copy',
                },
                {
                    text: 'Identifying Parts of an Intro Paragraph',
                    url: 'https://docs.google.com/presentation/d/1UgrCVja_kQSO1XHH4VdwKzjKDbYY0FaCxvvV6S8QIrg/copy',
                },
                {
                    text: 'Premises in Writing',
                    url: 'https://docs.google.com/presentation/d/1Z5YD8Uv-Mpa5YNuXxktA8gGt-XwtR1VPd1M9Py0idCA/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1pVK0uKEGKABDvOV0betI0NY5DssJ_7P9mMEaYjPNVNE/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1X9KBhMtDFPnQBROJRC1s36k6uFjgGtfJvNJC-Nwy8mo/copy',
                },
                {
                    text: 'Justification Practice',
                    url: 'https://docs.google.com/presentation/d/1viXbEZO5JPnLrA5O7PpFcgTiTGd07Jt-VymoQbyh1D0/copy',
                },
                {
                    text: 'Practice with the Intro and Body Paragraphs',
                    url: 'https://docs.google.com/presentation/d/1v-z3StULbMtb2JKLwWo5JjgI-l_Yntqn_X69j3BAWBE/copy',
                },
                {
                    text: 'Restating the Claim',
                    url: 'https://docs.google.com/presentation/d/12wEc_0sLhbKwCJo1xVVLhiYnUw86J53L-NYF2XCPvRc/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1tUvn15CqPnaHFqNrl8fLJxwPr4JMHrtp8Kix8tqT1Zo/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/14D9H71AWBINRIK49RvjQI-qkOhIaX2_SUhmqz0tNGGM/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1omfULa7UuD1JuNuNyB0ZynwO1aRg2dAxeCf25Dv_hNc/copy',
                },
                {
                    text: 'Cause and Effect Rubric',
                    url: 'https://docs.google.com/presentation/d/14np9GZcgGKKNoHALgc_qPdlgoyw5BUMW9WomOOfLod4/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1xIb6lBe9yOncFC9ygTJwknYPbs8GkumOy3uvaV-gbJE/copy',
                },
            ],
        },
        {
            value: 'compareAndContrast',
            heading: 'Compare + Contrast',
            content: [
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TOPICS',
                    url: 'https://docs.google.com/presentation/d/1JNGvHajZ09dCzyw7ciWz09-fiaCD3aIVbcoHyEqYbig/copy',
                },
                {
                    text: 'Compare + Contrast Graphic Organizer TWO TEXTS',
                    url: 'https://docs.google.com/presentation/d/1RJdMDrWLevDWfNaf4f2Rq4Y_PtrHwQ5p5i-GWyAR7eM/copy',
                },
                {
                    text: 'Game Cards',
                    url: 'https://docs.google.com/presentation/d/1O7b8ODK3ptFtkFuBTlg7rMuwEt1IWqzHPIShwecBbC4/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1IrIRzvmsoEjndRtI2sE7xqka83RhB7sz8rma2guHJuA/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1KPy-HVNPxYLmNPw7aBHGEILb7DgjEHvXI85xo69Jqhs/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1f359C6Cjzc_wkN-MMeeS2CU6SkBFFwRUELtko_tNyo4/copy',
                },
                {
                    text: 'Matching Prompts + Claims',
                    url: 'https://docs.google.com/presentation/d/1WgQOZlekP6qNrEdaa1lX21gnV-zR3i_SMHjASqx00DQ/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1KsiLHtnvBNbG9R20CZTyjGgBLCyq4LfuhwDtT4D59O0/copy',
                },
                {
                    text: 'Premises in Writing Activity',
                    url: 'https://docs.google.com/presentation/d/1kuTdBFVyN5C0y19B2A3CoSRmE3QGw6SZ0NPXzJTnJo8/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1qtJuhSpRIlD68z1KAZSxG3HSOzMOYDuS8NNCeDoCnns/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1mlf0EbMe_7OzLJ0SWsBM0UZnO7n7-CntfUt2Ek6CDgA/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1dhBI2zjORZlxtZDE3uzo1let3tu7ZzaOCzCcANK3KdA/copy',
                },
                {
                    text: 'Intro and Body Paragraphs Practice',
                    url: 'https://docs.google.com/presentation/d/1GNV2j0c1HY5c_Lr9ZXlFoiBHCrcU2t5oy4WwTvTcV6c/copy',
                },
                {
                    text: 'Restating Claim',
                    url: 'https://docs.google.com/presentation/d/1mV51dRwt_YFY4YtiQk_5jiR3cMYhboudBQxGJx5G4o8/copy',
                },
                {
                    text: 'Summarizing Evidence',
                    url: 'https://docs.google.com/presentation/d/1zCl-OC4mXonDCVJm1IrXGZ0RyQjp4NFX2d7BGIu4y78/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/10BPQXYVFbLKcZivreNqB3CxwSQchzhlRBVB04X-nR4M/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1JP-yEnN_4hNT35WJrpTplKMgEOUF575LMyx0D9H2lP8/copy',
                },
                {
                    text: 'Compare and Contrast Rubric',
                    url: 'https://docs.google.com/presentation/d/1bEzcfrtpooY3b0Dow4MX0CbWsy00A86b9efXTDMWPOA/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1F2ePYH3lIu87vrHVeJJJrPiZtd_mxbCO-aAVu4GpIcY/copy',
                },
            ],
        },
        {
            value: 'descriptiveWriting',
            heading: 'Descriptive Writing',
            bold: true,
            content: [
                {
                    text: 'Descriptive Writing Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1ff402bVplNJkpZIeS99_fagAREpkUBJ_yMnKGp_z4XY/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1zmbBQKl_77HXMAmMGqCTBpfLtT5rSpTCW1qVmAxGVlw/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1SCQLw9vrOLp8NfThDyfm8EUuBZz95V6uoZ4a2ewHTx4/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1iv8OMQHjFRyafLHtGkGxVAP2dizklPUMV2-356JeWlU/copy',
                },
                {
                    text: 'Practicing Thesis Statements',
                    url: 'https://docs.google.com/presentation/d/1GfoxzFnikqFJF2_MNrnvtDrdhAV5nG77a26PaN3T7aI/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1t3vBJLLi2iFjNY3TEsHOKKKV7vFqGPHs90ROCHd3n7Y/copy',
                },
                {
                    text: 'Understanding the Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1MSL79dt3QJxRRuZbVPiEaGCb2EUi2eLp7_9Yk6j8IrA/copy',
                },
                {
                    text: 'Practicing the Topic Sentences',
                    url: 'https://docs.google.com/presentation/d/1_84yqtP3ves3bpRy00i_EDlrg8wrksl3z9l6_g8i8ow/copy',
                },
                {
                    text: 'Concluding Statement Practice',
                    url: 'https://docs.google.com/presentation/d/1SdI9iXpCiWYMMUqXiHRlo71RJncpxFQPvYipvvUqrCw/copy',
                },
                {
                    text: 'Writing a Body Paragraph',
                    url: 'https://docs.google.com/presentation/d/1djbqZifxdC9Sm8bE7zea5myhq-Hrr-BZsMVVvCsyzyo/copy',
                },
                {
                    text: 'Restating the Thesis Statement',
                    url: 'https://docs.google.com/presentation/d/1SvLHeQXVUSsbFIu-1Gudh36tca0_73QlhNDi89EAP9M/copy',
                },
                {
                    text: 'Summarizing the Topic Sentence',
                    url: 'https://docs.google.com/presentation/d/13ZU1Ersi9ajYe0GDIhWLYRieVCUtUD1fpmaGjl8-eN4/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1XEyRjg1mZ06jri8cr-FigF84P8O6wChrYRe6DXYeW_Y/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/1glAjtuzEidBogGXZ6oOh_sSxoKlVcr8A__ADG7i-dG4/copy',
                },
                {
                    text: 'Descriptive Writing Rubric',
                    url: 'https://docs.google.com/presentation/d/1DOKyQ6YtxVHcOyiBO8KQ-1sjYYB2Z-Xn1sqqLB8A1AM/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1HL5UgW3UZrpizuW12m7VKMZ5PxbBElj9WxmChcHGa7c/copy',
                },
            ],
        },
        {
            value: 'problemSolution',
            heading: 'Problem-Solution',
            content: [
                {
                    text: 'Problem-Solution Graphic Organizer',
                    url: 'https://docs.google.com/presentation/d/1Djq0D51sI0NGNRbZeYov7foKgXs2ttrunB6-rt6EHXc/copy',
                },
                {
                    text: 'Sorting Cards',
                    url: 'https://docs.google.com/presentation/d/1dLJ4375vmDdzbl5ecqNIjEPLxz5pGgWskgNwg1Pieuw/copy',
                },
                {
                    text: 'Creating Hooks',
                    url: 'https://docs.google.com/presentation/d/1WVTDlTsfpwkVAOzcAB4sxvOjLhDCSi8aaVd_oGQC39o/copy',
                },
                {
                    text: 'Connecting Hook to Summary',
                    url: 'https://docs.google.com/presentation/d/1iYy4ZtCc1qrHPfP1DKQ1wlyc8_ZcIbzdiJ5Mwu1k0jE/copy',
                },
                {
                    text: 'Writing Summaries',
                    url: 'https://docs.google.com/presentation/d/1UMiiuuiQtnJ-JmnFyljY4-VsJVGFFTmofVESLuWYiC4/copy',
                },
                {
                    text: 'Investigation Cards',
                    url: 'https://docs.google.com/presentation/d/1SvCYvKXDaSpD5KOfGsBgqMGq4fHWvwm7te2RZW9hCKE/copy',
                },
                {
                    text: 'Intro Paragraph Activity',
                    url: 'https://docs.google.com/presentation/d/1h5x9bJozUCbI1IQgB2GQrAW8LUdfox90bXJNuPYVc7M/copy',
                },
                {
                    text: 'Writing Premises Activity',
                    url: 'https://docs.google.com/presentation/d/141osXnxSybURAjtqFU1s2D29aK3t4HxLXfPie2ou6Sc/copy',
                },
                {
                    text: 'Introducing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1qiI6dVVcqZD9Tp3kGSMLCQ6tn2faKMmwCCEngbpKKP8/copy',
                },
                {
                    text: 'Finding the Right Evidence',
                    url: 'https://docs.google.com/presentation/d/1J_yG1UT-58KpNoDfc1W7tSt_5VOKwTQebCyZI51Y9nc/copy',
                },
                {
                    text: 'Justification Activity',
                    url: 'https://docs.google.com/presentation/d/1JG3qufxOnCfM8xjofxNXNLnJyjfxWK3MiHo5AIUKfXQ/copy',
                },
                {
                    text: 'Intro and Body Paragraph Practice',
                    url: 'https://docs.google.com/presentation/d/1rClyVPdJPZQFFHuiOfsmRbA6sV8Nxp4DqKql5M0TZPU/copy',
                },
                {
                    text: 'Restating the Thesis ',
                    url: 'https://docs.google.com/presentation/d/14z9FNBZzeszB7u_Lnb98EyQcDz8KCue-5Eg5aj5Myhk/copy',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://docs.google.com/presentation/d/1M5kUnVX6Yd8Qxby1wFxg8j8ApPqLGGc1SRgReQzY3Ys/copy',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://docs.google.com/presentation/d/1gDrWy8cDTyUpnzTh7c397-PDdKDvYncX8I9B9Dzb-Kc/copy',
                },
                {
                    text: 'Evidence Tracker',
                    url: 'https://docs.google.com/presentation/d/152PkBUg6hWjwtvhE3qOF15BV7ixf4M-5JL1Ect-vgew/copy',
                },
                {
                    text: 'Problem-Solution Rubric',
                    url: 'https://docs.google.com/presentation/d/15reKTayCgDJun6NChv_ooYPKWfIvKIv5UUX1X8jx9S0/copy',
                },
                {
                    text: 'Peer Editing Handout',
                    url: 'https://docs.google.com/presentation/d/1o0SwzEWPFqZVmi4j0_EE91z0_dufIxVeE9o7XRuzk2g/copy',
                },
            ],
        },
    ],
    studentVideosConfig: [
        {
            value: 'causeAndEffect',
            heading: 'Cause and Effect Writing',
            bold: true,
            content: [
                {
                    text: 'What Is Cause and Effect Writing?',
                    url: 'https://drive.google.com/file/d/1MGV24IkiCfpG5z8MKcxNr35NztFl5QpB/view?usp=drive_link',
                },
                {
                    text: 'Is This a Good Topic?',
                    url: 'https://drive.google.com/file/d/1V-zkMxPu4ZLh2P--r6OLwMKTD_GO6_jD/view?usp=drive_link',
                },
                {
                    text: 'Gathering Evidence',
                    url: 'https://drive.google.com/file/d/1IIjRAzhmqtMmazdRKwOzdZrerXGgrZcD/view?usp=drive_link',
                },
                {
                    text: 'Investigation Trail',
                    url: 'https://drive.google.com/file/d/1v9LFj9n6HMgSJpNpcS_Ld-WoxMpecBQj/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphCnE',
            heading: 'The Introductory Paragraph | Cause and Effect',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1pku7fdB66BUYu1ccfemKZnKMEwIbrCi9/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1qQZHAzVLEYpdGzEtNli1SmSeS1UYvPH_/view?usp=drive_link',
                },
                {
                    text: 'The Claim',
                    url: 'https://drive.google.com/file/d/1D7zmDXK_wmJmgaTjdYnZl1DL-1EkF4Gn/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1JlP8P4kjl9X_TJfOM62sE7tiQDI0eIWf/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphCnE',
            heading: 'The Body Paragraph | Cause and Effect',
            content: [
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/1mOhxvA5231T2fwQSLqIaj9U_e_EpMYAh/view?usp=drive_link',
                },
                {
                    text: 'Introduction to Evidence',
                    url: 'https://drive.google.com/file/d/17PeedYhkj6gJ5Ewn2kAR3XGioWl_ssz7/view?usp=drive_link',
                },
                {
                    text: 'The Evidence',
                    url: 'https://drive.google.com/file/d/14zxg9TEtUkYwCqxMS3L6tJVP-0MUhtbT/view?usp=drive_link',
                },
                {
                    text: 'Justification',
                    url: 'https://drive.google.com/file/d/1IvC5N1i9FvLduq5sxRgeXu1xBkuG6ox1/view?usp=drive_link',
                },
                {
                    text: 'Body Paragraph Practice',
                    url: 'https://drive.google.com/file/d/1ECI2o7HCgnnBF3MyrLow9_hmq0b_veUL/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphCnE',
            heading: 'The Conclusion Paragraph | Cause and Effect',
            content: [
                {
                    text: 'Restating the Claim',
                    url: 'https://drive.google.com/file/d/1bWW9xZ1XaGDcP4YMQRQF0LuV6-W9gb4V/view?usp=drive_link',
                },
                {
                    text: 'Summarizing the Evidence',
                    url: 'https://drive.google.com/file/d/11Y0_miM7XRW5qbpnUJcb1l3x_Q4gFYwm/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1KsCMqpKNxEFnCn5yXDjcBK7Xm1SijLOd/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1FLLxikTjZBnU6MoweuZXPdixmmkQpcJ7/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'problemSolution',
            heading: 'Problem-Solution Writing',
            bold: true,
            content: [
                {
                    text: 'What is Problem-Solution Writing',
                    url: 'https://drive.google.com/file/d/1JEmKvCkhQJpLjqY5r1FQJ2APo0BVo9GK/view?usp=drive_link',
                },
                {
                    text: 'Preparing for a Problem-Solution Essay',
                    url: 'https://drive.google.com/file/d/1e-SGezgSsB4KwFwPFqcP2YvIrxhOG05K/view?usp=drive_link',
                },
                {
                    text: 'Problem-Solution Activity',
                    url: 'https://drive.google.com/file/d/1U_ERfqMZD5KZqcg4QY2j7s__XWmmzvFL/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphPS',
            heading: 'The Introductory Paragraph | Problem-Solution',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1GF943MSr6jtUZEi17AQ8_seoZhAgco5r/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1xmgdJzmqaCTrJRJxMKtuaTBb-EV51A3v/view?usp=drive_link',
                },
                {
                    text: 'The Thesis Statement',
                    url: 'https://drive.google.com/file/d/1a7Jrq98IDsRdLXFyhdqeuCGgk-Gzi8yS/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1NBf6P5OVc00N902nW8YwDy6MlWKi-rWQ/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphPS',
            heading: 'The Body Paragraph | Problem-Solution',
            content: [
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/15QlJ_Ab7V0Hs_Kcw1WpgpIkk9UGhqIIo/view?usp=drive_link',
                },
                {
                    text: 'The Introduction to Evidence',
                    url: 'https://drive.google.com/file/d/1yz0LVIkVX3ATYf0BgPuYh_pIWrDvcB3O/view?usp=drive_link',
                },
                {
                    text: 'The Evidence',
                    url: 'https://drive.google.com/file/d/10v0dfNjsepuOrv0Sn_bYz5tzC7QQx3tK/view?usp=drive_link',
                },
                {
                    text: 'Justification',
                    url: 'https://drive.google.com/file/d/1wkILv5AMLZ5yHQyzQHwX6gV6CSGQ1bfL/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1lATOXSaFo-o2I1xWYgy5EjXQmYfDxovn/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphPS',
            heading: 'The Conclusion Paragraph | Problem-Solution',
            content: [
                {
                    text: 'The Restatement of Thesis',
                    url: 'https://drive.google.com/file/d/1iFc8C2_lDlHO6KCu65GmkR8KeCVxF-1k/view?usp=drive_link',
                },
                {
                    text: 'The Summary or Evidence',
                    url: 'https://drive.google.com/file/d/1E-qQcxWfDzQhxKKdEGeeVyXD-6xudD2T/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1PQ97NRbu_KHra1IARk4gpkNzBpx-iwfG/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1lnQWDV_1nwbSEME2IUsyu2VrilxXOd0K/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'compareAndContrast',
            heading: 'Compare and Contrast Writing',
            bold: true,
            content: [
                {
                    text: 'What is Compare and Contrast Writing',
                    url: 'https://drive.google.com/file/d/1PQ4BB7yqME6WFbEF-uvUNp2UvbEFBMgZ/view?usp=drive_link',
                },
                {
                    text: 'Preparing for a Compare and Contrast Essay',
                    url: 'https://drive.google.com/file/d/1zPt9R6v-kC6LrCie5UxUt0GF2ltn5I3x/view?usp=drive_link',
                },
                {
                    text: 'Compare and Contrast Game',
                    url: 'https://drive.google.com/file/d/1SfU4mv3C_x-R5FnCOibdeEk10uOJs0Qs/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphCnC',
            heading: 'The Introductory Paragraph | Compare and Contrast',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1D3SNl2vFI_peBG0IK-CuJ6V1-GQOKDbe/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1Yax4dQ6w2WdoixEdVtR9M_NU3E5mQRWW/view?usp=drive_link',
                },
                {
                    text: 'The Claim',
                    url: 'https://drive.google.com/file/d/1IbBzSlqdQjTb2jC0hglTAVaDh3hRP2jl/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1XI0CRRmxEfVJhM6ycjQe92OtgGVcUKDY/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphCnC',
            heading: 'The Body Paragraph | Compare and Contrast',
            content: [
                {
                    text: 'The Premise',
                    url: 'https://drive.google.com/file/d/1tqGN_dsb-Qm3nfZzn3CIr_OnHOK1HSaQ/view?usp=drive_link',
                },
                {
                    text: 'The Introduction to Evidence',
                    url: 'https://drive.google.com/file/d/1hGkAH8NoXquVhshsz8EyebFBzj4sa3BS/view?usp=drive_link',
                },
                {
                    text: 'The Evidence',
                    url: 'https://drive.google.com/file/d/1UAFCadh2LINm-h1mgsCLL_wByKOlif1U/view?usp=drive_link',
                },
                {
                    text: 'The Justification',
                    url: 'https://drive.google.com/file/d/1f4ZAHaKqQ_AZmlY3RpFz7gey3-2f0klJ/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1b6URmXT5UYbWC6TpTyD09iWhBvsLBjZ0/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphCnC',
            heading: 'The Conclusion Paragraph | Compare and Contrast',
            content: [
                {
                    text: 'The Restatement of the Claim',
                    url: 'https://drive.google.com/file/d/1uoxzPzFhLEN5fmawG-kQ-msDdTGz62f9/view?usp=drive_link',
                },
                {
                    text: 'The Summary of Evidence',
                    url: 'https://drive.google.com/file/d/1zoNM6xt8x6oMuyN493mG8jMk8lv9fK-T/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1O6sb3GlIhwZrWOZdySU0Kwtt9eydrWcQ/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1FZUSEApsk9CR3gY0YJuw7B2qgx5ytR42/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'descriptiveWriting',
            heading: 'Descriptive Writing',
            bold: true,
            content: [
                {
                    text: 'What is Descriptive Writing',
                    url: 'https://drive.google.com/file/d/1K2ndJdB8jPW5AXtBFRkbEmUpWmfYh5LL/view?usp=drive_link',
                },
                {
                    text: 'Organizing a Descriptive Writing Essay',
                    url: 'https://drive.google.com/file/d/1cKFlSGKOF1ykaQ6JpG8RPjDhUi5SdBWr/view?usp=drive_link',
                },
                {
                    text: 'Introducing the Concept',
                    url: 'https://drive.google.com/file/d/1Z2fBQ4ER83knr14Tt-EXeIFqUoKXKMDz/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'introParagraphDW',
            heading: 'The Introductory Paragraph | Descriptive Writing',
            content: [
                {
                    text: 'The Hook',
                    url: 'https://drive.google.com/file/d/1FqHHBV-EzCPAzLGSf4rxy551EWCCqQY1/view?usp=drive_link',
                },
                {
                    text: 'The Summary',
                    url: 'https://drive.google.com/file/d/1f0Nc6EG3KHvTjrabhf9i4Ox2ThrKzP_l/view?usp=drive_link',
                },
                {
                    text: 'The Thesis Statement',
                    url: 'https://drive.google.com/file/d/1G70w1CNYQ_qRdwM7orxrTrjpWS7C52ZC/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Introductory Paragraph',
                    url: 'https://drive.google.com/file/d/1qMiAN6wsgr99WLBVqIddAG3Wv_KKhFXh/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'bodyParagraphDW',
            heading: 'The Body Paragraph | Descriptive Writing',
            content: [
                {
                    text: 'The Topic Sentences',
                    url: 'https://drive.google.com/file/d/1B7g6ZNRVEr91deCdKO7YUvOarl96bVDf/view?usp=drive_link',
                },
                {
                    text: 'The Supporting Details',
                    url: 'https://drive.google.com/file/d/1u5uiv0VrxWBou6etteBddxVesqgNt5D-/view?usp=drive_link',
                },
                {
                    text: 'The Concluding Statement',
                    url: 'https://drive.google.com/file/d/1ZDXrvG6Q2BUxEQefJFSGG-xrvZjq-k7u/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Body Paragraphs',
                    url: 'https://drive.google.com/file/d/1LlQKXiXT7MYxyMbN6Z9feLiKTGtW4NgE/view?usp=drive_link',
                },
            ],
        },
        {
            value: 'conclusionParagraphDW',
            heading: 'The Conclusion Paragraph | Descriptive Writing',
            content: [
                {
                    text: 'Restating the Thesis Statement',
                    url: 'https://drive.google.com/file/d/1RdF-kRlqPTSzl50-rYQLYRjuUBz5Zd3W/view?usp=drive_link',
                },
                {
                    text: 'Summarizing the Topic Sentences',
                    url: 'https://drive.google.com/file/d/140PMJffM9TjJERroTBsRKHt2z4yOXQtH/view?usp=drive_link',
                },
                {
                    text: 'The Mic Drop Sentence',
                    url: 'https://drive.google.com/file/d/1x-Akt7vuJJwjEjpMd7z5Z7wD_wCDzgRR/view?usp=drive_link',
                },
                {
                    text: 'Practice with the Conclusion Paragraph',
                    url: 'https://drive.google.com/file/d/1URmHatvWCYsxCal4VHi64pfuL6sr3d-0/view?usp=drive_link',
                },
            ],
        },
    ],
    certificateUrl:
        '/resource-assets/program-content/EB Writing Program/Level D/Certificate_of_Completion_Expository_Writing (2).pdf',
};
