import { map, OperatorFunction } from 'rxjs';

// Todo: I believe this could be useful in the near future.
//  However, if it remains unused long-term, we can remove it.
export function mapSortArray<Value>(
    sort: (a: Value, b: Value) => number,
): OperatorFunction<Value[], Value[]> {
    const transformArray = (values: Value[]) => values.sort(sort);
    return map(transformArray);
}
