import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_DOMAIN_TOKEN } from '@lib/frontend-shared-util-miscellaneous-providers';
import { ApiUnreachableError } from './api-unreachable-error';

@Injectable({ providedIn: 'root' })
export class ApiHealthService {
    public constructor(
        @Inject(API_DOMAIN_TOKEN)
        private readonly apiDomain: string,
        private readonly http: HttpClient,
    ) {}

    public async isReachable(): Promise<boolean> {
        const response$ = this.http.head(this.apiDomain + '/health');

        try {
            await firstValueFrom(response$);
            return true;
        } catch {
            return false;
        }
    }

    public async assertReachable(): Promise<void> {
        const isReachable = await this.isReachable();
        if (isReachable) return;

        // If you have mission-critical data
        // that needs to be sent to the backend as quickly as possible or not at all,
        // you can use this method to assert the API is reachable before performing an action.
        throw new ApiUnreachableError();
    }
}
