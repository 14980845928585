import { EnvironmentProviders, Inject, Injectable, InjectionToken, Provider } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

export const TITLE_PREFIX = new InjectionToken<string>('TITLE_PREFIX');

@Injectable()
class CustomTitle extends TitleStrategy {
    public override updateTitle(snapshot: RouterStateSnapshot): void {
        const title = this.buildTitle(snapshot);
        if (title != undefined) {
            this.title.setTitle(`${this.prefix} | ${title}`);
        }
    }

    public constructor(
        private title: Title,
        @Inject(TITLE_PREFIX) private prefix: string,
    ) {
        super();
    }
}

export function provideCustomTitleStrategy(prefix: string): Array<Provider | EnvironmentProviders> {
    return [
        Title,
        {
            provide: TITLE_PREFIX,
            useValue: prefix,
        },
        {
            provide: TitleStrategy,
            useClass: CustomTitle,
        },
    ];
}
