import { map, OperatorFunction } from 'rxjs';

// Todo: I believe this could be useful in the near future.
//  However, if it remains unused long-term, we can remove it.
export function filterArray<Value>(
    transform: (value: Value) => boolean,
): OperatorFunction<Value[], Value[]> {
    const filterArray = (values: Value[]) => values.filter(transform);
    return map(filterArray);
}
