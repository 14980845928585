import { ObservableInput, of, OperatorFunction, switchMap } from 'rxjs';

/**
 * If a stream value is undefined, leave it that way.
 * Otherwise, use transform function to switch to a new observable.
 */
export function switchMapIfDefined<Value, Result>(
    transform: (value: Value) => ObservableInput<Result>,
): OperatorFunction<Value | undefined | null, Result | undefined> {
    const actualTransform = (value: Value | undefined | null) =>
        value == undefined ? of(undefined) : transform(value);

    return switchMap(actualTransform);
}

/**
 * If a stream value is undefined, return the default result.
 * Otherwise, use transform function to switch to a new observable.
 *
 * Todo: I believe this could be useful in the near future.
 *  However, if it remains unused long-term, we can remove it.
 */
export function switchMapOrDefault<Value, Result, DefaultResult = Result>(
    transform: (value: Value) => ObservableInput<Result>,
    defaultResult: DefaultResult,
): OperatorFunction<Value | undefined, Result | DefaultResult> {
    const actualTransform = (value: Value | undefined) =>
        value == undefined ? of(defaultResult) : transform(value);

    return switchMap(actualTransform);
}
